import React from "react";
import { TableCell, TableRow } from '@material-ui/core';
import Utils from "../../../helpers/Utils";
import { icons } from "../icon";
import BootstrapTooltip from "../../globals/BootstrapTooltip";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
const EachRow = ({ eachItem }) => {
  const getContentType = (content_type) => {
    let contentType =
      content_type === 24
        ? "Letter"
        : content_type === 21
          ? "Post Card"
          : content_type === 22
            ? "Greeting Card"
            : "Gift";
    return contentType;
  };

  const getStatus = (status_type, item) => {
    switch (status_type) {
      case 0:
        return <span style={{ color: "yellow" }}>Pending </span>;

      case 1:
        return <span style={{ color: "green" }}>Success</span>;
      case 2:

        if (item.message && item.message !== "") {
          return (<>
            <span style={{ color: "red" }}>Failed
              <BootstrapTooltip arrow placement="top" title={item.message}>
                <ErrorOutlineIcon className="ml-2 errorOutlineIcon" style={{ marginBottom: "-5px" }}>
                </ErrorOutlineIcon>
              </BootstrapTooltip>
            </span>

          </>
          );

        } else {
          return <span style={{ color: "red" }}>Failed </span>;

        }
      case 3:
        return <span style={{ color: "green" }}>Sent</span>;
      case 4:
        return <span style={{ color: "yellow" }}>Undelivered</span>;
      case 5:
        return <span style={{ color: "green" }}>Accepted</span>;
      case 6:
        return <span style={{ color: "success" }}>Rescheduled</span>;
      default:
        return null;
    }
  };

  const getDelivaryStatus = (status_type, info) => {
    switch (status_type) {
      case "CANCELLED":
        return (
          info ?
            <BootstrapTooltip arrow placement="top" title={info}>
              <span className="delivery__status__cancel__btn delivery__status__btn__v2 info__icon">CANCELLED {icons.infoIcon}</span>
            </BootstrapTooltip>
            : <span className="delivery__status__cancel__btn delivery__status__btn__v2">CANCELLED</span>
        )

      case "PENDING":
        return (
          info ?
            <BootstrapTooltip arrow placement="top" title={info}>
              <span className="delivery__status__pending__btn delivery__status__btn__v2 info__icon">PENDING {icons.infoIcon}</span>
            </BootstrapTooltip>
            : <span className="delivery__status__pending__btn delivery__status__btn__v2">PENDING</span>
        )

      case "ERROR":
        return (
          info ?
            <BootstrapTooltip arrow placement="top" title={info}>
              <span className="delivery__status__error__btn delivery__status__btn__v2 info__icon">ERROR {icons.infoIcon}</span>
            </BootstrapTooltip>
            : <span className="delivery__status__error__btn delivery__status__btn__v2">ERROR</span>
        )

      case "FAILED":
        return (
          info ?
            <BootstrapTooltip arrow placement="top" title={info}>
              <span className="delivery__status__failed__btn delivery__status__btn__v2 info__icon">FAILED {icons.infoIcon}</span>
            </BootstrapTooltip>
            : <span className="delivery__status__failed__btn delivery__status__btn__v2">FAILED</span>
        )

      case "PRINTED":
        return (
          info ?
            <BootstrapTooltip arrow placement="top" title={info}>
              <span className="delivery__status__printed__btn delivery__status__btn__v2 info__icon">PRINTED {icons.infoIcon}</span>
            </BootstrapTooltip>
            : <span className="delivery__status__printed__btn delivery__status__btn__v2">PRINTED</span>
        )

      case "PRINTING":
        return (
          info ?
            <BootstrapTooltip arrow placement="top" title={info}>
              <span className="delivery__status__printing__btn delivery__status__btn__v2 info__icon">PRINTING {icons.infoIcon}</span>
            </BootstrapTooltip>
            : <span className="delivery__status__printing__btn delivery__status__btn__v2">PRINTING</span>
        )

      case "SHIPPED":
        return (
          info ?
            <BootstrapTooltip arrow placement="top" title={info}>
              <span className="delivery__status__shipped__btn delivery__status__btn__v2 info__icon">SHIPPED {icons.infoIcon}</span>
            </BootstrapTooltip>
            : <span className="delivery__status__shipped__btn delivery__status__btn__v2">SHIPPED</span>
        )

      default:
        return (
          <span
            style={
              status_type != null ? { color: "green" } : { color: "black" }
            }
          >
            {status_type != null ? status_type : "N/A"}
          </span>
        );
    }
  };


  return (
    <>
      <TableRow className="chart__table__body__tr">

        <TableCell className="system_button chart__table__body__td">
          {eachItem.templateThumbnailPath ?
            <div className="order__history__file__preview">
              <img src={eachItem.templateThumbnailPath} alt="file-thumbnail" />
            </div>
            : eachItem.fileUrl ?
              <a href={`${eachItem.fileUrl}`} target="_blank" rel="noreferrer">
                <div className="order__history__file__preview">
                  {icons.pdfIcon}
                </div>
              </a>
              : <div className="order__history__not__available">
                N/A
              </div>
          }

        </TableCell>

        <TableCell className="chart__table__body__td">
          <div className="report_recipient">
            <span className="report_recipient_top">
              {Utils.getReportUserName(eachItem.contact.firstName, eachItem.contact.lastName, () => Utils.getFullAddress(eachItem.contact.address, eachItem.contact.city, eachItem.contact.state, eachItem.contact.zip))}
            </span>
            <p className="report_recipient_bottom">
              {Utils.getFullAddress(eachItem.contact.address, eachItem.contact.city, eachItem.contact.state, eachItem.contact.zip)}
            </p>
          </div>
        </TableCell>

        <TableCell>
          <div className="order__history__content__type">
            <span className="order__history__gift">{getContentType(eachItem.contentType)}</span>
            {eachItem.cardSize && <p className="order__history__cardsize">{eachItem.cardSize}</p>}
          </div>
        </TableCell>

        <TableCell className="system_button chart__table__body__td">{getStatus(eachItem.status, eachItem)}</TableCell>
        <TableCell className="system_button chart__table__body__td">
          {getDelivaryStatus(eachItem.deliveryStatus, eachItem.message)}
        </TableCell>
        <TableCell className="system_button chart__table__body__td">
          <span className="time">
            {window.globalTimezoneConversionToDifferentTimezone(eachItem.createdAt, 'UTC', '', 'MM/DD/YYYY hh:mm a')}
          </span>
        </TableCell>
        <TableCell className="system_button chart__table__body__td"><span className="order__history__total__cost">{eachItem.cost ? `$${eachItem.cost}` : "$0"}</span></TableCell>
      </TableRow>
    </>
  );
};

export default EachRow;
