import React from 'react';

const TableSkeletonLoader = ({ rows = 5, columns = 6 }) => {
    const skeletonStyle = {
        background: 'linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)',
        backgroundSize: '200% 100%',
        animation: 'skeleton-loading 1.5s infinite',
        borderRadius: '4px',
        height: '20px',
        margin: '4px 0',
        width: '100%',
    };

    const skeletonRowStyle = {
        height: '48px',
    };

    const skeletonAnimation = `
        @keyframes skeleton-loading {
            0% {
                background-position: 200% 0;
            }
            100% {
                background-position: -200% 0;
            }
        }
    `;

    return (
        <>
            <style>{skeletonAnimation}</style>
            {Array.from({ length: rows }).map((_, rowIndex) => (
                <tr key={rowIndex} style={skeletonRowStyle}>
                    {Array.from({ length: columns }).map((_, colIndex) => (
                        <td key={colIndex}>
                            <div style={skeletonStyle}></div>
                        </td>
                    ))}
                </tr>
            ))}
        </>
    );
};

export default TableSkeletonLoader;
