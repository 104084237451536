import React from 'react';

const PipelineSkeleton = () => {
  const styles = {
    skeletonContainer: {
      display: 'flex',
      gap: '15px',
      paddingTop: '10px'
    },
    skeletonBox: {
      backgroundColor: '#e0e0e0',
      borderRadius: '4px',
      width: '250px',
      height: '170px',
      marginTop: '40px',
      animation: 'pulse 1.5s infinite ease-in-out',
    },
    skeletonBox1: {
      backgroundColor: '#e0e0e0',
      borderRadius: '4px',
      width: '100%',
      height: '320px',
      margin: '20px',
      animation: 'pulse 1.5s infinite ease-in-out',
    },
    skeletonBox2: {
      backgroundColor: '#e0e0e0',
      borderRadius: '4px',
      width: '250px',
      height: '50px',
      margin: '20px',
      animation: 'pulse 1.5s infinite ease-in-out',
    },
    keyframes: `
      @keyframes pulse {
        0% {
          background-color: #e0e0e0;
        }
        50% {
          background-color: #f5f5f5;
        }
        100% {
          background-color: #e0e0e0;
        }
      }
    `,
  };

  return (
    <>
      <style>{styles.keyframes}</style>
      <div style={styles.skeletonContainer}>
        <div style={styles.skeletonBox}></div>
        <div style={styles.skeletonBox}></div>
        <div style={styles.skeletonBox}></div>
        <div style={styles.skeletonBox}></div>
        <div style={styles.skeletonBox}></div>
        <div style={styles.skeletonBox}></div>
        <div style={styles.skeletonBox}></div>
      </div>
      
      <div style={styles.skeletonContainer}>
        <div style={styles.skeletonBox1}></div>
      </div>
      
      <div style={styles.skeletonContainer}>
        <div style={styles.skeletonBox2}></div>
        <div style={styles.skeletonBox2}></div>
        <div style={styles.skeletonBox2}></div>
        <div style={styles.skeletonBox2}></div>
        <div style={styles.skeletonBox2}></div>
        <div style={styles.skeletonBox2}></div>
        <div style={styles.skeletonBox2}></div>

      </div>
    </>
  );
};

export default PipelineSkeleton;
