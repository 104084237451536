export const iconList = {
  checkMark : <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="15" cy="15" r="15" fill="#3C7EF3"/><path d="m10.333 15 4 3.333 5.334-6.666" stroke="#fff" strokeWidth="2"/></svg>,
  times : <svg width="26" height="26" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="13" cy="13" r="13" fill="#fff"/><path d="M16.535 16.535c-.325.326-.617.561-.943.235L13 14.178l-2.592 2.592c-.325.326-.618.091-.944-.235-.325-.326-.56-.618-.235-.943L11.822 13l-2.593-2.592c-.325-.326-.09-.618.235-.944.326-.326.619-.56.944-.235L13 11.821l2.592-2.592c.326-.326.618-.09.943.235.326.326.561.618.236.944L14.178 13l2.593 2.592c.325.326.09.617-.236.943z" fill="#FF264A"/></svg>,
  circle : <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="15" cy="15" r="14.5" stroke="#133159" strokeOpacity=".35"/></svg>,
  leftArrow : <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" width="18" height="18"><path d="m5.935 9 7.72-7.72a.749.749 0 1 0-1.06-1.06l-8.25 8.25a.749.749 0 0 0 0 1.06l8.25 8.25a.749.749 0 1 0 1.06-1.06L5.935 9z"/></svg>,
  doubleLeftArrow : <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"><path d="M15.633 18c.494 0 .992-.164 1.404-.501a2.223 2.223 0 0 0 .319-3.131l-4.351-5.341L17.4 3.631A2.226 2.226 0 0 0 13.951.819L8.409 7.621a2.224 2.224 0 0 0 0 2.812l5.498 6.749a2.228 2.228 0 0 0 1.727.818z"/><path d="M6.099 17.18a2.222 2.222 0 0 0 3.131.319 2.223 2.223 0 0 0 .319-3.131L5.198 9.027l4.396-5.396A2.226 2.226 0 0 0 6.145.819L.599 7.622a2.224 2.224 0 0 0 0 2.812l5.499 6.748z"/></svg>,
  rightArrow : <svg width="18" height="18" viewBox="0 0 18 18" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"><path d="m5.29 16.804-.724-.756L11.922 9 4.565 1.951l.724-.755L13.435 9 5.29 16.804z"/></svg>,
  doubleRightArrow : <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"><path d="M.923 17.499a2.222 2.222 0 0 0 3.131-.319l5.54-6.8a2.226 2.226 0 0 0 0-2.812L4.096.82A2.226 2.226 0 0 0 .645 3.631l4.352 5.343-4.395 5.395A2.226 2.226 0 0 0 .923 17.5z"/><path d="M11.902.821A2.224 2.224 0 1 0 8.453 3.63l4.351 5.343-4.395 5.395a2.226 2.226 0 0 0 3.449 2.812l5.54-6.8a2.226 2.226 0 0 0 0-2.812L11.902.821z"/></svg>,
  modalCloseIcon: <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M28.6484 7.3499C22.7984 1.4999 13.1984 1.4999 7.34844 7.3499C1.49844 13.1999 1.49844 22.7999 7.34844 28.6499C13.1984 34.4999 22.6484 34.4999 28.4984 28.6499C34.3484 22.7999 34.4984 13.1999 28.6484 7.3499ZM22.1984 24.2999L17.9984 20.0999L13.7984 24.2999L11.6984 22.1999L15.8984 17.9999L11.6984 13.7999L13.7984 11.6999L17.9984 15.8999L22.1984 11.6999L24.2984 13.7999L20.0984 17.9999L24.2984 22.1999L22.1984 24.2999Z" fill="#133159" fillOpacity="0.35"></path>
  </svg>,
}
