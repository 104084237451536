import { all } from "redux-saga/effects";
import reportMiddleware from "./reportMiddleware";

export default function* rootMiddleware() {
    yield all(
        [
            reportMiddleware()
        ]
    )
}
