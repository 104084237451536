import React, { useState } from 'react';
import { getGoalReportPopulateData } from '../../../api/goalApi';
import If from 'if-else-react';
import GoalIndexSkeleton from '../../common/Skeletons/GoalIndexSkeleton.jsx';

class LeftSide extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: true,
            pipelines: [],
            team: []
        };
    };

    componentDidMount() {
        this.populateData();
    }

    populatePipelineOptions = () => {
        return this.state.pipelines.map((value, index) => {
            return <option key={index} value={value.id} selected={this.props.selectedPipeline == value.id}>{value.title}</option>;
        });
    }

    populateTeamOptions = () => {
        return this.state.team.map((value, index) => {
            return <option key={index} value={value.id} >{value.name}</option>;
        });
    }

    changeSelectedOption = (option) => {
        if (option === 'activity-added' || option === 'activity-completed' || option === 'conversions-sent' || option === 'conversions-received') {
            this.props.toggleHidePipelineHandler(true);
        } else {
            this.props.toggleHidePipelineHandler(false);
        }
        this.props.changeSelectedOption(option);
    }

    populateData = () => {
        getGoalReportPopulateData({}).then(
            response  => {
                if (response.data.status == 'success') {
                    this.setState({
                        pipelines: response.data.result.pipelines,
                        team: response.data.result.group,
                        loader: false
                    });
                } else {
                    alert(response.data.result);
                }

            }
        )
        .catch((error) => {
            alert('Server Error. Please Reload.');
        });
    }

    render() {
        return (
            <React.Fragment>
                <div className="goalreport__left">
                    <If condition={this.state.loader}>
                        <div className="text-center w-100 m--margin-100">
                            <GoalIndexSkeleton/>
                        </div>
                    </If>
                    <If condition={!this.state.loader}>
                        <>
                            <div className="goalreport__left__wr">
                                <div className={"sidebar__menu_list "+ (this.props.hidePipeline ? 'd-none' : '')}>
                                    <span className="select-box-icon">
                                        <svg width="16" height="16" viewBox="0 0 16 16"><path d="M6 2v12h3V2H6zm7 0h-3v6h3V2zM5 2v9H2V2h3z"></path></svg>
                                    </span>
                                    <select className="form-control" onChange={(e) => { this.props.pipelineOptionOnChangeHandler(e)}} value={this.props.selectedPipeline}>
                                        <option value="0">All Piplines</option>
                                        {this.populatePipelineOptions()}
                                    </select>
                                </div>
                                <div className="sidebar__menu_list">
                                    <span className="select-box-icon">
                                        <svg width="16" height="16" viewBox="0 0 16 16"><path d="M10.98 7.02a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm-5.96 0a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm-.04 1.65C3.42 8.67.3 9.45.3 11v1.67h9.33V11c0-1.55-3.11-2.33-4.66-2.33h.01zm5.96 0l-.65.03c.77.56 1.31 1.31 1.31 2.3v1.67h4V11c0-1.55-3.1-2.33-4.66-2.33z"></path></svg>
                                    </span>
                                    <select className="form-control" onChange={(e) => { this.props.teamUserOptionOnChangeHandler(e)}} value={this.props.selectedTeamUser}>
                                        <option value="0">Current User</option>
                                        {this.populateTeamOptions()}
                                    </select>
                                </div>
                                <div className="sidebar__menu_list">
                                    <span className="select-box-icon">
                                        <svg width="16" height="16" viewBox="0 0 16 16"><path d="M12 9H9v3h3V9z"></path><path d="M5 2h6V0h2v2a2 2 0 0 1 2 2v9a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2l.01-9A2 2 0 0 1 3 2V0h2v2zM3 13h10V6H3v7z" clipRule="evenodd"></path></svg>
                                    </span>

                                    <select className="form-control" onChange={(e) => this.props.handleDateRangeChange(e)} defaultValue={this.props.selectedDateType}>
                                        <option value={'today'}>Today</option>
                                        <option value={'yesterday'}>Yesterday</option>
                                        <option value={'thisweek'}>This Week</option>
                                        <option value={'lastweek'}>Last Week</option>
                                        <option value={'thismonth'}>This Month</option>
                                        <option value={'lastmonth'}>Last Month</option>
                                        <option value={'thisyear'}>This Year</option>
                                        <option value={'lastyear'}>Last Year</option>
                                    </select>
                                </div>
                            </div>
                            {/* <div className="sidebar__selected_dates">
                                01/07/2020 - 31/07/2020
                            </div> */}
                            <div className="activites__side__effort">
                                <div className="activites__effort__single activites__effort1">
                                    <h2 className="activites__effort__h2">ACTIVITIES EFFORT</h2>
                                    <ul className="activites__effort__ul">
                                        <li className={`activites__effort__li ${this.props.selectedOption == 'activity-added' ? 'active' : ''}`}>
                                            <a href="javascript:void(0)" onClick={() => { this.changeSelectedOption('activity-added') }} className="">Activities added</a>
                                        </li>
                                        <li className={`activites__effort__li ${this.props.selectedOption == 'activity-completed' ? 'active' : ''}`}>
                                            <a href="javascript:void(0)" onClick={() => { this.changeSelectedOption('activity-completed') }}>Activities completed</a>
                                        </li>
                                        <li className={`activites__effort__li ${this.props.selectedOption == 'conversions-sent' ? 'active' : ''}`}>
                                            <a href="javascript:void(0)" onClick={() => { this.changeSelectedOption('conversions-sent') }}>Conversations sent</a>
                                        </li>
                                        <li className={`activites__effort__li ${this.props.selectedOption == 'conversions-received' ? 'active' : ''}`}>
                                            <a href="javascript:void(0)" onClick={() => { this.changeSelectedOption('conversions-received') }}>Conversations received</a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="activites__effort__single activites__pipeline__wr">
                                    <h2 className="activites__effort__h2">PIPELINE PERFORMANCE</h2>
                                    <ul className="activites__effort__ul">
                                        <li className={`activites__effort__li ${this.props.selectedOption == 'deals-started' ? 'active' : ''}`}>
                                            <a href="javascript:void(0)" onClick={() => { this.changeSelectedOption('deals-started') }}>Deals started</a>
                                        </li>
                                        <li className={`activites__effort__li ${this.props.selectedOption == 'deal-progress' ? 'active' : ''}`}>
                                            <a href="javascript:void(0)" onClick={() => { this.changeSelectedOption('deal-progress') }}>Deal progress</a>
                                        </li>
                                        {/* <li className={this.props.selectedOption == 'deal-velocity' ? 'active' : ''}>
                                            <a href="javascript:void(0)" onClick={() => { this.changeSelectedOption('deal-velocity') }}>Deal velocity</a>
                                        </li>
                                        <li className={this.props.selectedOption == 'deal-conversion' ? 'active' : ''}>
                                            <a href="javascript:void(0)" onClick={() => { this.changeSelectedOption('deal-conversion') }}>Deal conversion</a>
                                        </li> */}
                                    </ul>
                                </div>
                                <div className="activites__effort__single activites__salse__wr">
                                    <h2 className="activites__effort__h2">SALES PERFORMANCE</h2>
                                    <ul className="activites__effort__ul">
                                        <li className={`activites__effort__li ${this.props.selectedOption == 'deals-won' ? 'active' : ''}`}>
                                            <a href="javascript:void(0)" onClick={() => { this.changeSelectedOption('deals-won') }}>Deals won</a>
                                        </li>
                                        <li className={`activites__effort__li ${this.props.selectedOption == 'deals-lost' ? 'active' : ''}`}>
                                            <a href="javascript:void(0)" onClick={() => { this.changeSelectedOption('deals-lost') }}>Deals lost</a>
                                        </li>
                                        {/*<li className={this.props.selectedOption == 'revenue-forecast' ? 'active' : ''}>*/}
                                        {/*    <a href="javascript:void(0)" onClick={() => { this.changeSelectedOption('revenue-forecast') }}>Revenue forecast</a>*/}
                                        {/*</li>*/}
                                    </ul>
                                </div>
                            </div>
                        </>
                    </If>
                </div>
            </React.Fragment>
        );
    }
}

export default LeftSide
