import { FormControl, MenuItem } from "@material-ui/core";
import { useEffect, useState } from "react";
import { goalPipeline, goalStage } from "../../../api/goalApi";
import { AssigneeTypes, GoalTypes, PipelineTypes } from "../../../constants/CoreConstants";
import { useIsMounted } from "../../../hooks/IsMounted";
import { iconList } from "../../globals/IconList";
import SelectComponent from "../SelectComponent";

const StepThird = props => {

    const isMounted = useIsMounted();
    const [pipelineId,setPipelineId] = useState(null);
    const [pipelines,setPipelines] = useState([]);
    const [stageId,setStageId] = useState(null);
    const [stages,setStages] = useState([]);
    const [pipelineText,setPipelineText] = useState('');
    const [stageText,setStageText] = useState('');
    const [validStep,setValidStep] = useState(false);


    useEffect(() => {
        if(props.stepActive === 3) {
            let formParams;
            if(props.postData.assigneeType === AssigneeTypes.TYPE_USER) {
                formParams = { from : 1,userId : props.postData.selectedUser }
            } else {
                formParams = { from : 2 }
            }

            goalPipeline(formParams).then(res => {
                setPipelines(res.data.data.pipelines);
            });
        }
    },[props.postData.assigneeType,props.stepActive]);

    useEffect(() => {
        if(isMounted) {
            setStageId(null);
            goalStage({pipelineId:pipelineId}).then(res => {
                setStages(res.data.data.stages);
            });
        }
    },[pipelineId]);

    useEffect(() => {
        if(isMounted) {
            let isValid = pipelineId !== null && 
            (props.postData.selectedGoalType !==  GoalTypes.GOAL_TYPE_KEY_DEALS_PROGRESSED || stageId !== null);
            setValidStep(isValid);
            props.validateStep(3,isValid,{
                pipelineId : pipelineId,
                stageId : stageId
            });
        }
    },[pipelineId,stageId]);

    const renderPipelineList = () => (
        pipelines.map((item, index) => (
            <MenuItem key={index} value={item['id']} >
                {item['title']}
            </MenuItem>
        ))
    )

    const renderStageList = () => (
        stages.map((item, index) => (
            <MenuItem key={index} value={item['id']} >
                {item['stage']}
            </MenuItem>
        ))
    );

    return ( 
        <>
        <div className={`add__goal_item ${props.stepComplete < 3 ? 'mb-2' : ''}`}>
            <div className="step__label__icon">
                { validStep ? iconList.checkMark : iconList.circle }
                </div>
                <div className="step__level__select__content__wr">
                <div className={`step__label__name ${props.stepActive === 3 ? 'assigne__label__name' : ''}`}>Pipeline</div>
            </div>
        </div>

        { props.stepActive === 3 &&
            <div className="setp__label__left__border">
                <h4 className="step__label__name__title">Which pipeline does this goal apply to?</h4>
                <div className="step__select__content">
                    <div className="add__goal__select1">
                        <FormControl variant="outlined" margin="none" focused="false" className="goal_select__wr">
                            <SelectComponent
                                onChange={(e,elem) => {setPipelineId(e.target.value);setPipelineText(elem.props.children)}}
                                value={pipelineId == null ? -2 : pipelineId}>
                                <MenuItem value="-2">Select Pipeline</MenuItem>
                                {
                                    ((props.postData.selectedGoalType ===  GoalTypes.GOAL_TYPE_KEY_ACTIVITY_ADDED) || (props.postData.selectedGoalType ===  GoalTypes.GOAL_TYPE_KEY_ACTIVITY_COMPLETED)) &&
                                    <MenuItem value={PipelineTypes.NO_SPECIFIC_PIPELINE}>No Specific Pipeline</MenuItem>
                                }
                                {
                                    ((props.postData.selectedGoalType ===  GoalTypes.GOAL_TYPE_KEY_DEALS_WON) || (props.postData.selectedGoalType ===  GoalTypes.GOAL_TYPE_KEY_DEALS_LOST) || (props.postData.selectedGoalType ===  GoalTypes.GOAL_TYPE_KEY_DEALS_STARTED)) && 
                                    <MenuItem value={PipelineTypes.ALL_PIPELINE}>All Pipeline</MenuItem>
                                }
                                {renderPipelineList()}
                            </SelectComponent>
                        </FormControl>
                    </div>

                    {
                        props.postData.selectedGoalType ===  GoalTypes.GOAL_TYPE_KEY_DEALS_PROGRESSED &&
                        <div className="add__goal__select2">
                            <FormControl variant="outlined" margin="none" focused="false" className="goal_select__wr">
                                <SelectComponent
                                    value={stageId == null ? 0 : stageId}
                                    onChange={(e,elem) => {setStageId(e.target.value);setStageText(elem.props.children)}}>
                                    <MenuItem value="0" disabled>Select Stage</MenuItem>
                                    {renderStageList()}
                                </SelectComponent>
                            </FormControl>
                        </div>
                    }
                </div>
            </div>
        }
        {
            props.stepComplete >= 3 && props.stepActive !== 3 &&
            <div className="">
                <h4 className="active__border__green pro__lead__group">{pipelineText + " " + stageText}</h4>
            </div>
        }
        </>
    );
}
 
export default StepThird;