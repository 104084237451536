import {sendRequest, sendRequestBackEnd, sendRequestBackEndGetMethod} from './rootApi';
import Api from "../constants/Api";
import HttpRequest from "./HttpRequest";

export const getReportData = async (payload) => {
    return sendRequest("post", Api.reportData, payload);
}

export const getUserReportData = async (payload) => {
    let url = Api.getUserReportData + "?page="+payload.page+"&size="+payload.size+"&searchKey="+payload.searchKey+"&type="+payload.type+"&status="+payload.status+"&startDate="+payload.startDate+"&endDate="+payload.endDate;
    return sendRequestBackEndGetMethod(url);
}

export const getUserOrderReports = async (payload) => {
    let url = Api.getUserOrderReports;
    return sendRequestBackEndGetMethod(url);
}

export const getUserOrderReportCharts = async (payload) => {
    let url = Api.getUserOrderReportCharts;
    return sendRequestBackEndGetMethod(url);
}

/* business report version 2 */
export const getBusinessReportChartV2Api = async (payload) => {
    return sendRequest("post", Api.getBusinessReportChartV2, payload);
}

export const getUserListApi = async () => {
    return sendRequest("post", Api.getSubUserList);
}

export const getBusinessReportCardV2Api = async (payload) => {
    return sendRequest("post", Api.getBusinessReportCardV2, payload);
}

export const getCallLog = async (payload) => {
    return sendRequest("post", Api.getCallLog, payload);
}

export const getSubUserListForContactlist = async (payload) => {
    return sendRequest("post", Api.subUserListForContactDropdown, payload);
}

export const callOutcomeListForCallLogDropdown = async (payload) => {
    return sendRequest("post", Api.callOutcomeListForCallLogDropdown, payload);
}

export const getUserAndVirtualNumberArray = async (payload) => {
    return sendRequest("post", Api.getUserAndVirtualNumberArray, payload);
}

export const getOutgoingCallCount = async (payload) => {
    return sendRequest("post", Api.getOutgoingCallCount, payload);
}

export const getOutgoingCallOutcome = async (payload) => {
    return sendRequest("post", Api.getOutgoingCallOutcome, payload);
}

export const getPowerDialerCallLogsApi = async (payload) => {
    return sendRequestBackEnd("post", Api.getPowerDialerCallLogsApi, payload);
}

export const getPowerDialerDropdownDataApi = async (payload) => {
    return sendRequestBackEnd("post", Api.getPowerDialerDropdownDataApi, payload);
}

export const getDncInventoryApi = async (payload) => {
    return sendRequest("post", Api.getDncInventory, payload);
}

export const updateNumberDncStatusApi = async (payload) => {
    return HttpRequest.post(Api.updateNumberDncStatus, payload);
}