const CoreConstants  = {
    DEAL_CURRENCY : '$'
}

export const AllDateFilters = {
    today : 'Today',
    yesterday :'Yesterday',
    this_week :'This Week',
    last_7_days :'Last 7 days',
    last_week :'Last Week (Mon - Sun)',
    last_14_days :'Last 14 days',
    this_month :'This Month',
    last_30_days :'Last 30 days',
    last_month :'Last Month',
    last_90_days :'Last 90 days',
    this_year :'This Year',
    last_year :'Last Year',
    // all_time :'All Time',
};

export const AllColumns = {
    new_leads : 'New Leads',
    calls :'Calls',
    emails :'Emails',
    texts :'Texts',
    notes :'Notes',
    task_completed :'Tasks Completed',
    appointment_set :'Appointments Set',
    appointment :'Appointments',
    deal_closed : 'Deal closed',
    deal_value : 'Deal value',
    leads_not_acted_on :'Leads not acted on',
    leads_not_called : 'Leads not called',
    leads_not_texted : 'Leads not texted',
    leads_not_emailed : 'Leads not emailed',
    percentage_of_leads_not_acted_on :'% of leads not acted on',
    percentage_of_leads_not_called : '% of leads not called',
    percentage_of_leads_not_emailed : '% of leads not emailed',
    percentage_of_leads_not_texted : '% of leads not texted',
    // percentage_of_leads_responding : '% of leads responding',
    // percentage_of_leads_responding_by_email : '% of leads responding by email',
    // percentage_of_leads_responding_by_call : '% of leads responding by call',
    // percentage_of_leads_responding_by_text : '% of leads responding by text',
    lead_responded : 'Lead responded',
    lead_responded_by_email : 'Lead responded by email',
    lead_responded_by_call : 'Lead responded by call',
    lead_responded_by_text : 'Lead responded by text',
    percentage_of_leads_responded : '%  of leads responded',
    percentage_of_leads_responded_by_email : '% of leads responded by email',
    percentage_of_leads_responded_by_call : '% of leads responded by call',
    percentage_of_leads_responded_by_text : '% of leads responded by text',
};

export const DefaultColumns = {
    new_leads : 'New Leads',
    calls :'Calls',
    emails :'Emails',
    texts :'Texts',
    notes :'Notes',
    task_completed :'Tasks Completed',
    appointment_set :'Appointments Set',
    appointment :'Appointments',
    deal_closed : 'Deal closed',
    deal_value : 'Deal value',
};

export const HideColumns = {
    created_at: false,
    id: false,
    updated_at: false,
    user_id: false,
    user: false,
    dates: false
};

export const AssigneeTypes = {
    TYPE_COMPANY: 1,
    TYPE_TEAM: 2,
    TYPE_USER: 3,
}

export const GoalTypes = {
    GOAL_TYPE_KEY_ACTIVITY_ADDED : 1,
    GOAL_TYPE_KEY_ACTIVITY_COMPLETED : 2,
    GOAL_TYPE_KEY_DEALS_STARTED : 3,
    GOAL_TYPE_KEY_DEALS_PROGRESSED : 4,
    GOAL_TYPE_KEY_DEALS_WON : 5,
    GOAL_TYPE_KEY_DEALS_LOST : 6,
}

export const TaskTypes = {
    TASK_CALL : 1,
    TASK_MEETING : 2,
    TASK_TASK : 3,
    TASK_DEADLINE : 4,
    TASK_EMAIL : 5,
    TASK_FOLLOWUP : 6,
}

export const PipelineTypes = {
    NO_SPECIFIC_PIPELINE : 0,
    ALL_PIPELINE : -1,
}

export const OutcomeTypes = {
    EXPECTED_OUTCOME_AMOUNT : 1,
    EXPECTED_OUTCOME_VALUE : 2,
}

export const IntervalTypes = {
    GOAL_INTERVAL_WEEKLY : 1,
    GOAL_INTERVAL_MONTHLY : 2,
    GOAL_INTERVAL_QUARTERLY : 3,
    GOAL_INTERVAL_YEARLY : 4,
}

export const DurationTypes = {
    GOAL_DURATION_TYPE_THIS_MONTH : 1,
    GOAL_DURATION_TYPE_NEXT_MONTH : 2,
    GOAL_DURATION_TYPE_THIS_QUARTER : 3,
    GOAL_DURATION_TYPE_NEXT_QUARTER : 4,
    GOAL_DURATION_TYPE_THIS_YEAR : 5,
    GOAL_DURATION_TYPE_NEXT_YEAR : 6,
    GOAL_DURATION_TYPE_DATE_RANGE : 7,
    GOAL_DURATION_TYPE_THIS_WEEK : 8,
    GOAL_DURATION_TYPE_NEXT_WEEK : 9,
}

export const DROPDOWN_BOTTOM_SETUP = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "center",
  },
  getContentAnchorEl: null,
};

export const USER_ORDER_REPORT = {
    types: {
        "POSTCARD": 21,
        "GREETING CARD": 22,
        "GIFT": 23,
        "LETTER": 24,
        "ALL TYPES": ""
    },
    allStatus: {
        "PENDING": 0,
        "SUCCESS": 1,
        "FAILED": 2,
        "SENT": 3,
        "UNDELIVERED":4,
        "ACCEPTED":5,
        "RESCHEDULED":6,
        "ALL": ""
    },
}

export default CoreConstants;