import React from 'react';
import {BrowserRouter as Router, Route ,Switch} from 'react-router-dom';
import GoalIndex from '../components/goal/GoalIndex';
import GoalReport from '../components/goal/Report/GoalReport';
import ReportIndex from '../components/report/ReportIndex';
import HealthCheck from '../components/HealthCheck';
import Bill from '../components/Bill';
import Transaction from '../components/Transaction';
import UserOrderReport from '../components/report/UserOrderReport';
import ReportViewV2ModuleApp from '../components/report-v2/App';
import CallLogs from "../components/report/CallLogs/CallLogs";
import CheckFullInfo from "../components/report/CallLogs/CheckFullInfo";
import PowerDialerCallLogs from "../components/report/powerDialerCallLogs/PowerDialerCallLogs";
import DncInventory from "../components/report/dncInventory/DncInventory";

const Routes = () => {
    return (
        <Router>
            <Switch>
                <Route exact path={'/business-report'} component={ReportViewV2ModuleApp}/>
                {/* <Route exact path={'/business-report'} component={ReportIndex}/> */}
                {/* <Route exact path={'/business-report-v2'} component={ReportViewV2ModuleApp}/> */}
                <Route exact path={'/business-report-v2'} component={ReportIndex}/>
                <Route exact path={'/goal/progress'} component={GoalIndex}/>
                <Route exact path={'/goal/report/:goalId'} component={GoalReport}/>
                <Route path={"/bills"} component={Bill}/>
                <Route path={"/transactions"} component={Transaction}/>
                <Route path={"/report/health-check"} component={HealthCheck}/>
                <Route path={"/user-order/report"} component={UserOrderReport}/>
                <Route path={"/call-logs"} component={CallLogs}/>
                <Route path={"/call-logs-full"} component={CheckFullInfo}/>

                <Route path={"/power-dialer/call-logs"} component={PowerDialerCallLogs}/>

                <Route path="/user/dnc-numbers" component={DncInventory}/>
            </Switch>
        </Router>
    );
}

export default Routes;
