import React from 'react';
import {BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend,ResponsiveContainer} from 'recharts';
import { getGoalStatistics } from '../../../../api/goalApi';
import If from 'if-else-react';
import { AccountCircle, FormatListBulleted, Group, MailOutline, Phone } from '@material-ui/icons';
import GoalSkeleton from '../../../common/Skeletons/GoalSkeleton';


export default class UserReportTab extends React.Component{
    constructor(props) {
        super(props);
        this.state={
            data:[],
            userData:[],
            responseLength:0,
            duration:'today',
            teamUser:0,
            loading:true,
            activity_type:0,
            goalValue:0,
            goalTargetActivity: null,
            goal: null,
            perDayGoalValue: null,
        }
    }
    loadChart=()=>{
        getGoalStatistics({
            type:2,
            chart:'byUser',
            duration:this.props.selectedDateType,
            pipeline:this.props.selectedPipeline,
            teamUser:this.props.selectedTeamUser,
            from:this.props.selectedDateFrom,
            to:this.props.selectedDateTo,
            activity:this.state.activity_type,
        }).then(
                response  => {
                    let res = response.data;
                    let chartData=[];
                    if(res.length>0){
                        res.map(function(data,index){
                            chartData.push({name: data.user.full_name, call: data.call_count, meeting: data.meeting_count, task: data.task_count,email:data.email_count});
                        });
                    }
                    this.setState({data:chartData,userData:res, loading: false});
                    this.setGoalFromGoalSetting();
                }
            );
    }

    setGoalFromGoalSetting = () => {
        let perDayGoalValue = 0;
        let goalValue = 0;

        if (this.props.goal.goal_duration_type == 1) {
            perDayGoalValue = parseFloat(this.props.goal.expected_outcome_value) / 30;
        } else if (this.props.goal.goal_duration_type == 2) {
            perDayGoalValue = parseFloat(this.props.goal.expected_outcome_value) / 30;
        } else if (this.props.goal.goal_duration_type == 5) {
            perDayGoalValue = parseFloat(this.props.goal.expected_outcome_value) / 365;
        }  else if (this.props.goal.goal_duration_type == 6) {
            perDayGoalValue = parseFloat(this.props.goal.expected_outcome_value) / 365;
        }

        if (this.state.duration == 'today') {
            goalValue = perDayGoalValue;
        } else if (this.state.duration == 'yesterday') {
            goalValue = perDayGoalValue;
        } else if (this.state.duration == 'thisweek') {
            goalValue = perDayGoalValue * 7;
        } else if (this.state.duration == 'lastweek') {
            goalValue = perDayGoalValue * 7;
        } else if (this.state.duration == 'thismonth') {
            goalValue = perDayGoalValue * 30;
        } else if (this.state.duration == 'lastmonth') {
            goalValue = perDayGoalValue * 30;
        } else if (this.state.duration == 'thisyear') {
            goalValue = perDayGoalValue * 365;
        } else if (this.state.duration == 'lastyear') {
            goalValue = perDayGoalValue * 365;
        }

        if (this.props.goal.goal_type_key == 2) {
            this.setState({
                goal: this.props.goal,
                perDayGoalValue: perDayGoalValue,
                goalValue: goalValue,
                goalTargetActivity: this.props.goal.goal_type_value
            });
        } else {
            this.setState({
                goal: null,
                perDayGoalValue: null,
                goalValue: 0,
                goalTargetActivity: null
            });
        }
    }

    populateUserTable = () => {
        return this.state.data.map((value, index) => (
            <tr key={index}>
                <td>{value.name}</td>
                <td>{value.call+value.meeting+value.task+value.email}</td>
                <td>{value.call} {this.state.goalTargetActivity == 1 ? <strong>[GOAL:{this.state.goalValue}]</strong> : ''}</td>
                <td>{value.meeting} {this.state.goalTargetActivity == 2 ? <strong>[GOAL:{this.state.goalValue}]</strong> : ''}</td>
                <td>{value.task} {this.state.goalTargetActivity == 3 ? <strong>[GOAL:{this.state.goalValue}]</strong> : ''}</td>
                <td>{value.email} {this.state.goalTargetActivity == 5 ? <strong>[GOAL:{this.state.goalValue}]</strong> : ''}</td>
            </tr>
        ));
    }

    activityTypeChange=(event)=>{
        this.setState({activity_type:event.target.value},()=>{
            this.loadChart();
        });
    }

    renderCustomAxisTick = ({ x, y, payload }) => {
        let path = '';

        switch (payload.value) {
            case 'Call':
                path = 'M899.072 99.328q9.216 13.312 17.92 48.128t16.384 81.92 13.824 100.352 11.264 102.912 9.216 90.112 6.144 60.928q4.096 30.72 7.168 70.656t5.632 79.872 4.096 75.264 2.56 56.832q-13.312 16.384-30.208 25.6t-34.304 11.264-34.304-2.56-30.208-16.896q-1.024-10.24-3.584-33.28t-6.144-53.76-8.192-66.56-8.704-71.68q-11.264-83.968-23.552-184.32-7.168 37.888-11.264 74.752-4.096 31.744-6.656 66.56t-0.512 62.464q1.024 18.432 3.072 29.184t4.608 19.968 5.12 21.504 5.12 34.304 5.12 56.832 4.608 90.112q-11.264 24.576-50.688 42.496t-88.576 29.696-97.28 16.896-74.752 5.12q-18.432 0-46.08-2.56t-60.416-7.168-66.048-12.288-61.952-17.92-49.664-24.064-28.16-30.208q2.048-55.296 5.12-90.112t5.632-56.832 5.12-34.304 5.12-21.504 4.096-19.968 3.584-29.184q2.048-27.648-0.512-62.464t-6.656-66.56q-4.096-36.864-11.264-74.752-13.312 100.352-24.576 184.32-5.12 35.84-9.216 71.68t-8.192 66.56-6.656 53.76-2.56 33.28q-13.312 12.288-30.208 16.896t-34.304 2.56-33.792-11.264-29.696-25.6q0-21.504 2.048-56.832t4.096-75.264 5.632-79.872 6.656-70.656q2.048-20.48 6.144-60.928t9.728-90.112 11.776-102.912 13.824-100.352 16.384-81.92 17.92-48.128q20.48-12.288 56.32-25.6t73.216-26.624 71.168-25.088 50.176-22.016q10.24 13.312 16.896 61.44t13.312 115.712 15.36 146.432 23.04 153.6l38.912-334.848-29.696-25.6 43.008-54.272 15.36 2.048 15.36-2.048 43.008 54.272-29.696 25.6 38.912 334.848q14.336-74.752 23.04-153.6t15.36-146.432 13.312-115.712 16.896-61.44q16.384 10.24 50.176 22.016t71.168 25.088 73.216 26.624 56.32 25.6';
                break;
            case 'Meeting':
                path = 'M662.528 451.584q10.24 5.12 30.208 16.384t46.08 31.744 57.856 52.736 65.024 80.896 67.072 115.2 64.512 154.624q-15.36 9.216-31.232 21.504t-31.232 22.016-31.744 15.36-32.768 2.56q-44.032-9.216-78.336-8.192t-62.976 7.68-53.248 16.896-47.616 19.968-46.08 16.384-49.664 6.656q-57.344-1.024-110.592-16.896t-101.376-32.256-89.6-25.088-75.264 4.608q-20.48 8.192-41.984 1.024t-38.912-18.432q-20.48-13.312-39.936-33.792 37.888-116.736 86.016-199.68t92.672-136.704 78.848-81.408 43.52-33.792q9.216-5.12 10.24-25.088t-1.024-40.448q-3.072-24.576-9.216-54.272l-150.528-302.08 180.224-29.696q27.648 52.224 53.76 79.36t50.176 36.864 45.568 5.12 39.936-17.92q43.008-30.72 80.896-103.424l181.248 29.696q-20.48 48.128-45.056 99.328-20.48 44.032-47.616 97.28t-57.856 105.472q-12.288 34.816-13.824 57.344t1.536 36.864q4.096 16.384 12.288 25.6z';
                break;
            case 'Task':
                path = 'M662.528 451.584q10.24 5.12 30.208 16.384t46.08 31.744 57.856 52.736 65.024 80.896 67.072 115.2 64.512 154.624q-15.36 9.216-31.232 21.504t-31.232 22.016-31.744 15.36-32.768 2.56q-44.032-9.216-78.336-8.192t-62.976 7.68-53.248 16.896-47.616 19.968-46.08 16.384-49.664 6.656q-57.344-1.024-110.592-16.896t-101.376-32.256-89.6-25.088-75.264 4.608q-20.48 8.192-41.984 1.024t-38.912-18.432q-20.48-13.312-39.936-33.792 37.888-116.736 86.016-199.68t92.672-136.704 78.848-81.408 43.52-33.792q9.216-5.12 10.24-25.088t-1.024-40.448q-3.072-24.576-9.216-54.272l-150.528-302.08 180.224-29.696q27.648 52.224 53.76 79.36t50.176 36.864 45.568 5.12 39.936-17.92q43.008-30.72 80.896-103.424l181.248 29.696q-20.48 48.128-45.056 99.328-20.48 44.032-47.616 97.28t-57.856 105.472q-12.288 34.816-13.824 57.344t1.536 36.864q4.096 16.384 12.288 25.6z';
                break;
            case 'Email':
                path = 'M662.528 451.584q10.24 5.12 30.208 16.384t46.08 31.744 57.856 52.736 65.024 80.896 67.072 115.2 64.512 154.624q-15.36 9.216-31.232 21.504t-31.232 22.016-31.744 15.36-32.768 2.56q-44.032-9.216-78.336-8.192t-62.976 7.68-53.248 16.896-47.616 19.968-46.08 16.384-49.664 6.656q-57.344-1.024-110.592-16.896t-101.376-32.256-89.6-25.088-75.264 4.608q-20.48 8.192-41.984 1.024t-38.912-18.432q-20.48-13.312-39.936-33.792 37.888-116.736 86.016-199.68t92.672-136.704 78.848-81.408 43.52-33.792q9.216-5.12 10.24-25.088t-1.024-40.448q-3.072-24.576-9.216-54.272l-150.528-302.08 180.224-29.696q27.648 52.224 53.76 79.36t50.176 36.864 45.568 5.12 39.936-17.92q43.008-30.72 80.896-103.424l181.248 29.696q-20.48 48.128-45.056 99.328-20.48 44.032-47.616 97.28t-57.856 105.472q-12.288 34.816-13.824 57.344t1.536 36.864q4.096 16.384 12.288 25.6z';
                break;
            default:
                path = '';
        }

        return (
            <svg x={x - 12} y={y + 4} width={24} height={24} viewBox="0 0 1024 1024" fill="#666">
                <path d={path} />
            </svg>
        );
    }

    componentDidMount(){
        this.loadChart();
    }

    loadingPanel(){
        if (this.state.loading){
            return (
                <GoalSkeleton/>);
        }else{
            return (
                <div className="goal_no_data_text_wrapper">
                    <div>
                        <h2 className="no_data__loading_title text-center">No activities have been added during the chosen period</h2>
                        <br/>
                        <p className="no_data__loading_subtitle">Try selecting another pipeline or a longer period</p>
                    </div>
                </div>
            );
        }
    }


    componentDidUpdate(){
        if(this.props.activeTab=='by-user' && this.state.duration!=this.props.selectedDateType){
            this.setState({duration:this.props.selectedDateType},() => {
                this.loadChart();
            })
        }else if(this.props.activeTab=='by-user' && this.state.teamUser!=this.props.selectedTeamUser){
            this.setState({teamUser:this.props.selectedTeamUser},() => {
                this.loadChart();
            })
        }
    }

    customTooltipChart = ({ payload, label, active }) => {
        if (active) {
            return (
                <div className="custom-tooltip" style={{backgroundColor:"#ffffff",padding:10,borderRadius:5,fontSize:13,lineHeight:1, width: '250px'}}>
                    <p className="d-flex align-items-center py-1" style={{fontSize:14}}><AccountCircle className={"mr-2"}/> {`${label}`}</p>
                    <p className="d-flex align-items-center justify-content-between py-1"><span className="d-flex align-items-center"><Phone className={"mr-2"}/> Call</span>&nbsp;<span style={{float:"right"}}>{`${payload[0].value}`} [Goal: {this.state.goalTargetActivity == 1 ? this.state.goalValue : '--'}]</span></p>
                    <p className="d-flex align-items-center justify-content-between py-1"><span className="d-flex align-items-center"><Group className={"mr-2"}/> Meeting</span>&nbsp;<span style={{float:"right"}}>{`${payload[1].value}`}  [Goal: {this.state.goalTargetActivity == 2 ? this.state.goalValue : '--'}]</span></p>
                    <p className="d-flex align-items-center justify-content-between py-1"><span className="d-flex align-items-center"><FormatListBulleted className={"mr-2"}/> Task</span>&nbsp;<span style={{float:"right"}}>{`${payload[2].value}`}  [Goal: {this.state.goalTargetActivity == 3 ? this.state.goalValue : '--'}]</span></p>
                    <p className="d-flex align-items-center justify-content-between py-1"><span className="d-flex align-items-center"><MailOutline className={"mr-2"}/> Email</span>&nbsp;<span style={{float:"right"}}>{`${payload[3].value}`}  [Goal: {this.state.goalTargetActivity == 5 ? this.state.goalValue : '--'}]</span></p>
                </div>
            );
        }
        return null;
    }

    render() {

        return(
            <div>
            <If condition={this.props.activeTab=='by-user'}>
                <div className="tab-pane fade in active show">
                    <div className="tabs__content-action">
                        <div className="action_lists">
                            <div className="action_list">
                                <div className="action__input action__input__radio">
                                    <select name={'activityType'} onChange={e=>this.activityTypeChange(e)} defaultValue={this.state.activity_type}>
                                        <option value="0">All Activity</option>
                                        <option value="1">Call</option>
                                        <option value="2">Appointment</option>
                                        <option value="3">Task</option>
                                        <option value="5">Email</option>
                                    </select>
                                </div>
                                {/*<div className="action__input action__input__radio">
                                    <label className="widget-radio active"><input type="radio" name="viewType" value="count"
                                                                                  checked="&quot;checked&quot;/"/>Count</label>
                                    <label className="widget-radio"><input type="radio" name="viewType" value="value"/>Value</label>
                                </div>
                                <div className="action__input action__input__radio">
                                    <label className="widget-radio"><input type="radio" name="userType" value="creator"/>Deal
                                        creator</label>
                                    <label className="widget-radio active"><input type="radio" name="userType" value="owner"
                                                                                  checked="&quot;checked&quot;/"/>Current
                                        owner</label>
                                </div>*/}
                                {/*<div className="action__input action__input__checkbox">*/}
                                {/*    <label className="checkbox">*/}
                                {/*        <input type="checkbox" name="" value="1"/>*/}
                                {/*        <span className="checkbox__label">Divide by current status</span>*/}
                                {/*    </label>*/}
                                {/*</div>*/}
                            </div>
                            <div className="action_list">
                                <div style={{width:"100%",height:400}}>
                                    {(this.state.data.length===0||this.state.loading)?this.loadingPanel():
                                    <ResponsiveContainer>
                                        <BarChart
                                            data={this.state.data}
                                            layout={'vertical'}
                                            margin={{
                                                top: 20, right: 30, left: 20, bottom: 5,
                                            }}
                                        >
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis type="number" tick={{fontSize: 14}}/>
                                            <YAxis dataKey="name" tick={{fontSize: 14}} type="category"/>
                                            <Tooltip content={this.customTooltipChart}/>
                                            <Legend wrapperStyle={{fontSize:14}}/>
                                            <Bar name="Call" dataKey="call" stackId="a" fill={this.props.barColors.call} />
                                            <Bar name="Meeting" dataKey="meeting" stackId="a" fill={this.props.barColors.meeting} />
                                            <Bar name="Task" dataKey="task" stackId="a" fill={this.props.barColors.task} />
                                            <Bar name="Email" dataKey="email" stackId="a" fill={this.props.barColors.email} />
                                        </BarChart>
                                    </ResponsiveContainer>}
                                </div>
                            </div>
                            <If condition={this.state.data.length !== 0}>
                                <div className="action_list">
                                    <table className={'mt-5 table table-bordered text-center'}>
                                        <thead><th>User</th><th>All Type</th><th>Call</th><th>Meeting</th><th>Task</th><th>Email</th></thead>
                                        <tbody>{this.populateUserTable()}</tbody>
                                    </table>
                                </div>
                            </If>
                        </div>
                    </div>
                </div>
            </If>
            </div>
        );
    }
}
