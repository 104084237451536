import React from 'react';
import {LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend,ResponsiveContainer} from 'recharts';
import { getGoalStatistics } from '../../../../api/goalApi';
import If from 'if-else-react';
import CoreConstants from '../../../../constants/CoreConstants';
import GoalSkeleton from '../../../common/Skeletons/GoalSkeleton';

const days=[{xp:'Sat',name:'',value:0,count:0},{xp:'Sun',name:'',value:0,count:0},{xp:'Mon',name:'',value:0,count:0},{xp:'Tue',name:'',value:0,count:0},{xp:'Wed',name:'',value:0,count:0},{xp:'Thu',name:'',value:0,count:0},{xp:'Fri',name:'',value:0,count:0}];

export default class DealStartedByUserOverTime extends React.Component{
    constructor(props) {
        super(props);
        this.state={
            data:[],
            userData:[],
            responseLength:0,
            loading:true,
            daysOfWeek:[],
            startdaysOfWeek:'',
            duration:'today',
            teamUser:0,
            pipeline:this.props.selectedPipeline,
            viewType:'count',
            header:[],
            body:[],
            body_v:[],
            body_c:[],
        }
    }
    loadChart=()=>{
        getGoalStatistics({
            type:5,
            chart:'byUserOverTime',
            duration:this.props.selectedDateType,
            pipeline:this.props.selectedPipeline,
            teamUser:this.props.selectedTeamUser,
            from:this.props.selectedDateFrom,
            to:this.props.selectedDateTo,
        }).then(
                response  => {
                    let res = response.data.res;
                    // table content
                    let table = response.data.table;
                    let c_body=[];
                    let v_body=[];
                    if(table.length>0){
                        table.map((tdata,tindex)=>{
                            c_body.push(tdata.tbody_c)
                            v_body.push(tdata.tbody_v)
                        });
                        this.setState({header:table[0]?table[0].thead:[],body_c:c_body,body_v:v_body});
                    }

                    if(typeof res !== 'undefined' && res.length>0){
                        let chartData=[];
                        let dateval={};

                        res.map((data,index)=>{
                            if(data.length>0){
                                data.map((d,i)=>{
                                    if(this.props.selectedDateType=='thisyear' || this.props.selectedDateType=='lastyear'){
                                        if(index===0 && i === 0){
                                            chartData=[{xp:'Jan',name:[],value:0,count:0},{xp:'Feb',name:[],value:0,count:0},{xp:'Mar',name:[],value:0,count:0},{xp:'Apr',name:[],value:0,count:0},{xp:'May',name:[],value:0,count:0},{xp:'Jun',name:[],value:0,count:0},{xp:'Jul',name:[],value:0,count:0},{xp:'Aug',name:[],value:0,count:0},{xp:'Sep',name:[],value:0,count:0},{xp:'Oct',name:[],value:0,count:0},{xp:'Nov',name:[],value:0,count:0},{xp:'Dec',name:[],value:0,count:0}];
                                        }
                                        chartData[d.xpoint-1].name.push({name:d.full_name,count:d.total_count,value:d.total_value});
                                        chartData[d.xpoint-1].value =chartData[d.xpoint-1].value+d.total_value
                                        chartData[d.xpoint-1].count =chartData[d.xpoint-1].count+d.total_count
                                    }
                                    else if(this.props.selectedDateType=='thisweek' || this.props.selectedDateType=='lastweek'){
                                        if(dateval[d.xpoint]){
                                            dateval[d.xpoint]={
                                                name:dateval[d.xpoint].name.push({name:d.full_name,count:d.total_count,value:d.total_value}),
                                                value:dateval[d.xpoint].value+d.total_value,
                                                count:dateval[d.xpoint].count+d.total_count
                                            }
                                        }else{
                                            dateval[d.xpoint]={name:[{name:d.full_name,count:d.total_count,value:d.total_value}],value:d.total_value,count:d.total_count}
                                        }
                                    }
                                    else if(this.props.selectedDateType=='thismonth' || this.props.selectedDateType=='lastmonth'){
                                        if(dateval[d.xpoint]){
                                            dateval[d.xpoint]={
                                                name:dateval[d.xpoint].name.push({name:d.full_name,count:d.total_count,value:d.total_value}),
                                                value:dateval[d.xpoint].value+d.total_value,
                                                count:dateval[d.xpoint].count+d.total_count
                                            }
                                        }else{
                                            dateval[d.xpoint]={name:[{name:d.full_name,count:d.total_count,value:d.total_value}],value:d.total_value,count:d.total_count}
                                        }
                                    }
                                    else{
                                        chartData.push({xp:d.xpoint,name:[{name:d.full_name,count:d.total_count,value:d.total_value}],count:d.total_count,value:d.total_value});
                                    }
                                });
                            }

                            this.setState({loading:false})
                        });

                        if(res[0].length>0) {
                            if (this.props.selectedDateType == 'thisweek' || this.props.selectedDateType == 'lastweek') {
                                chartData = [];
                                let startOfWeek = response.data.startOdWeek;
                                chartData = days;
                                let split = startOfWeek.split('-');
                                let day = split[2], mon = split[1], year = split[0];

                                for (let i = 0; i < 7; i++) {
                                    let dF = (((parseInt(day) + i) <= 9 ? '0' : '') + (parseInt(day) + i));
                                    chartData[i].xp = mon + '/' + dF + '/' + year;
                                    if (dateval[dF]) {
                                        chartData[i].name = dateval[dF].name
                                        chartData[i].count = dateval[dF].count
                                        chartData[i].value = dateval[dF].value
                                    }

                                }
                            }
                            else if (this.props.selectedDateType == 'thismonth' || this.props.selectedDateType == 'lastmonth') {
                                chartData = [];
                                let swom = response.data.startWeekOfMonth, ewom = response.data.endWeekOfMonth;
                                for (let i = swom; i <= ewom; i++) {
                                    chartData.push({
                                        xp: ('W' + i),
                                        name: dateval[i] ? dateval[i].name : '',
                                        count: dateval[i] ? dateval[i].count : 0,
                                        value: dateval[i] ? dateval[i].value : 0
                                    });
                                }

                            }

                        }
                        this.setState({data: chartData, userData: res, loading: false});
                    }
                    this.setState({loading:false})
                }
            );
    }

    componentDidMount(){
        this.loadChart();
    }
    radioOnClick=(type)=>{
        this.setState({viewType:type});
    }

    loadingPanel(){
        if (this.state.loading){
            return (
                <GoalSkeleton/>);
        }else{
            return (
                <div className="goal_no_data_text_wrapper">
                    <div>
                        <h2 className="no_data__loading_title text-center">No activities have been added during the chosen period</h2>
                        <br/>
                        <p className="no_data__loading_subtitle">Try selecting another pipeline or a longer period</p>
                    </div>
                </div>
            );
        }
    }

    componentDidUpdate(){
        if(this.props.activeTab=='by-user-over-time' && this.state.duration!=this.props.selectedDateType){
            this.setState({duration:this.props.selectedDateType},() => {
                this.loadChart();
            })
        }else if(this.props.activeTab=='by-user-over-time' && this.state.teamUser!=this.props.selectedTeamUser){
            this.setState({teamUser:this.props.selectedTeamUser},() => {
                this.loadChart();
            })
        }else if(this.props.activeTab=='by-user-over-time' && this.state.pipeline!=this.props.selectedPipeline){
            this.setState({pipeline:this.props.selectedPipeline},() => {
                this.loadChart();
            })
        }
    }

    customTooltipChart = ({ payload, label, active }) => {
        if (active) {
            return (
                <div className="custom-tooltip" style={{backgroundColor:"#000000",padding:10,borderRadius:5,fontSize:13,lineHeight:1.2,opacity:0.85}}>
                    <p className="label text-light"><i className="fa fa-calendar"></i> {`${label}`}</p>
                    <p className="intro my-0 text-light"><i className="fa fa-angellist" style={{width:16}}></i>
                        Deals started&nbsp;<span style={{float:"right"}}>{`${payload[0].unit}${parseFloat(payload[0].value).toLocaleString()}`}</span></p>
                    <If condition={payload[0] && payload[0].payload && Array.isArray(payload[0].payload.name)}>
                        {
                            payload[0].payload.name.map((d,i)=>{
                                return <p className="intro my-0 text-light"><i className="fa fa-user" style={{width: 20}}/>
                                    {d.name}&nbsp;<span style={{float:"right"}}>{`${(this.state.viewType==='count'?d.count:CoreConstants.DEAL_CURRENCY+parseFloat(d.value).toLocaleString())}`}</span></p>
                            })
                        }
                    </If>
                </div>
            );
        }
        return null;
    }

    populateUserTable = () => {
        let body=this.state.viewType=='count'?this.state.body_c:this.state.body_v;

       return body.map((data,index)=>{
            return <tr key={index}>
                {
                     data.map((d,i)=>{
                        return <td key={i}>{this.state.viewType=='count' || i==0?d:CoreConstants.DEAL_CURRENCY+parseFloat(d).toLocaleString()}</td>
                })
                }
            </tr>;
        });
    }


    populateUserTableHeader = () => {

        return this.state.header.map((value, index) => {
            return <th key={index}>
                 {value}
             </th>;
        });
    }

    renderCustomAxisTick = ({ x, y, payload }) => {
        return (
            <text x={x} y={y} fill={"#0a0101"} textAnchor="end" dx={0} dy={3} >
                {CoreConstants.DEAL_CURRENCY+parseFloat(payload.value).toLocaleString()}
            </text>
        );
    }

    render() {

        return(
            <div>
                <If condition={this.props.activeTab=='by-user-over-time'}>
                    <div className="tab-pane fade in active show">
                        <div className="tabs__content-action">
                            <div className="action_lists">
                                <div className="action_list">
                                    <div className="action__input action__input__radio">
                                        <label className={(this.state.viewType=='count'?" active ":"")+"widget-radio"} onClick={()=>this.radioOnClick('count')}>
                                            <input  type="radio" name="viewType" value="count"
                                                    checked={false}/>Count</label>
                                        <label className={(this.state.viewType=='value'?" active ":"")+"widget-radio"} onClick={()=>this.radioOnClick('value')}>
                                            <input  type="radio" name="viewType" value="value"/>Value</label>
                                    </div>
                                </div>
                                <div className="action_list">
                                    <div style={{width:"100%",height:400}}>
                                        {(this.state.data.length===0||this.state.loading)?this.loadingPanel():
                                        <ResponsiveContainer>
                                            <LineChart
                                                data={this.state.data}
                                                margin={{
                                                    top: 5, right: 30, left: 20, bottom: 5,
                                                }}
                                            >
                                                <CartesianGrid strokeDasharray="3 10" />
                                                <XAxis dataKey="xp" tick={{fontSize: 14}}/>
                                                <YAxis tick={this.state.viewType==='count'?{fontSize: 14}:this.renderCustomAxisTick}/>
                                                <Tooltip content={this.customTooltipChart}/>
                                                {/*<Legend wrapperStyle={{fontSize:16}}/>*/}
                                                <Line  dataKey={this.state.viewType} stroke="#8884d8" strokeWidth={3} unit={this.state.viewType==='count'?'':CoreConstants.DEAL_CURRENCY} />
                                            </LineChart>
                                        </ResponsiveContainer>}
                                    </div>
                                </div>
                                <If condition={this.state.data.length !== 0 && this.state.totalCount!=0}>
                                    <div className="action_list">
                                        <table className={'mt-5 table table-bordered text-center'} id={'byTime'}>
                                            <thead>{this.populateUserTableHeader()}</thead>
                                            <tbody>{this.populateUserTable()}</tbody>
                                        </table>
                                    </div>
                                </If>
                            </div>
                        </div>
                    </div>
                </If>
            </div>
        );
    }
}
