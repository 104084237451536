import { all, call, put, takeEvery } from 'redux-saga/effects';
import * as ACTION from '../constants/reportActionTypes'
import * as REPORT_API from "../api/reportApi";


function* reportWatcher() {
    yield takeEvery(ACTION.REPORT_GET_DATA, reportGetMiddleware)
}

function* reportGetMiddleware(action) {
    const { payload } = action;
    try {
        yield put({type: ACTION.CHANGE_DATE, payload: {id:payload.id}});
        yield put({type: ACTION.CONTENT_LOADING, payload: {loading : true}});
        const response = yield call(REPORT_API.getReportData, payload);
        yield put({type: ACTION.CONTENT_LOADING, payload: {loading : false}});
        yield put({type: ACTION.CONTENT_LOADED, payload: response.data.data});
    } catch (error) {
        // yield put({type: ACTION.FAILED_FETCH_CAMPAIGNS});
        // Utils.handleException(error);
    }
}   


export default function* reportMiddleware() {
    yield all([
        reportWatcher()
    ])
}