import { useContext, useEffect } from "react";
import ReactEcharts from 'echarts-for-react';
import moment from "moment";
import { getBusinessReportChartV2Api } from "../../../api/reportApi";
import { handleChartLoading, storeCompareData } from "../reducer/Action";
import { ReportViewContext } from "../reducer/ReportV2Reduers";
import CustomCircleLoader from "./CustomCircleLoader";
import ChartViewTopHeader from "./ChartViewTopHeader";

const BusinessChartView = (props) => {
    const {reportStates, detailsDispatch} = useContext(ReportViewContext)

    useEffect(()=> {
        if(!reportStates.isLoadingChart){
            if(reportStates.userType === "user" && reportStates.userId === ""){
                return;
            }
            detailsDispatch(handleChartLoading(true))
            getChartData({
                date: reportStates.date,
                user_type: reportStates.userType,
                compare_for: reportStates.compareFor,
                compare_with: reportStates.compareWith,
                user_id: reportStates.userId
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        // reportStates.userType, 
        reportStates.userId, 
        reportStates.date, 
        reportStates.compareFor, 
        reportStates.compareWith
    ])

    // useEffect(() => {
    //     if(reportStates.addColumn != null){
    //         if(reportStates.userType === "user" && reportStates.userId === ""){
    //             return;
    //         }
    //         detailsDispatch(handleChartLoading(true))
    //         getChartData({
    //             date: reportStates.date,
    //             user_type: reportStates.userType,
    //             compare_for: reportStates.compareFor,
    //             compare_with: reportStates.compareWith,
    //             user_id: reportStates.userId
    //         });
    //     }
    // // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [reportStates.addColumn])

    const getChartData = (payload) => {
        getBusinessReportChartV2Api(payload).then(res => {
            detailsDispatch(storeCompareData({compareFor: res.data.data.compare_for, compareWith: res.data.data.compare_with}))
            detailsDispatch(handleChartLoading(false))
        }).catch(error => {
            detailsDispatch(handleChartLoading(false))
        })
    }

    const renderChartData = () => {

        let calculate = {
            dates : [],
            firstValue : [],
            secondValue : [],

        };

        reportStates.chartDataFor.forEach((item)=> {
            if(item[reportStates.compareFor] !== undefined){
                calculate.dates.push(moment(item.created_at).format("ddd, MMM D YYYY"));
                calculate.firstValue.push(item[reportStates.compareFor])
            }
        })

        if(reportStates.compareWith !== "" && reportStates.chartDataWith != null){
            reportStates.chartDataWith.forEach((item) =>{
                if(item[reportStates.compareWith] !== undefined){
                    calculate.secondValue.push(item[reportStates.compareWith])
                }
            });
        }

        return {
            tooltip : {
                trigger: 'axis',
                axisPointer: {
                    type: 'cross',
                    label: {
                        backgroundColor: '#252d47'
                    }
                }
            },
            toolbox: {
                feature: {
                    saveAsImage: {
                        title : "",
                    }
                }
            },
            legend: {
                data:[reportStates.selectedCompareFor, reportStates.selectedCompareWith]
            },
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true
            },
            xAxis: {
                type: 'category',
                boundaryGap: false,
                data: calculate.dates,
                nameTextStyle : {
                  color:'#ffffff'
                }
            },
            yAxis: {
                type: 'value',
                axisTick: {
                    show: false
                },
                splitLine: {
                    lineStyle: {
                        color: '#f7f7f7'
                    }
                }
            },
            series: [
                {
                    name: reportStates.selectedCompareFor,
                    color: {
                        type: 'linear',
                        x: 0,
                        y: 0,
                        x2: 0,
                        y2: 1,
                        colorStops: [{
                            offset: 0, color: '#114ea9' // color at 0% position
                        }, {
                            offset: 1, color: '#3bd1bf' // color at 100% position
                        }],
                        global: false // false by default
                    },
                    data: calculate.firstValue,
                    type: 'line',
                    areaStyle: {
                        normal: {
                        }
                    },
                    smooth: true,
                    showSymbol: true,
                    showAllSymbol: true,
                    symbol: 'circle',
                    symbolSize: 10,
                    lineStyle: {
                        normal: {
                            width: 2
                        }
                    },
                },
                {
                    name: reportStates.selectedCompareWith,
                    color: {
                        type: 'linear',
                        x: 0,
                        y: 0,
                        x2: 0,
                        y2: 1,
                        colorStops: [{
                            offset: 0, color: '#ff3221' // color at 0% position
                        }, {
                            offset: 1, color: '#e88389' // color at 100% position
                        }],
                        global: false // false by default
                    },
                    data: calculate.secondValue,
                    type: 'line',
                    areaStyle: {
                        normal: {
                        }
                    },
                    smooth: true,
                    showSymbol: true,
                    showAllSymbol: true,
                    symbol: 'circle',
                    symbolSize: 10,
                    lineStyle: {
                        normal: {
                            width: 2
                        }
                    }
            }]
        }
    };

    return(
        <div className="business__report__chart__view__container">
            <div className="business__chart__view">
                <ChartViewTopHeader />
                {reportStates.isLoadingChart ?
                    <CustomCircleLoader className="business__custom__loader" block={false} />
                :
                    (reportStates.chartDataFor == null && reportStates.chartDataWith == null) ?
                        <div className="business_chart_not_created">
                            Business chart report not available
                        </div>
                    :
                    <ReactEcharts  
                    style={{height: '250px', width: '100%'}}
                    className={"Echart__custom"}
                    notMerge={true}
                    lazyUpdate={true}
                    theme={"light"}
                    option={renderChartData()} 
                />
                }
            </div>
        </div>
    );
}
export default BusinessChartView;