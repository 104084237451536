//Report Actions
export const CHANGE_FIRST_COLUMN = 'CHANGE_FIRST_COLUMN';
export const CHANGE_DATE = 'CHANGE_DATE';
export const CONTENT_LOADING = 'CONTENT_LOADING';
export const CONTENT_LOADED = 'CONTENT_LOADED';
export const CHANGE_COLUMNS = 'CHANGE_COLUMNS';
export const CHANGE_SECOND_COLUMN = 'CHANGE_SECOND_COLUMN';
export const REPORT_GET_DATA = 'REPORT_GET_DATA';
export const REPORT_PAGINATION = 'REPORT_PAGINATION';
export const NEED_TO_LOAD = 'NEED_TO_LOAD';
export const REPORT_INFO_UPDATE = 'REPORT_INFO_UPDATE';