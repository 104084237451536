import { useContext, useEffect, useState } from 'react';
import { FormControl,Select, MenuItem } from '@material-ui/core';
import { KeyboardArrowDown } from '@material-ui/icons';
import { ReportViewContext } from '../reducer/ReportV2Reduers';
import { handleAddColumn, handleDateChange, handleUserChange, handleUserTypeChange, storeSubUserData } from '../reducer/Action';
import { AllColumns, AllDateFilters, DefaultColumns } from '../../../constants/CoreConstants';
import Utils from '../../../helpers/Utils';
import { getUserListApi } from '../../../api/reportApi';
import ColumnModal from '../../report/ModalAddColumnForm';

// const usersType = [
//     {value: 'owner', label: "Owner"},
//     {value: 'user', label: "Other User"},
//     // {value: 'all', label: "All"},
// ];

const TopHeader = () => {
    const {reportStates, detailsDispatch} = useContext(ReportViewContext)
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        if(Utils.getAccountData('userIsOwner')){
            getSubUserList();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getSubUserList = () => {
        getUserListApi().then(res =>{
            detailsDispatch(storeSubUserData(res.data.data))
        }).catch(error => {

        })
    }

    // const _handleUserTypeChange = (e) => {
    //     if(e.target.value === "user"){
    //         getSubUserList();
    //     }
    //     detailsDispatch(handleUserTypeChange({userType: e.target.value, selectedUserType: e.target.value}))
    // }

    const _handleUserChange = (e) => {
        if(parseInt(e.target.value) === parseInt(Utils.getAccountData('userId'))){
            detailsDispatch(handleUserTypeChange({userType: 'owner', selectedUserType: 'owner'}))
            detailsDispatch(handleUserChange({userId: e.target.value, selectedUser: e.target.value}))
        }
        else if(e.target.value === "all"){
            detailsDispatch(handleUserChange({userId: 'all', selectedUser: 'all'}))
        }
        else{
            detailsDispatch(handleUserTypeChange({userType: 'user', selectedUserType: 'user'}))
            detailsDispatch(handleUserChange({userId: e.target.value, selectedUser: e.target.value}))
        }
        
    }

    const _handleDateChange = (e) => {
        detailsDispatch(handleDateChange({date: e.target.value, selectedDate: e.target.value}))
    }

    const handleAddColumnCallback = (data) => {
        detailsDispatch(handleAddColumn(data))
    }

    // const renderUserFilterMenu = () => {
    //     let view = [];
    //     usersType.forEach((item, index) => {
    //         view.push(<MenuItem key={index} className={'repot_dropdown_item'} value={item.value}>{item.label}</MenuItem>);
            
    //     });
    //     return view;
    // }

    const renderDateFilterMenu = () => {
        let view = [], index = 0;
        for(var date in AllDateFilters){
            view.push(<MenuItem key={index} className={'repot_dropdown_item dropdownhelper-menuitem-class'} value={date}>{AllDateFilters[date]}</MenuItem>);
            index++;
        }
        return view;
    }

    const renderUserListMenu = () => {
        let view = [];
        reportStates.subUsers.forEach((item, index) => {
            view.push(<MenuItem key={index} className={'repot_dropdown_item dropdownhelper-menuitem-class'} value={item.id}>{item.full_name}</MenuItem>);
            
        });
        return view;
    }

    return(
        <div className="business__report__top__header__container">
            <div className="top__header__title">Total Lead Count And Total Agent Activity</div>
            <div className="top__header__filter">
                <div className='top__header__filter__add__col'>
                    <div className='top__header__filter__add__btn' onClick={() => setIsOpen(true)}>+ Add Columns</div>
                </div>
                {/* {Utils.getAccountData('userIsOwner') &&
                <div className='top__header__filter__select'>
                    <FormControl variant="outlined" margin="none" focused="false" className="business___report__select__wr">
                        <Select
                            className="business_select select__blue__back accent--bg--color"
                            labelId="business__report__user__type__filter"
                            value={reportStates.userType}
                            onChange={_handleUserTypeChange}
                            IconComponent = {KeyboardArrowDown}
                            displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                            MenuProps={{
                                anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left"
                                },
                                transformOrigin: {
                                    vertical: "top",
                                    horizontal: "left"
                                },
                                getContentAnchorEl: null
                            }}
                        >
                            {renderUserFilterMenu()}
                        </Select>
                    </FormControl>
                </div>
                } */}
                
                {/* {reportStates.userType === "user" && */}
                {Utils.getAccountData('userIsOwner') &&
                <div className='top__header__filter__select'>
                    <FormControl variant="outlined" margin="none" focused="false" className="business___report__select__wr">
                        <Select
                            className="business_select select__blue__back accent--bg--color"
                            labelId="business__report__user__filter"
                            value={reportStates.userId}
                            onChange={_handleUserChange}
                            IconComponent = {KeyboardArrowDown}
                            displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                            MenuProps={{
                                anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left"
                                },
                                transformOrigin: {
                                    vertical: "top",
                                    horizontal: "left"
                                },
                                getContentAnchorEl: null
                            }}
                        >
                            <MenuItem className={'repot_dropdown_item dropdownhelper-menuitem-class'} value={''} disabled>{'Select user'}</MenuItem>
                            <MenuItem className={'repot_dropdown_item dropdownhelper-menuitem-class'} value={Utils.getAccountData('userId')}>{Utils.getAccountData('fullName')}</MenuItem>
                            {renderUserListMenu()}
                            {/* <MenuItem className={'repot_dropdown_item'} value={'all'}>{'All'}</MenuItem> */}
                        </Select>
                    </FormControl>
                </div>
                }

                <div className='top__header__filter__select'>
                    <FormControl variant="outlined" margin="none" focused="false" className="business___report__select__wr">
                        <Select
                            className="business_select select__blue__back accent--bg--color"
                            labelId="business__report__date__filter"
                            value={reportStates.date}
                            onChange={_handleDateChange}
                            IconComponent = {KeyboardArrowDown}
                            displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                            MenuProps={{
                                anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left"
                                },
                                transformOrigin: {
                                    vertical: "top",
                                    horizontal: "left"
                                },
                                getContentAnchorEl: null
                            }}
                            name={"user_type"}
                        >
                            {renderDateFilterMenu()}
                        </Select>
                    </FormControl>
                </div>
            </div>
            <ColumnModal 
                allColumns={AllColumns}
                defaultColumns={DefaultColumns}
                title={"Change Column"}
                isOpen={isOpen}
                closeModal={() => setIsOpen(false)}
                addData={handleAddColumnCallback}
            /> 
        </div>
    )
}
export default TopHeader;