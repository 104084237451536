import * as ACTION from "../constants/reportActionTypes";

export const changeFirstColumnFilter = (payload) => {
    return { type: ACTION.CHANGE_FIRST_COLUMN, payload }
};

export const getData = (payload) => ({
    type: ACTION.REPORT_GET_DATA,
    payload: payload 
})

export const changeColumn = (payload) => {
    return { type: ACTION.CHANGE_COLUMNS, payload }
};

export const changeSecColumnFilter = (payload) => {
    return { type: ACTION.CHANGE_SECOND_COLUMN, payload }
};

export const userReportPagination = (payload) => {
    return { type: ACTION.REPORT_PAGINATION, payload }
};

export const userReportReload = (payload) => {
    return { type: ACTION.NEED_TO_LOAD, payload }
};

export const userReportInfoUpdate = (payload) => {
    return { type: ACTION.REPORT_INFO_UPDATE, payload }
};

