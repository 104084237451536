import {useEffect, useState} from "react";
import {getCookie} from "../helpers/Cookie";
import {iconList} from "./globals/IconList";
import './report.css';
import ListSkeleton from "./common/Skeletons/ListSkeleton";
import Api from "../constants/Api";

const Bill = () => {
    const [pageInfo, setPageInfo] = useState({
        currentPage: 1,
        limit: 20,
        previousPageNo: 0,
        lastPage: 0,
        totalPages: 0,
        loading: true,
        totalBills: 0
    });
    const [bills, setBills] = useState(null);

    useEffect(() => {
        fetchBills();
    }, [pageInfo.currentPage]);

    const fetchBills = () => {
        let base_url = Api.billReportBackEnd;
        const apiKey = getCookie(process.env.REACT_APP_ACCESS_TOKEN);

        fetch(`${base_url}?page=${pageInfo.currentPage}&limit=${pageInfo.limit}&order=desc`, {
            headers: {
                'Authorization': 'Bearer ' + apiKey
            }
        }).then(response => {
            response.json().then(data => {
                setBills(data.carrierBills);
                setPageInfo({...pageInfo, lastPage: data.lastPage, totalPages: data.totalPages, loading: false, totalBills: data.totalBills});
            });
        });
    }

    const renderBills = () => {
        return bills.map(bill => {
            return (
                <tr>
                    <td><span>{bill.id}</span></td>
                    <td><span>{bill.totalSmsCount}</span></td>
                    <td><span>{bill.carrierFeeSms}</span></td>
                    <td><span>{bill.totalMmsCount}</span></td>
                    <td><span>{bill.carrierFeeMms}</span></td>
                    <td><span>{bill.totalCarrierFee}</span></td>
                    <td><span>{bill.billDate}</span></td>
                </tr>
            );
        })
    }

    const renderPaginateInfo = () => {
        let start = pageInfo.currentPage <= 1 ? 1 : pageInfo.currentPage - 1;
        let limit = pageInfo.lastPage <= start + 2 ? pageInfo.lastPage : start + 2;
        let paginate = [];

        for(let i = start; i <= limit; i++) {
            paginate.push(<a className={`paginate_button page_number ${pageInfo.currentPage == i ? 'current' : ''}`} onClick={e => goToPage(e, i)} data-dt-idx="1" tabIndex="0">{i}</a>);
        }
        if (pageInfo.totalPages > 4) {
            paginate.push(<span className="mx-2 m--icon-font-size-lg2 font-weight-bold">...</span>);
            for(let i = pageInfo.totalPages - 2; i <= pageInfo.totalPages; i++) {
                paginate.push(<a className={`paginate_button page_number ${pageInfo.currentPage == i ? 'current' : ''}`} onClick={e => goToPage(e, i)}  aria-controls="user-table" data-dt-idx="1" tabIndex="0">{i}</a>);
            }
        }
        return paginate;
    }

    const goToPage = (e, page) => {
        e.preventDefault();
        if (page >= 1 && page <= pageInfo.lastPage) {
            setPageInfo({...pageInfo, currentPage: page});
        }
    }

    return (
        <div className="transaction__report">
            <div className="section__top">
                <div className="section__top__left">
                    <h5>Bill Report</h5>
                </div>
            </div>

            <div className="container-fulid">
                <div className="dataTables_wrapper text-center">
                    {!pageInfo.loading ?
                        <>
                            {(bills !== null && typeof bills[0] !== 'undefined') ?
                                <>
                                    <table className="text-center table table-striped table-bordered table-hover  datatable_list table-middle ">
                                        <thead>
                                        <tr>
                                            <th>
                                                <span>Bill Id</span>
                                            </th>
                                            <th>
                                                <span>Total SMS Count</span>
                                            </th>
                                            <th>
                                                <span>Carrier Fee SMS</span>
                                            </th>
                                            <th>
                                                <span>Total MMS Count</span>
                                            </th>
                                            <th>
                                                <span>Carrier Fee MMS</span>
                                            </th>
                                            <th>
                                                <span>Total Carrier Fee</span>
                                            </th>
                                            <th>
                                                <span>Bill Date</span>
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {renderBills()}
                                        </tbody>
                                    </table>
                                    <div className="user-carrier-bill-table-paginate">
                                        <div className="dataTables_info" role="status" aria-live="polite">
                                            Showing {(pageInfo.limit * (pageInfo.currentPage - 1)) + 1} to {pageInfo.totalBills <= (pageInfo.limit * pageInfo.currentPage) ? pageInfo.totalBills : (pageInfo.limit * pageInfo.currentPage)} of {pageInfo.totalBills} entries
                                        </div>
                                        <div className="dataTables_paginate paging_simple_numbers">
                                            <a className="paginate_button previous" aria-controls="user-table"
                                               onClick={e => goToPage(e, 1)} data-dt-idx="0" tabIndex="0"
                                               id="bill-table_previous">{iconList.doubleLeftArrow}</a>
                                            <a className="paginate_button previous" aria-controls="user-table"
                                               onClick={e => goToPage(e, pageInfo.currentPage - 1)} data-dt-idx="0"
                                               tabIndex="0" id="bill-table_previous">{iconList.leftArrow}</a>
                                            <span>
                                        {renderPaginateInfo()}
                                    </span>
                                            <a className="paginate_button next" aria-controls="user-table" data-dt-idx="4"
                                               onClick={e => goToPage(e, pageInfo.currentPage + 1)} tabIndex="0"
                                               id="bill-table_next">{iconList.rightArrow}</a>
                                            <a className="paginate_button next" aria-controls="user-table" data-dt-idx="4"
                                               onClick={e => goToPage(e, pageInfo.lastPage)} tabIndex="0"
                                               id="bill-table_next">{iconList.doubleRightArrow}</a>
                                        </div>
                                    </div>
                                </>
                                :
                                <p className="not-found">No carrier bill found</p>
                            }
                        </>
                        :
                        <>
                            <ListSkeleton number='14' width='100%' />
                        </>
                    }

                </div>
            </div>
        </div>
    );
}

export default Bill;
