import React from 'react';
import DealLostByuser from "../dealLost/deallostByuser";
import DealLostByuserOverTime from "../dealLost/dealLostByuserOverTime";
import DealLostByStage from "../dealLost/dealLostByStage";
import DealLostByStageOverTime from "../dealLost/dealLostByStage";
import If from 'if-else-react';
import { Tab, Tabs } from '@material-ui/core';


class DealsLost extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab:'by-user'
        };
    };

    showTab=(tab)=>{
        this.setState({activeTab:tab});

    }

    render() {
        const {activeTab}=this.state;
        const {selectedDateType,selectedPipeline,selectedTeamUser,selectedDateFrom,selectedDateTo}=this.props;
        return (
            <React.Fragment>
                <div class="goalreport__right effort__report">
                    <div class="effort__top__wrapper">
                        <div class="goalreport__right__top effort__right__top">
                            <h2 className="goalreport__right__top_title">How many deals were lost?</h2>
                            <div className="">
                                 <Tabs className="effor__tab__topper" value={activeTab} onChange={(e,tabValue) => this.showTab(tabValue)}>
                                    <Tab className="effort__tab__button" value={'by-user'} label="by Users"/>
                                    <Tab className="effort__tab__button" value={'by-user-over-time'} label="by Users over time"  />
                                </Tabs>
                            </div>
                        </div>
                        <div className="goalreport__main__content effort__main__content">
                            <If condition={activeTab=='by-user'}>
                                <DealLostByuser activeTab={activeTab}
                                                selectedDateType={selectedDateType}
                                                selectedPipeline={selectedPipeline}
                                                selectedTeamUser={selectedTeamUser}
                                                selectedDateFrom={selectedDateFrom}
                                                selectedDateTo={selectedDateTo}
                                                goal={this.props.goal}
                                />
                            </If>
                            <If condition={activeTab=='by-user-over-time'}>
                                <DealLostByuserOverTime activeTab={activeTab}
                                                selectedDateType={selectedDateType}
                                                selectedPipeline={selectedPipeline}
                                                selectedTeamUser={selectedTeamUser}
                                                selectedDateFrom={selectedDateFrom}
                                                selectedDateTo={selectedDateTo}
                                />
                            </If>
                            <If condition={activeTab=='by-stages'}>
                                <DealLostByStage activeTab={activeTab}
                                                selectedDateType={selectedDateType}
                                                selectedPipeline={selectedPipeline}
                                                selectedTeamUser={selectedTeamUser}
                                                selectedDateFrom={selectedDateFrom}
                                                selectedDateTo={selectedDateTo}
                                />
                            </If>
                            <If condition={activeTab=='by-stages-over-time'}>
                                <DealLostByStageOverTime activeTab={activeTab}
                                                selectedDateType={selectedDateType}
                                                selectedPipeline={selectedPipeline}
                                                selectedTeamUser={selectedTeamUser}
                                                selectedDateFrom={selectedDateFrom}
                                                selectedDateTo={selectedDateTo}
                                />
                            </If>

                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default DealsLost
