import React, {useContext, useEffect, useState} from "react";
import "./power-dialer-call-logs.css";
import {Link} from "react-router-dom";
import MEISCustomTable from "../../common/custom-components/custom-table/MEISCustomTable";
import CustomMenuPype from "../../common/custom-components/custom-menu/CustomMenuPype";
import {Icons} from "../../common/custom-components/custom-table/components/Icons";
import {getPowerDialerCallLogsApi, getPowerDialerDropdownDataApi} from "../../../api/reportApi";
import Utils from "../../../helpers/Utils";
import TextTruncateWithTooltip from "./TextTruncateWithTooltip";
import Styles from "./PowerDialerCallLogs.module.css";
import BootstrapTooltip from "../../globals/BootstrapTooltip";
import BasicSelect from "../../common/BasicSelect";
import {Button} from "@material-ui/core";
import {ArrowBack} from "@material-ui/icons";
import {HistoryProvider} from "../../../App";

const back_icon = (<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M14.1258 6.7925L12.8333 5.5L7.33331 11L12.8333 16.5L14.1258 15.2075L9.92748 11L14.1258 6.7925Z" fill="#1C1B1F"/>
</svg>);
const next_icon = (<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M9.16671 5.5L7.87421 6.7925L12.0725 11L7.87421 15.2075L9.16671 16.5L14.6667 11L9.16671 5.5Z" fill="#1C1B1F"/>
</svg>);

function formatPhoneNumber(phoneNumberString) {
  if (!phoneNumberString){
    return 'N/A';
  }

  if (phoneNumberString.length < 11){
    phoneNumberString = '1' + phoneNumberString;
  }
  return phoneNumberString.replace(/\D+/g, '').replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, '+$1 ($2) $3-$4');
}

function clean(str)
{
  if (str){
    return str;
  }
  return '';
}

const PowerDialerCallLogs = () => {
  const history = useContext(HistoryProvider);
  const [perPage, setPerPage] = useState(20);
  const [powerDialerCallLogs, setPowerDialerCallLogs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [selectedPowerDialer, setSelectedPowerDialer] = useState(null);
  const [selectedOutcome, setSelectedOutcome] = useState(null);
  const [powerDialerCallOutcomes, setPowerDialerCallOutcomes] = useState([]);
  const [powerDialerList, setPowerDialerList] = useState([]);
  const [powerDialerKeyValue, setPowerDialerKeyValue] = useState(null);

  const [filterQuery, setFilterQuery] = useState({
    page: 1,
    limit: 20,
  });

  useEffect(()=>{
    getPowerDialerCallLogsService(filterQuery);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[filterQuery]);

  useEffect(()=>{
    getPowerDialerDropdownDataApi({ agency_id: Utils.getAccountData("agencyId") }).then((res)=>{
      if (res.success){
        if (res.data.power_dialer_call_outcomes){
          setPowerDialerCallOutcomes(res.data.power_dialer_call_outcomes);
        }
        if(res.data.power_dialer_lists){
          setPowerDialerList(res.data.power_dialer_lists);
          let listIds = res.data.power_dialer_lists;

          if (Array.isArray(listIds)){
            const result = listIds.reduce(function (r, e) {
              r[e.id] = e.title;
              return r;
            }, {});
            setPowerDialerKeyValue(result);
          }

        }
      }
    });
  },[]);

  const getPowerDialerCallLogsService = (payload) => {
    try{
      payload.agency_id = Utils.getAccountData("agencyId");

      setIsLoading(true);

      if (payload.power_dialer_list_id == null){
        delete payload.power_dialer_list_id;
      }

      if (payload.user_call_outcome_id == null){
        delete payload.user_call_outcome_id;
      }

      getPowerDialerCallLogsApi(payload).then((res) => {
        setPowerDialerCallLogs(res.data.call_logs);
        if(currentPage === 1){
          setTotalPages(res.data.totalPages);
        }
        setCurrentPage(res.data.currentPage);
      }).catch((err)=>{
        setPowerDialerCallLogs([]);
      }).finally(()=>{
        setIsLoading(false);
      });
    }catch (err){
      setPowerDialerCallLogs([]);
    }
  };

  const onClickEach = (contact_id) => {

  };

  const onChangePage = (page) => {
    page = page.replace(/[a-z|A-Z|!@#$%^&* ]/g,"");
    if (page !== "" && page > 0) {
      if (totalPages >= page) {
        setFilterQuery({ ...filterQuery, page: parseInt(page) });
        setCurrentPage(parseInt(page));
      }
    }else{
      setCurrentPage("");
    }
  }

  const handleStepChange = (event, page) => {
    event.preventDefault();
    if (page != null && page > 0) {
      setFilterQuery({ ...filterQuery, page: parseInt(page) });
      setCurrentPage(page);
    }
  }

  const updateDatatable = (dropdownHeader, value) => {
    setCurrentPage(1);
    setFilterQuery({ ...filterQuery, page: 1, [dropdownHeader]: value });
  };

  let previous_page = null;
  let next_page = null;

  if (currentPage > 1) {
    previous_page = currentPage - 1;
  }

  if (currentPage !== totalPages) {
    if (typeof currentPage === "string" && currentPage !== ""){
      let parsedCurrentPage = parseInt(currentPage);
      setCurrentPage(parsedCurrentPage);
    }
    next_page = currentPage + 1;
  }


  const renderFilter = () => {
    return(
        <div className="d-flex justify-content-between align-items-center">
          <div className="campaign_report_select_wrapper_v2 ml-2">
              <h5>Power Dialer Call Logs</h5>
          </div>
          <div style={{display:'flex',alignItems:'center', gap:'24px'}}>
            {
              history?.location?.state?.back &&
                <Button variant="contained" color="primary" startIcon={<ArrowBack/>} onClick={()=>{ window.open("/power-dialer", "_self") }}>
                  Back to Power Dialer
                </Button>
            }
            <div style={{width:'350px'}}>
              <BasicSelect
              fullWidth
              options={powerDialerList}
              value={selectedPowerDialer || ''}
              defaultText="All Power Dialer Lists"
              displayEmpty
              onChange={(e)=>{
                setSelectedPowerDialer(e.target.value);
                updateDatatable("power_dialer_list_id",e.target.value);
              }}
              />
            </div>
            <div style={{width:'350px'}}>
              <BasicSelect
              fullWidth
              options={powerDialerCallOutcomes}
              value={selectedOutcome || ''}
              defaultText="All Call Outcomes"
              displayEmpty
              onChange={(e)=>{
                setSelectedOutcome(e.target.value);
                updateDatatable("user_call_outcome_id",e.target.value);
              }}
               mapping={{label:'outcome', value:'id'}}
              />
            </div>
          </div>
        </div>
    );
  }

  const renderPagination = () => {
    return (
        totalPages !== 0 ?
            <div className="power-dialer-call_logs_table_pagination">
              <div className="rftp_table_per_page_select">
                <span>Rows per page:</span>
                <div>
                  <CustomMenuPype
                      wrapperClass="mlvc_row_per_page_dropdown"
                      popup= {{
                        placement: "center",
                        list: [
                          {label: "10", value: 10},
                          {label: "20", value: 20},
                          {label: "30", value: 30},
                          {label: "50", value: 50},
                          {label: "100", value: 100},
                        ],
                        onClick: (item) => {
                          if(item.value === perPage){
                            return;
                          }else {
                            setPerPage(parseInt(item.value));
                            setCurrentPage(1);
                            setFilterQuery({ ...filterQuery, limit: parseInt(item.value), page: 1 });
                          }
                        },
                        height: "200px",
                        width: "80px",
                        listStyle: {
                          justifyContent: "center"
                        }
                      }}
                      label= {{
                        renderCustom: (
                            <div className="power-dialer-call_logs_perpage_body">
                              <span className="clpb_label">{perPage}</span>
                              <span className="clpb_icon">{Icons.arrow_down}</span>
                            </div>
                        )
                      }}
                  />
                </div>
              </div>
              <div className="power-dialer-call-logs_pagination">
                <span className={`rftp_back_button  ${previous_page == null ? "rftp_bb_disabled":""}`} onClick={(event) => handleStepChange(event, previous_page)}>{back_icon}</span>
                <input type="text" className="rftp_input_field" value={currentPage} onChange={(event)=> {onChangePage(event.target.value)}}/>
                <span className="rftp_divider"></span>
                <input className="rftp_input_field_total" readOnly value={totalPages}/>
                <span className={`rftp_next_button ${next_page == null ? "rftp_bb_disabled":""}`} onClick={(event) => handleStepChange(event, next_page)}>{next_icon}</span>
              </div>
            </div> : <div/>
    );
  }
  const convertDate = (date)=>{
    if(date){
      return window.globalTimezoneConversionToDifferentTimezone(date,"UTC",Utils.getAccountData('userTimezoneRaw'), "MMMM Do YYYY h:mm A");
    }
    return '-'

  }
  return (
      <MEISCustomTable
          tableWrapperclass="power-dialer-call_logs_ria"
          isLoading={isLoading}
          data={powerDialerCallLogs}
          customHeader={renderFilter()}
          renderPagination={renderPagination()}
          rowDecoration={[
            {
              title: <div className="power-dialer-call-logs_th" style={{ color: "#546376", fontWeight: "normal", marginLeft: "16px" }}>Lead Info</div>,
              value: (row) => {
                return <Link to={`/contacts/${row.contact_id}`} target="_blank">
                  <div
                       style={{cursor: "pointer", color: "#1C1B1F", marginLeft: "16px"}}
                       onClick={() => onClickEach(row.id)}
                  >
                    {
                      <div className="power-dialer-call-logs_display_name">
                        <span className="power-dialer-call-logs_display_icon"><svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.75 2.842c0 1.567 1.234 2.842 2.75 2.842S8.25 4.41 8.25 2.842C8.25 1.275 7.016 0 5.5 0S2.75 1.275 2.75 2.842ZM10.389 12H11v-.632c0-2.437-1.92-4.42-4.278-4.42H4.278C1.918 6.947 0 8.93 0 11.367V12h10.389Z" fill="#546376"/></svg></span>
                        <TextTruncateWithTooltip text={`${clean(row['contact_first_name'])} ${clean(row['contact_last_name'])}`}/>
                      </div>
                    }

                    {
                      (row['contact_number'] && !row['to_number']) && (
                            <div className="log_contact_number">
                              <span className="power-dialer-call-logs_display_icon"><svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m9.439 7.136-1.48-.17a1.159 1.159 0 0 0-.955.333L5.932 8.37a8.764 8.764 0 0 1-3.839-3.84l1.078-1.077c.25-.25.373-.6.332-.955L3.334 1.03A1.166 1.166 0 0 0 2.174 0H1.168C.51 0-.039.548.002 1.206a9.898 9.898 0 0 0 9.256 9.256c.658.04 1.206-.507 1.206-1.165V8.289a1.155 1.155 0 0 0-1.025-1.153Z" fill="#546376"/></svg></span>
                              <TextTruncateWithTooltip text={formatPhoneNumber(row['contact_number'])}/>
                            </div>
                        )
                    }

                    {
                        row['to_number'] && (
                            <div className="log_contact_number">
                              <span className="power-dialer-call-logs_display_icon"><svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m9.439 7.136-1.48-.17a1.159 1.159 0 0 0-.955.333L5.932 8.37a8.764 8.764 0 0 1-3.839-3.84l1.078-1.077c.25-.25.373-.6.332-.955L3.334 1.03A1.166 1.166 0 0 0 2.174 0H1.168C.51 0-.039.548.002 1.206a9.898 9.898 0 0 0 9.256 9.256c.658.04 1.206-.507 1.206-1.165V8.289a1.155 1.155 0 0 0-1.025-1.153Z" fill="#546376"/></svg></span>
                              <TextTruncateWithTooltip text={formatPhoneNumber(clean(row['to_number']))}/>
                            </div>
                        )
                    }

                    {
                      row['contact_email'] && (
                            <div className="log_contact_email_email">
                              <span className="power-dialer-call-logs_display_icon"><svg width="13" height="11" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.583.416A2.083 2.083 0 0 0 .5 2.499v.21l6.25 3.365L13 2.71V2.5A2.083 2.083 0 0 0 10.917.415H2.583Z" fill="#546376"/><path d="M13 3.892 6.997 7.124a.52.52 0 0 1-.494 0L.5 3.892v4.857a2.083 2.083 0 0 0 2.083 2.083h8.334A2.083 2.083 0 0 0 13 8.75V3.892Z" fill="#546376"/></svg></span>
                              <TextTruncateWithTooltip text={clean(row['contact_email'])}/>
                            </div>
                        )
                    }
                  </div>
                </Link>;
              },
              width: "unset",
            },
            {
              title: <div style={{ color: "#546376", fontWeight: "normal", marginLeft: "10px",textAlign:'center' }}>Power Dialer List</div>,
              value: (row) => {
                return <div className="power-dialer-call-logs_th--value" style={{cursor: "pointer", color: "#1C1B1F"}} onClick={() => onClickEach(row.id)}>
                  {powerDialerKeyValue && (powerDialerKeyValue[row['power_dialer_list_id']] ? <TextTruncateWithTooltip text={powerDialerKeyValue[row['power_dialer_list_id']]} /> : (<span style={{ color: "red" }}>deleted</span>)) }
                </div>
              },
              width: "unset"
            },
            {
              title: <div style={{ color: "#546376", fontWeight: "normal",textAlign:"center" }}>Date</div>,
              value: (row) => {
                return <div className="power-dialer-call-logs_th--value" style={{cursor: "pointer", color: "#1C1B1F"}} onClick={() => onClickEach(row.id)}>
                  {convertDate(row['created_at'])}
                </div>
              },
              width: "unset"
            },
            {
              title: <div style={{ color: "#546376", fontWeight: "normal", marginLeft: "10px",textAlign:'center' }}>Number</div>,
              value: (row) => {
                return <div className="power-dialer-call-logs_th--value" style={{cursor: "pointer", color: "#1C1B1F"}} onClick={() => onClickEach(row.id)}>
                  <TextTruncateWithTooltip text={formatPhoneNumber(row['from_number'])}/>
                </div>
              },
              width: "unset"
            },
            {
              title: <div style={{ color: "#546376", fontWeight: "normal",textAlign:'center' }}>Duration</div>,
              value: (row) => {
                return <div className="power-dialer-call-logs_th--value" style={{cursor: "pointer", color: "#1C1B1F"}} onClick={() => onClickEach(row.id)}>
                  { new Date(row['call_duration'] * 1000).toISOString().substring(11, 19) }
                </div>
              },
              width: "unset"
            },
            {
              title: <div style={{ color: "#546376", fontWeight: "normal", textAlign: "center" }}>Connected?</div>,
              value: (row) => {
                return <div className="power-dialer-call-logs_th--value" style={{cursor: "pointer", color: "#1C1B1F", textAlign: "center"}} onClick={() => onClickEach(row.id)}>
                  { row['total_contacted'] > 0  ? Icons.checkMark : Icons.crossMark}
                </div>
              },
              width: "unset"
            },
            {
              title: <div style={{ color: "#546376", fontWeight: "normal",textAlign:'center' }}>Outcome</div>,
              value: (row) => {
                return <div className="power-dialer-call-logs_th--value" style={{cursor: "pointer", color: "#1C1B1F"}} onClick={() => onClickEach(row.id)}>
                  {row['call_outcome']}
                </div>
              },
              width: "unset"
            },
            {
              title: <div style={{ color: "#546376", fontWeight: "normal",textAlign:'center' }}>Call Time</div>,
              value: (row) => {
                return <div className="power-dialer-call-logs_th--value" style={{cursor: "pointer", color: "#1C1B1F"}} onClick={() => onClickEach(row.id)}>
                  {row['created_at'] ? window.globalTimezoneConversionToDifferentTimezone(row['created_at'], '', '', 'from_now') : ""}
                </div>
              },
              width: "unset"
            },
            {
              title: <div style={{ color: "#546376", fontWeight: "normal", textAlign: "center" }}>Recording</div>,
              value: (row) => {
                return <div
                    className="power-dialer-call-logs_th--value"
                    style={{cursor: "pointer", color: "#1C1B1F", textAlign: "center" }}
                    onClick={() => onClickEach(row.id)}
                >
                  { row['call_recording_url'] ?
                      (
                          <audio className={`${Styles.audio} audioPlayer`} controls>
                            <source src={row['call_recording_url']} type="audio/mp3"/>
                                Your browser does not support the audio element.
                          </audio>
                      )
                      : ''
                  }
                </div>
              },
              width: "unset"
            },
            {
              title: <div style={{ color: "#546376", fontWeight: "normal", textAlign: "center" }}>Call Notes</div>,
              value: (row) => {
                return <div
                    className="power-dialer-call-logs_th--value"
                    style={{cursor: "pointer", color: "#1C1B1F", textAlign: "center", border: (row['notes'] ? "solid 1px #b59a9a" : 0), borderRadius: (row['notes'] ? "4px" : 0) }}
                    onClick={() => onClickEach(row.id)}
                >
                  <BootstrapTooltip placement={"top-start"} arrow title={<span>{row['notes']}</span>}>
                    <div className={Styles.noteText}>
                      {row['notes']}
                    </div>
                  </BootstrapTooltip>
                </div>
              },
              width: "250px"
            }
          ]}
          emptyContainer={{
            text: "No Power Dialer Call Logs",
          }}
      />
  );
};

export default PowerDialerCallLogs;