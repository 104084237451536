import React from 'react';
import DealStartedByuser from "../dealStarted/dealStartedByuser";
import DealStartedByUserOverTime from "../dealStarted/DealStartedByUserOverTime";

import If from 'if-else-react';
import { Tab, Tabs } from '@material-ui/core';

class DealStarted extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab:'by-user'
        };
    };

    showTab=(tab)=>{
        this.setState({activeTab:tab});
    }

    render() {
        const {activeTab}=this.state;
        const {selectedDateType,selectedPipeline,selectedTeamUser,selectedDateFrom,selectedDateTo}=this.props;
        return (
            <React.Fragment>
                <div class="goalreport__right effort__report">
                    <div class="effort__top__wrapper">
                        <div class="goalreport__right__top effort__right__top">
                            <h2 className="goalreport__right__top_title">How many new deals were started?</h2>
                            <div className="">
                                 <Tabs variant="scrollable" className="effor__tab__topper" value={activeTab} onChange={(e,tabValue) => this.showTab(tabValue)}>
                                    <Tab className="effort__tab__button" value={'by-user'} label="by Users"/>
                                    <Tab className="effort__tab__button" value={'by-user-over-time'} label="by Users over time"  />
                                </Tabs>
                            </div>
                        </div>
                    
                        <div class="goalreport__main__content effort__main__content">
                            <If condition={activeTab=='by-user'}>
                                <DealStartedByuser activeTab={activeTab}
                                                selectedDateType={selectedDateType}
                                                selectedPipeline={selectedPipeline}
                                                selectedTeamUser={selectedTeamUser}
                                                selectedDateFrom={selectedDateFrom}
                                                selectedDateTo={selectedDateTo}
                                                goal={this.props.goal}
                                />
                            </If>
                            <If condition={activeTab=='by-user-over-time'}>
                                <DealStartedByUserOverTime
                                    activeTab={activeTab}
                                        selectedDateType={selectedDateType}
                                        selectedPipeline={selectedPipeline}
                                        selectedTeamUser={selectedTeamUser}
                                        selectedDateFrom={selectedDateFrom}
                                        selectedDateTo={selectedDateTo}
                                        goal={this.props.goal}
                                    />
                            </If>

                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default DealStarted
