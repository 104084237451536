import { Icons } from "../components/Icons";
import BootstrapTooltip from "../../../../globals/BootstrapTooltip";

const EachTableRow = (props) => {

    const checkSelect = (id) => {
        if(props.selectedIds === "__ALL__"){
            return true;
        }
        if(props.selectedIds === ""){
            return false;
        }
        if(props.selectedIds.includes(`id_${id};`)){
            return true;
        }
        return false;
    }

    const renderSelectBox = (item) => {
        let check_box_field_data = item.id;
        if(props.select?.checkBoxField !== undefined){
            check_box_field_data = item[props.select?.checkBoxField]
        }
        const flag = checkSelect(check_box_field_data)

        if (props.select.selectable !== undefined && (props.select.selectable.key && props.select.selectable.value && item[props.select.selectable.key] !== props.select.selectable.value)){
            return (
                <td>
                    <BootstrapTooltip arrow placement={"top"} title={<p>Selection not allowed</p>}>
                        <span className="meis_ctmw_table_check_svg" style={{ cursor: "not-allowed" }}>
                            {Icons.unCheck}
                        </span>
                    </BootstrapTooltip>
                </td>
            );
        }

        return (
            <td>
                <span className="meis_ctmw_table_check_svg"
                    onClick={() => props.onChangeSelected(`id_${check_box_field_data};`)}
                    key={`id_${check_box_field_data};`}
                >
                    {flag ? Icons.check : Icons.unCheck}
                </span>
            </td>
        );
    }

    const renderRow = (item, index) => {
        const view = [];
        props.rowDecoration.forEach((rItem, rIndex) => {
            view.push(
                <td style={{textAlign:rIndex===0?'left':'center'}} key={`${index}-${rIndex}`} index={`${index}-${rIndex}`}>
                    {rItem.value(item)}
                </td>
            )
        });
        return view;
    }

    const renderEachRow = () => {
        const view = [];
        props.data.forEach((item, index) => {
            view.push(
                <tr key={index}>
                    {props.select !== undefined && renderSelectBox(item, index)}
                    {renderRow(item, index)}
                </tr>
            )
        })
        return view;
    }

    return (
        <tbody>
            {renderEachRow()}
        </tbody>
    );
}
export default EachTableRow;