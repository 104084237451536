import ActionType from "./ActionType";

export const handleChartLoading = (payload) => ({
    type: ActionType.HANDLE_LOADING_CHART,
    payload: payload
});

export const handleUserTypeChange = (payload) => ({
    type: ActionType.HANLDE_USER_TYPE_CHANGE,
    payload: payload
});

export const handleUserChange = (payload) => ({
    type: ActionType.HANLDE_USER_CHANGE,
    payload: payload
});

export const handleDateChange = (payload) => ({
    type: ActionType.HANDLE_DATE_CHANGE,
    payload: payload
});

export const handleCompareForChange = (payload) => ({
    type: ActionType.HANDLE_COMPARE_FOR_CHANGE,
    payload: payload
});

export const handleCompareWithChange = (payload) => ({
    type: ActionType.HANDLE_COMPARE_WITH_CHANGE,
    payload: payload
});

export const storeCompareData = (payload) => ({
    type: ActionType.STORE_COMPARE_DATA,
    payload: payload
});

export const storeSubUserData = (payload) => ({
    type: ActionType.STORE_SUB_USER_DATA,
    payload: payload
});

export const handleCardLoading = (payload) => ({
    type: ActionType.HANDLE_LOADING_CARD,
    payload: payload
});

export const storeCardData = (payload) => ({
    type: ActionType.STORE_CARD_DATA,
    payload: payload
});

export const handleAddColumn = (payload) => ({
    type: ActionType.STORE_ADD_COLUMN_DATA,
    payload: payload
})