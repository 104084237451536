import { useEffect } from "react";
import Utils from "../../helpers/Utils";
import BusinessCardView from "./components/CardView";
import BusinessChartView from "./components/ChartView";
import TopHeader from "./components/TopHeader";
import './css/business-report-v2.css'

const ReportView = () => {
    useEffect(() => {
        /* todo: change page title */
        document.title = `Business Report | ${Utils.getAccountData('AuthUserAgencyName')}`;
        if(window.setActiveSidebar) {
            window.setActiveSidebar('report');
        }

        /* todo: check data in local storage */

        /* add a mechanism for old data without today data */
    }, [])

    return (
        <div className="business__report__view__v2__container">
            <TopHeader />
            <BusinessChartView />
            <BusinessCardView />
            {/* {Utils.getAccountData("userIsOwner") &&
            <BusinessTableView />
            } */}
        </div>
    );
}
export default ReportView;