const rootUrl = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/report`
const contactModuleUrl = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/contacts`
const backEndRootUrl = `${process.env.REACT_APP_JAVAEND_URL}/${process.env.REACT_APP_API_VERSION}/`
const tenantPowerDialerUrl = `${process.env.REACT_APP_PYPEPRO_TENANT_BACKEND_SERVER}/power-dialer`;
const tenantBackendV1 = `${process.env.REACT_APP_PYPEPRO_TENANT_BACKEND_SERVER}`;

const Api = {
    reportData: `${rootUrl}/get-report-data`,
    loadData: `${rootUrl}/load-data`,
    loadGoalData: `${rootUrl}/load-goal-data`,
    userSearch : `${rootUrl}/user-search`,
    groupSearch : `${rootUrl}/group-search`,
    updateSetting : `${rootUrl}/update-setting`,
    reloadGoalData : `${rootUrl}/reload-data`,
    deleteGoal : `${rootUrl}/delete-goal`,
    goalPipeline : `${rootUrl}/goal-pipeline`,
    goalStage : `${rootUrl}/goal-stage`,
    goalTeam : `${rootUrl}/goal-team`,
    goalUser : `${rootUrl}/goal-user`,
    createNewGoal : `${rootUrl}/create-new-goal`,
    goalStatistics : `${rootUrl}/goal-statistics`,
    goalReport : `${rootUrl}/goal-report`,
    goalReportPopulateData : `${rootUrl}/goal-report-populate-data`,
    billReportBackEnd : `${backEndRootUrl}credit/carrier/bills`,
    transitionReportBackEnd : `${backEndRootUrl}credit/carrier/transactions`,
    getUserReportData : `${backEndRootUrl}orders/users`,
    getUserOrderReports: `${backEndRootUrl}orders/reports`,
    getUserOrderReportCharts: `${backEndRootUrl}orders/chart`,

    /* todo: business report v2 api */
    getBusinessReportChartV2: `${rootUrl}/business/report/chart/data`,
    getSubUserList: `${rootUrl}/get-sub-user-list`,
    getBusinessReportCardV2: `${rootUrl}/business/report/card/data`,

    /* todo: get call records */

    getCallLog: `${rootUrl}/get-call-logs`,

    subUserListForContactDropdown: `${contactModuleUrl}/sub-users-optimized`,

    callOutcomeListForCallLogDropdown: `${rootUrl}/get-call-outcome-list`,

    getUserAndVirtualNumberArray: `${rootUrl}/get-user-virtual-number-data`,

    getOutgoingCallCount: `${rootUrl}/outgoing-call-list-by-date`,

    getOutgoingCallOutcome: `${rootUrl}/outgoing-call-outcome-list-by-date`,

    getPowerDialerCallLogsApi: `${tenantPowerDialerUrl}/call/user-wise/call-logs`,

    getPowerDialerDropdownDataApi: `${tenantPowerDialerUrl}/call-log-dropdown-data`,

    getDncInventory: `${contactModuleUrl}/get-dnc-inventory`,

    updateNumberDncStatus: `${tenantBackendV1}/contacts/dnc/update-number-dnc-status`,
}

export default Api;