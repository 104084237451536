import React, {useState} from "react";
import "date-fns";
import {icons} from "../icon";
import BootstrapTooltip from "../../globals/BootstrapTooltip";
import {DropdownHelper} from "../../globals/DropdownHelper";
import {DateRangePicker} from "react-date-range";
import {addDays} from "date-fns";
import {formatDateWithoutZone} from "../../globals/TimezoneConversion";
import Utils from "../../../helpers/Utils";
import {FormControl} from "@material-ui/core";
import {iconList} from "../../globals/IconList";
import "./styles.css";
import "./theme/default.css";

const inOut = [
  {
    value: "All Calls"
  },
  {
    id: 1,
    value: "Incoming Calls"
  },
  {
    id: 2,
    value: "Outgoing Calls"
  }
];

const startDate = new Date();
const endDate = new Date();

const formattedStartDate = formatDateWithoutZone(startDate, "MM/DD/YYYY");
const formattedEndDate = formatDateWithoutZone(endDate, "MM/DD/YYYY");

const userTimezoneRaw = Utils.getAccountData('userTimezoneRaw');

const FilterCallLog = (props) => {
  const [filterQuery, setFilterQuery] = useState({});
  const [selectedOwner, setSelectedOwner] = useState("");
  const [selectedOutCome, setSelectedOutCome] = useState("");
  const [selectedInOut, setSelectedInOut] = useState("");
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [searchTimeoutToken, setSearchTimeoutToken] = useState(null);
  const [datePickerActive, setDatePickerActive] = useState(false);
  const [datePickerFormattedDate, setDatePickerFormattedDate] = useState({
    startDate: "",
    endDate: ""
  });
  const [state, setState] = useState([
    {
      startDate: startDate,
      endDate: endDate,
      key: "selection"
    }
  ]);

  const resetFilter = () => {
    setSelectedOwner("");
    setSelectedOutCome("");
    setSelectedInOut("");
    setFilterQuery({});

    props.fetchUserReportData();
  };

  const resetDateRange = () => {
    setDatePickerActive(false);
    setState([
      {
        startDate: startDate,
        endDate: endDate,
        key: "selection"
      }
    ]);
    setDatePickerFormattedDate({
      startDate: "",
      endDate: ""
    });

    props.fetchUserReportData({
      ...filterQuery
    });
  };

  const updateDatatable = (dropdownHeader, value) => {
    props.fetchUserReportData({ ...filterQuery, [dropdownHeader]: value });
    setFilterQuery({ ...filterQuery, [dropdownHeader]: value });
  };

  const selectDateRange = (selectedDateRange) => {
    clearTimeout(searchTimeoutToken);
    const startDate = formatDateWithoutZone(selectedDateRange.startDate,"MM/DD/YYYY");
    const endDate =  formatDateWithoutZone(selectedDateRange.endDate,"MM/DD/YYYY");
    setDatePickerFormattedDate({
      startDate: startDate,
      endDate: endDate
    });
    setState([selectedDateRange]);
    setDatePickerActive(true);

    const timoutToken = setTimeout(()=>{
      props.fetchUserReportData({
        ...filterQuery,
        startDate: startDate + " 00:00:01",
        endDate: endDate + " 23:59:59",
        userTimezoneRaw: userTimezoneRaw
      });
      setFilterQuery({
        ...filterQuery,
        startDate: startDate + " 00:00:01",
        endDate: endDate + " 23:59:59",
        userTimezoneRaw: userTimezoneRaw
      });
      setShowDatePicker(false);
    },2000);

    setSearchTimeoutToken(timoutToken);
  };

  return (
    <>
      <div className="filter__menu__area">
        {
          props.subUserList && Array.isArray(props.subUserList) && props.subUserList[0] ? (
              <div className="select-wrapper call_logs_select_wrapper_v2">
                <DropdownHelper
                    datalist={props.subUserList}
                    noneFieldRequired={true}
                    noneFieldTitle="Filter Users"
                    className=""
                    mapping={{ title: "full_name" }}
                    menuItemClassName=""
                    updateSelectedData={(params)=>{ setSelectedOwner(params) }}
                    selectedValue={selectedOwner}
                    updateDatatable={updateDatatable}
                    dropdownHeader={"userId"}
                    parentDivClassName="ppc__select_owner"
                />
              </div>
          ): <div/>
        }
        <div className="select-wrapper call_logs_select_wrapper_v2">
          <DropdownHelper
              datalist={props.callOutcomeList}
              noneFieldRequired={true}
              noneFieldTitle="Filter Outcome"
              className=""
              mapping={{ title: "outcome" }}
              menuItemClassName=""
              updateSelectedData={(params)=>{ setSelectedOutCome(params) }}
              selectedValue={selectedOutCome}
              updateDatatable={updateDatatable}
              dropdownHeader={"callOutcomeId"}
              parentDivClassName="ppc__select_owner"
          />
        </div>
        
        <div className="select-wrapper call_logs_select_wrapper_v2">
          <DropdownHelper
              datalist={inOut}
              noneFieldRequired={true}
              noneFieldTitle="Filter Call type"
              className=""
              mapping={{ title: "value" }}
              menuItemClassName=""
              updateSelectedData={(params)=>{ setSelectedInOut(params) }}
              selectedValue={selectedInOut}
              updateDatatable={updateDatatable}
              dropdownHeader={"inOut"}
              parentDivClassName="ppc__select_owner"
          />
        </div>
        <div className="select-wrapper call_logs_select_wrapper_v2">
          <div className="custom-input">
            <div className="select-wrapper filter__action_select" >
              <FormControl onClick={() => setShowDatePicker(true)}>
                <input className="select-dropdown dropdown-trigger" type="text" readOnly={true} value={datePickerActive ? (datePickerFormattedDate.startDate === formattedStartDate &&  datePickerFormattedDate.endDate === formattedEndDate ? "Today" : datePickerFormattedDate.startDate + "-" + datePickerFormattedDate.endDate) : "Filter By Date"} />
              </FormControl>
              {
                  datePickerActive && (
                      <span
                          className="filter__action_button date_picker_reset"
                          onClick={resetDateRange}
                      >
                        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path fill="none" stroke="#000" stroke-width="2" d="m3 3 18 18M3 21 21 3"/></svg>
                      </span>
                  )
              }
            </div>
          </div>
          {
             showDatePicker &&

         
          <div className="custom-input call__log_datepicker call__log_datepicker_extra log__filter">
            <div className="select-wrapper">
            
                      <DateRangePicker
                          editableDateInputs={true}
                          moveRangeOnFirstSelection={true}
                          onChange={item => selectDateRange(item.selection)}
                          months={1}
                          minDate={addDays(new Date(), -600)}
                          maxDate={addDays(new Date(), 1)}
                          dateDisplayFormat="MM/dd/yyyy"
                          calendarFocus="backwards"
                          direction="vertical"
                          scroll={{enabled: true}}
                          ranges={state}
                      />
                  
             
               
                <span
                    className="date__action_button"
                    onClick={() => setShowDatePicker(false)}
                >
                  {iconList.modalCloseIcon}
                </span>

            </div>
           
          </div>
           }
        </div>
        <div onClick={resetFilter} className="call_logs__filter__btn">
          <BootstrapTooltip title="Reset Filter" arrow placement="top">
            {icons.restore}
          </BootstrapTooltip>
        </div>
      </div>
    </>
  );
};

export default FilterCallLog;
