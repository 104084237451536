
export const Goal = {
    ASSIGNEE_TYPE_COMPANY:1,
    ASSIGNEE_TYPE_TEAM:2,
    ASSIGNEE_TYPE_USER:3,

    GOAL_TYPE_KEY_ACTIVITY_ADDED:1,
    GOAL_TYPE_KEY_ACTIVITY_COMPLETE:2,
    GOAL_TYPE_KEY_DEALS_STARTED:3,
    GOAL_TYPE_KEY_DEALS_PROGRESSED:4,
    GOAL_TYPE_KEY_DEALS_WON:5,
    GOAL_TYPE_KEY_DEALS_LOST:6,
    GOAL_TYPE_KEY_EMAIL_SENT:7,
    GOAL_TYPE_KEY_EMAIL_RECEIVED:8,
    GOAL_TYPE_KEY_DEALS_VELOCITY:9,
    GOAL_TYPE_KEY_DEALS_CONVERSION:10,
    GOAL_TYPE_KEY_REVENUE_FORECAST:11,

    GOAL_INTERVAL_WEEKLY:1,
    GOAL_INTERVAL_MONTHLY:2,
    GOAL_INTERVAL_QUARTERLY:3,
    GOAL_INTERVAL_YEARLY:4,

    GOAL_DURATION_TYPE_THIS_MONTH:1,
    GOAL_DURATION_TYPE_NEXT_MONTH:2,
    GOAL_DURATION_TYPE_THIS_QUARTER:3,
    GOAL_DURATION_TYPE_NEXT_QUARTER:4,
    GOAL_DURATION_TYPE_THIS_YEAR:5,
    GOAL_DURATION_TYPE_NEXT_YEAR:6,
    GOAL_DURATION_TYPE_DATE_RANGE:7,
}
