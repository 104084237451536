import React from 'react';
import {BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend,ResponsiveContainer} from 'recharts';
import { getGoalStatistics } from '../../../../api/goalApi';
import If from 'if-else-react';
import { AccountCircle, FormatListBulleted, Group, MailOutline, Phone } from '@material-ui/icons';
import GoalSkeleton from '../../../common/Skeletons/GoalSkeleton';


export default class UserReportTab extends React.Component{
    constructor(props) {
        super(props);
        this.state={
            data:[],
            userData:[],
            responseLength:0,
            duration:'today',
            teamUser:0,
            loading:true,
            activity_type:0,
            goalValue:0,
            goalTargetActivity: null,
            goal: null,
            perDayGoalValue: null,
        }
    }
    loadChart=()=>{
        getGoalStatistics({
            type:1,
            chart:'byUser',
            duration:this.props.selectedDateType,
            pipeline:this.props.selectedPipeline,
            teamUser:this.props.selectedTeamUser,
            from:this.props.selectedDateFrom,
            to:this.props.selectedDateTo,
            activity:this.state.activity_type,
        }).then(
                response  => {
                    let res = response.data;
                    let chartData=[];
                    if(res.length>0){
                        res.map(function(data,index){
                            chartData.push({name: data.user.full_name, call: data.call_count, meeting: data.meeting_count, task: data.task_count,email:data.email_count});
                        });
                    }
                    this.setState({data:chartData,userData:res,loading:false});
                    this.setGoalFromGoalSetting();
                }
            );
    }

    setGoalFromGoalSetting = () => {
        let perDayGoalValue = 0;
        let goalValue = 0;

        if (this.props.goal.goal_duration_type == 1) {
            perDayGoalValue = parseFloat(this.props.goal.expected_outcome_value) / 30;
        } else if (this.props.goal.goal_duration_type == 2) {
            perDayGoalValue = parseFloat(this.props.goal.expected_outcome_value) / 30;
        } else if (this.props.goal.goal_duration_type == 5) {
            perDayGoalValue = parseFloat(this.props.goal.expected_outcome_value) / 365;
        }  else if (this.props.goal.goal_duration_type == 6) {
            perDayGoalValue = parseFloat(this.props.goal.expected_outcome_value) / 365;
        }

        if (this.state.duration == 'today') {
            goalValue = perDayGoalValue;
        } else if (this.state.duration == 'yesterday') {
            goalValue = perDayGoalValue;
        } else if (this.state.duration == 'thisweek') {
            goalValue = perDayGoalValue * 7;
        } else if (this.state.duration == 'lastweek') {
            goalValue = perDayGoalValue * 7;
        } else if (this.state.duration == 'thismonth') {
            goalValue = perDayGoalValue * 30;
        } else if (this.state.duration == 'lastmonth') {
            goalValue = perDayGoalValue * 30;
        } else if (this.state.duration == 'thisyear') {
            goalValue = perDayGoalValue * 365;
        } else if (this.state.duration == 'lastyear') {
            goalValue = perDayGoalValue * 365;
        }

        if (this.props.goal.goal_type_key == 1) {
            this.setState({
                goal: this.props.goal,
                perDayGoalValue: perDayGoalValue,
                goalValue: goalValue,
                goalTargetActivity: this.props.goal.goal_type_value
            });
        } else {
            this.setState({
                goal: null,
                perDayGoalValue: null,
                goalValue: 0,
                goalTargetActivity: null
            });
        }
    }

    populateUserTable = () => {
        return this.state.data.map((value, index) => (
            <tr key={index}>
                <td>{value.name}</td>
                <td>{value.call+value.meeting+value.task+value.email}</td>
                <td>{value.call} {this.state.goalTargetActivity == 1 ? <strong>[GOAL:{this.state.goalValue}]</strong> : ''}</td>
                <td>{value.meeting} {this.state.goalTargetActivity == 2 ? <strong>[GOAL:{this.state.goalValue}]</strong> : ''}</td>
                <td>{value.task} {this.state.goalTargetActivity == 3 ? <strong>[GOAL:{this.state.goalValue}]</strong> : ''}</td>
                <td>{value.email} {this.state.goalTargetActivity == 5 ? <strong>[GOAL:{this.state.goalValue}]</strong> : ''}</td>
            </tr>
        ));
    }

    loadingPanel(){
        if (this.state.loading){
            return (
                <GoalSkeleton/>
            );
        }else{
            return (
                <div className="goal_no_data_text_wrapper">
                    <div>
                        <h2 className="no_data__loading_title text-center">No activities have been added during the chosen period</h2>
                        <br/>
                        <p className="no_data__loading_subtitle">Try selecting another pipeline or a longer period</p>
                    </div>
                </div>
            );
        }
    }

    componentDidMount(){
        this.loadChart();
    }

    componentDidUpdate(){
        if(this.props.activeTab=='by-user' && this.state.duration!=this.props.selectedDateType){
            this.setState({duration:this.props.selectedDateType},() => {
                this.loadChart();
            })
        }else if(this.props.activeTab=='by-user' && this.state.teamUser!=this.props.selectedTeamUser){
            this.setState({teamUser:this.props.selectedTeamUser},() => {
                this.loadChart();
            })
        }
    }

    activityTypeChange=(event)=>{
        this.setState({activity_type:event.target.value},()=>{
            this.loadChart();
        });
    }

    customTooltipChart = ({ payload, label, active }) => {
        if (active) {
            return (
                <div className="custom-tooltip" style={{backgroundColor:"#ffffff",padding:10,borderRadius:5,fontSize:13,lineHeight:1, width: '250px'}}>
                    <p className="label d-flex align-items-center py-1" style={{fontSize:14}}><AccountCircle className="mr-2"/> {`${label}`}</p>
                    <p className="intro d-flex align-items-center justify-content-between py-1"><span className="d-flex align-items-center"><Phone className="mr-2"/> Call</span><span>{`${payload[0].value}`} [Goal: {this.state.goalTargetActivity == 1 ? this.state.goalValue : '--'}]</span></p>
                    <p className="intro d-flex align-items-center justify-content-between py-1"><span className="d-flex align-items-center"><Group className="mr-2"/> Meeting</span><span>{`${payload[1].value}`}  [Goal: {this.state.goalTargetActivity == 2 ? this.state.goalValue : '--'}]</span></p>
                    <p className="intro d-flex align-items-center justify-content-between py-1"><span className="d-flex align-items-center"><FormatListBulleted className="mr-2"/> Task</span><span>{`${payload[2].value}`}  [Goal: {this.state.goalTargetActivity == 3 ? this.state.goalValue : '--'}]</span></p>
                    <p className="intro d-flex align-items-center justify-content-between py-1"><span className="d-flex align-items-center"><MailOutline className="mr-2"/> Email</span><span>{`${payload[3].value}`}  [Goal: {this.state.goalTargetActivity == 5 ? this.state.goalValue : '--'}]</span></p>
                </div>
            );
        }
        return null;
    }

    render() {

        return(
            <div>
            <If condition={this.props.activeTab=='by-user'}>
                <div className="tab-pane fade in active show">
                    <div className="tabs__content-action">
                        <div className="action_lists">
                            <div className="action_list">
                                <div className="action__input action__input__radio">
                                    <select className={'d-block'} name={'activityType'} onChange={e=>this.activityTypeChange(e)} defaultValue={this.state.activity_type}>
                                        <option value="0">All Activity</option>
                                        <option value="1">Call</option>
                                        <option value="2">Appointment</option>
                                        <option value="3">Task</option>
                                        <option value="5">Email</option>
                                    </select>
                                </div>
                                {/*<div className="action__input action__input__radio">
                                    <label className="widget-radio active"><input type="radio" name="viewType" value="count"
                                                                                  checked="&quot;checked&quot;/"/>Count</label>
                                    <label className="widget-radio"><input type="radio" name="viewType" value="value"/>Value</label>
                                </div>
                                <div className="action__input action__input__radio">
                                    <label className="widget-radio"><input type="radio" name="userType" value="creator"/>Deal
                                        creator</label>
                                    <label className="widget-radio active"><input type="radio" name="userType" value="owner"
                                                                                  checked="&quot;checked&quot;/"/>Current
                                        owner</label>
                                </div>*/}
                                {/*<div className="action__input action__input__checkbox">*/}
                                {/*    <label className="checkbox">*/}
                                {/*        <input type="checkbox" name="" value="1"/>*/}
                                {/*        <span className="checkbox__label">Divide by current status</span>*/}
                                {/*    </label>*/}
                                {/*</div>*/}
                            </div>
                            <div className="action_list">
                                <div style={{width:"100%",height:400}}>
                                    {(this.state.data.length===0||this.state.loading)?this.loadingPanel():
                                    <ResponsiveContainer>
                                        <BarChart
                                            data={this.state.data}
                                            layout={'vertical'}
                                            margin={{
                                                top: 20, right: 30, left: 20, bottom: 5,
                                            }}>
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis type="number" tick={{fontSize: 14}}/>
                                            <YAxis dataKey="name" type="category" tick={{fontSize: 14}}/>
                                            <Tooltip content={this.customTooltipChart}/>
                                            <Legend wrapperStyle={{fontSize:14}}/>
                                            <Bar name="Call" dataKey="call" stackId="a" fill={this.props.barColors.call} />
                                            <Bar name="Meeting" dataKey="meeting" stackId="a" fill={this.props.barColors.meeting} />
                                            <Bar name="Task" dataKey="task" stackId="a" fill={this.props.barColors.task} />
                                            <Bar name="Email" dataKey="email" stackId="a" fill={this.props.barColors.email} />
                                        </BarChart>
                                    </ResponsiveContainer>}
                                </div>
                            </div>
                            <If condition={this.state.data.length !== 0}>
                                <div className="action_list">
                                    <table className={'mt-5 table table-bordered text-center'}>
                                        <thead><th>User</th><th>All Type</th><th>Call</th><th>Meeting</th><th>Task</th><th>Email</th></thead>
                                        <tbody>{this.populateUserTable()}</tbody>
                                    </table>
                                </div>
                            </If>
                        </div>
                    </div>
                </div>
            </If>
            </div>
        );
    }
}
