import { Select } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const SelectComponent = (props) => {
    return <Select
        className="goal_select goal_select__modal"
        labelId="demo-simple-select-outlined-label"
        value={props.value}
        onChange={props.onChange}
        IconComponent = {ArrowDropDownIcon}
        displayEmpty
        inputProps={{ 'aria-label': 'Without label' }}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        MenuProps={{
            anchorOrigin: {
            vertical: "bottom",
            horizontal: "left"
            },
            transformOrigin: {
            vertical: "top",
            horizontal: "left"
            },
            getContentAnchorEl: null
        }}
        >
        {props.children}
    </Select>
}

export default SelectComponent;