import React from 'react';
import UserReportTab from '../activityCompletedChart/userReportTab';
import UserReportByTime from '../activityCompletedChart/userReportByTime';
import TypeReportTab from '../activityCompletedChart/TypeReportTab';
import TypeReportByTime from '../activityCompletedChart/TypeReportByTime';
import If from 'if-else-react';
import { Tab, Tabs } from '@material-ui/core';

class ActivitiesCompleted extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab:'by-user'
        };
    };

    showTab=(e,value)=>{
        this.setState({activeTab:value});
    }

    render() {
        const {activeTab}=this.state;
        const {selectedDateType,selectedPipeline,selectedTeamUser,selectedDateFrom,selectedDateTo,barColors,goal}=this.props;
        return (
            <React.Fragment>
                <div class="goalreport__right effort__report">
                    <div className="effort__top__wrapper">
                        <div class="goalreport__right__top effort__right__top">
                            <h2 className="goalreport__right__top_title">How many activities were completed?</h2>
                            <div className="">
                                 <Tabs variant="scrollable" className="effor__tab__topper" value={activeTab} onChange={this.showTab}>
                                    <Tab className="effort__tab__button" value={'by-user'} label="by Users"/>
                                    <Tab className="effort__tab__button" value={'by-user-over-time'} label="by Users over time"  />
                                    <Tab className="effort__tab__button" value={'by-type'} label="by Types" />
                                    <Tab className="effort__tab__button" value={'by-type-over-time'} label="by Types over time" />
                                </Tabs>
                            </div>
                        </div>
                        <div class="goalreport__main__content effort__main__content">
                            <If condition={activeTab=='by-user'}>
                                <UserReportTab activeTab={activeTab}
                                            selectedDateType={selectedDateType}
                                            selectedPipeline={selectedPipeline}
                                            selectedTeamUser={selectedTeamUser}
                                            selectedDateFrom={selectedDateFrom}
                                            selectedDateTo={selectedDateTo}
                                            barColors={barColors}
                                            goal={goal}
                                />
                            </If>
                            <If condition={activeTab=='by-user-over-time'}>
                                <UserReportByTime activeTab={activeTab}
                                                    selectedDateType={selectedDateType}
                                                    selectedPipeline={selectedPipeline}
                                                    selectedTeamUser={selectedTeamUser}
                                                    selectedDateFrom={selectedDateFrom}
                                                    selectedDateTo={selectedDateTo}
                                                    barColors={barColors}
                                />
                            </If>
                            <If condition={activeTab == 'by-type'}>
                                <TypeReportTab activeTab={activeTab}
                                            selectedDateType={selectedDateType}
                                            selectedPipeline={selectedPipeline}
                                            selectedTeamUser={selectedTeamUser}
                                            selectedDateFrom={selectedDateFrom}
                                            selectedDateTo={selectedDateTo}
                                            barColors={barColors}
                                />
                            </If>
                            <If condition={activeTab == 'by-type-over-time'}>
                                <TypeReportByTime activeTab={activeTab}
                                                selectedDateType={selectedDateType}
                                                selectedPipeline={selectedPipeline}
                                                selectedTeamUser={selectedTeamUser}
                                                selectedDateFrom={selectedDateFrom}
                                                selectedDateTo={selectedDateTo}
                                                barColors={barColors}
                                />
                            </If>

                        </div>
                    </div>
                    
                </div>
            </React.Fragment>
        );
    }
}

export default ActivitiesCompleted
