import React from 'react';
import {LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip,ResponsiveContainer} from 'recharts';
import { getGoalStatistics } from '../../../../api/goalApi';
import If from 'if-else-react';
import GoalSkeleton from '../../../common/Skeletons/GoalSkeleton';

const days=[{xp:'Sat',name:'',count:0},{xp:'Sun',name:'',count:0},{xp:'Mon',name:'',count:0},{xp:'Tue',name:'',count:0},{xp:'Wed',name:'',count:0},{xp:'Thu',name:'',count:0},{xp:'Fri',name:'',count:0}];

export default class UserReportByTime extends React.Component{
    constructor(props) {
        super(props);
        this.state={
            data:[],
            userData:[],
            responseLength:0,
            loading:true,
            daysOfWeek:[],
            startdaysOfWeek:'',
            duration:'today',
            teamUser:0,
            pipeline:this.props.selectedPipeline,
            viewType:'count',
            header:[],
            body:[],
            body_v:[],
            body_c:[],
        }
    }
    loadChart=()=>{
        getGoalStatistics({
            type:3,
            chart:'byUserOverTime',
            duration:this.props.selectedDateType,
            pipeline:this.props.selectedPipeline,
            teamUser:this.props.selectedTeamUser,
            from:this.props.selectedDateFrom,
            to:this.props.selectedDateTo,
        }).then(
                response  => {
                    let res = response.data.res;
                    // table content
                    let table = response.data.table;
                    let c_body=[];
                    let v_body=[];
                    if(table.length>0){
                        table.forEach((tdata)=>{
                            c_body.push(tdata.tbody_c)
                            v_body.push(tdata.tbody_v)
                        });
                        this.setState({header:table[0]?table[0].thead:[],body_c:c_body,body_v:v_body});
                    }


                    if(typeof res !== 'undefined' && res.length>0){
                        let chartData=[];
                        let dateval={};

                        res.forEach((data,index)=>{
                           
                            if(data.length>0){
                                data.forEach((d,i)=>{
                                    if(this.props.selectedDateType==='thisyear' || this.props.selectedDateType==='lastyear'){
                                        if(i===0 && index === 0){
                                            chartData=[{xp:'Jan',name:[],count:0},{xp:'Feb',name:[],count:0},{xp:'Mar',name:[],count:0},{xp:'Apr',name:[],count:0},{xp:'May',name:[],count:0},{xp:'Jun',name:[],count:0},{xp:'Jul',name:[],count:0},{xp:'Aug',name:[],count:0},{xp:'Sep',name:[],count:0},{xp:'Oct',name:[],count:0},{xp:'Nov',name:[],count:0},{xp:'Dec',name:[],count:0}];
                                        }
                                        chartData[d.xpoint-1].name.push({name:d.full_name,count:d.total_count});
                                        chartData[d.xpoint-1].count = chartData[d.xpoint-1].count+d.total_count
                                    }
                                    else if(this.props.selectedDateType==='thisweek' || this.props.selectedDateType==='lastweek'){
                                        if(dateval[d.xpoint]){
                                            dateval[d.xpoint]={
                                                name:dateval[d.xpoint].name.push({name:d.full_name,count:d.total_count}),
                                                count:dateval[d.xpoint].count+d.total_count
                                            }
                                        }else{
                                            dateval[d.xpoint]={name:[{name:d.full_name,count:d.total_count}],count:d.total_count}
                                        }
                                    }
                                    else if(this.props.selectedDateType==='thismonth' || this.props.selectedDateType==='lastmonth'){
                                        if(dateval[d.xpoint]){
                                            dateval[d.xpoint]={
                                                name:dateval[d.xpoint].name.push({name:d.full_name,count:d.total_count}),
                                                count:dateval[d.xpoint].count+d.total_count
                                            }
                                        }else{
                                            dateval[d.xpoint]={name:[{name:d.full_name,count:d.total_count}],count:d.total_count}
                                        }

                                    }
                                    else{
                                        chartData.push({xp:d.xpoint,name:[{name:d.full_name,count:d.total_count}],count:d.total_count});
                                    }
                                });
                            }

                            this.setState({loading:false})
                        });

                        if(res[0].length>0) {
                            if (this.props.selectedDateType === 'thisweek' || this.props.selectedDateType === 'lastweek') {
                                chartData = [];
                                let startOfWeek = response.data.startOdWeek;
                                chartData = days;
                                let split = startOfWeek.split('-');
                                let day = split[2], mon = split[1], year = split[0];

                                for (let i = 0; i < 7; i++) {
                                    let dF = (((parseInt(day) + i) <= 9 ? '0' : '') + (parseInt(day) + i));
                                    chartData[i].xp = mon + '/' + dF + '/' + year;
                                    if (dateval[dF]) {
                                        chartData[i].name = dateval[dF].name
                                        chartData[i].count = dateval[dF].count
                                    }
                                }
                            }
                            else if (this.props.selectedDateType === 'thismonth' || this.props.selectedDateType === 'lastmonth') {
                                chartData = [];
                                let swom = response.data.startWeekOfMonth, ewom = response.data.endWeekOfMonth;
                                for (let i = swom; i <= ewom; i++) {
                                    chartData.push({
                                        xp: ('W' + i),
                                        name: dateval[i] ? dateval[i].name : '',
                                        count: dateval[i] ? dateval[i].count : 0,
                                    });
                                }

                            }

                        }
                        this.setState({data: chartData, userData: res, loading: false});
                    }
                    this.setState({loading:false})
                }
            );
    }

    componentDidMount(){
        this.loadChart();
    }
    
    loadingPanel(){
        if (this.state.loading){
            return (
                <GoalSkeleton/>);
        }else{
            return (
                <div className="goal_no_data_text_wrapper">
                    <div>
                        <h2 className="no_data__loading_title text-center">No activities have been added during the chosen period</h2>
                        <br/>
                        <p className="no_data__loading_subtitle">Try selecting another pipeline or a longer period</p>
                    </div>
                </div>
            );
        }
    }

    componentDidUpdate(){
        if(this.props.activeTab==='by-user-over-time' && this.state.duration!==this.props.selectedDateType){
            this.setState({duration:this.props.selectedDateType},() => {
                this.loadChart();
            })
        }else if(this.props.activeTab==='by-user-over-time' && this.state.teamUser!==this.props.selectedTeamUser){
            this.setState({teamUser:this.props.selectedTeamUser},() => {
                this.loadChart();
            })
        }else if(this.props.activeTab==='by-user-over-time' && this.state.pipeline!==this.props.selectedPipeline){
            this.setState({pipeline:this.props.selectedPipeline},() => {
                this.loadChart();
            })
        }
    }

    customTooltipChart = ({ payload, label, active }) => {
        if (active) {
            return (
                <div className="custom-tooltip" style={{backgroundColor:"#000000",padding:10,borderRadius:5,fontSize:13,lineHeight:1.2,opacity:0.85}}>
                    <p className="label text-light"><i className="fa fa-calendar"></i> {`${label}`}</p>
                    <p className="intro my-0 text-light"><i className="fa fa-send" style={{width:16}}></i>
                        Conversations Sent&nbsp;<span style={{float:"right"}}>{`${payload[0].value}`}</span></p>
                    <If condition={payload[0] && payload[0].payload && Array.isArray(payload[0].payload.name)}>
                        {
                            payload[0].payload.name.map((d,i)=>{
                                return <p className="intro my-0 text-light"><i className="fa fa-user" style={{width: 20}}/>
                                    {d.name}&nbsp;<span style={{float:"right"}}>{`${d.count}`}</span></p>
                            })
                        }
                    </If>
                </div>
            );
        }
        return null;
    }

    populateUserTable = () => {
        let body=this.state.viewType==='count'?this.state.body_c:this.state.body_v;

        return body.map((data,index)=>{
            return <tr key={index}>
                {
                    data.map((d,i)=>{
                        return <td key={i}>{d}</td>
                    })
                }
            </tr>;
        });
    }


    populateUserTableHeader = () => {
        return this.state.header.map((value, index) => {
            return <th key={index}>
                {value}
            </th>;
        });
    }

    render() {
        return(
            <div>
                <If condition={this.props.activeTab==='by-user-over-time'}>
                    <div className="tab-pane fade in active show">
                        <div className="tabs__content-action">
                            <div className="action_lists">
                                <div className="action_list">
                                    <div style={{width:"100%",height:400}}>
                                        {(this.state.data.length===0||this.state.loading)?this.loadingPanel():
                                            <ResponsiveContainer>
                                                <LineChart
                                                    data={this.state.data}
                                                    margin={{
                                                        top: 5, right: 30, left: 20, bottom: 5,
                                                    }}
                                                >
                                                    <CartesianGrid strokeDasharray="3 10" />
                                                    <XAxis dataKey="xp" tick={{fontSize: 14}}/>
                                                    <YAxis tick={{fontSize: 14}}/>
                                                    <Tooltip content={this.customTooltipChart}/>
                                                    {/*<Legend wrapperStyle={{fontSize:16}}/>*/}
                                                    <Line  dataKey="count" stroke="#8884d8" strokeWidth={3} />
                                                </LineChart>
                                            </ResponsiveContainer>}
                                    </div>
                                </div>
                                <If condition={this.state.data.length !== 0 && this.state.totalCount!==0}>
                                    <div className="action_list">
                                        <table className={'mt-5 table table-bordered text-center'} id={'byTime'}>
                                            <thead>{this.populateUserTableHeader()}</thead>
                                            <tbody>{this.populateUserTable()}</tbody>
                                        </table>
                                    </div>
                                </If>
                            </div>
                        </div>
                    </div>
                </If>
            </div>
        );
    }
}
