import React from 'react';
import {BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend,ResponsiveContainer} from 'recharts';
import { getGoalStatistics } from '../../../../api/goalApi';
import If from 'if-else-react';
import GoalSkeleton from '../../../common/Skeletons/GoalSkeleton';


export default class UserReportTab extends React.Component{
    constructor(props) {
        super(props);
        this.state={
            data:[],
            userData:[],
            responseLength:0,
            duration:this.props.selectedDateType,
            teamUser:0,
            loading:true,
        }
    }
    loadChart=()=>{
        getGoalStatistics({
            type:4,
            chart:'byUser',
            duration:this.props.selectedDateType,
            pipeline:this.props.selectedPipeline,
            teamUser:this.props.selectedTeamUser,
            from:this.props.selectedDateFrom,
            to:this.props.selectedDateTo
        }).then(
                response  => {
                    let res = response.data;
                    let chartData=[];
                    if(res.length>0){
                        res.forEach(function(data){
                            chartData.push({
                                name: data.user.full_name,
                                sms: data.sms_count,
                                email: data.email_count,
                                mms: data.mms_count,
                                call: data.call_count
                            });
                        });
                    }
                    this.setState({data:chartData,userData:res,loading:false});
                }
            );
    }
    populateUserTable = () => {
        return this.state.data.map((value, index) => {
            return <tr key={index}>
                <td>{value.name}</td>
                <td>{value.sms+value.email+value.mms+value.call}</td>
                <td>{value.sms}</td>
                <td>{value.email}</td>
                <td>{value.mms}</td>
                <td>{value.call}</td>
            </tr>;
        });
    }

    loadingPanel(){
        if (this.state.loading){
            return (
                <GoalSkeleton/>);
        }else{
            return (
                <div className="goal_no_data_text_wrapper">
                    <div>
                        <h2 className="no_data__loading_title text-center">No activities have been added during the chosen period</h2>
                        <br/>
                        <p className="no_data__loading_subtitle">Try selecting another pipeline or a longer period</p>
                    </div>
                </div>
            );
        }
    }

    componentDidMount(){
        this.loadChart();
    }

    componentDidUpdate(){
        if(this.props.activeTab==='by-user' && this.state.duration!==this.props.selectedDateType){
            this.setState({duration:this.props.selectedDateType},() => {
                this.loadChart();
            })
        }else if(this.props.activeTab==='by-user' && this.state.teamUser!==this.props.selectedTeamUser){
            this.setState({teamUser:this.props.selectedTeamUser},() => {
                this.loadChart();
            })
        }
    }

    customTooltipChart = ({ payload, label, active }) => {
        if (active) {
            return (
                <div className="custom-tooltip" style={{backgroundColor:"#ffffff",padding:10,borderRadius:5,fontSize:13,lineHeight:1}}>
                    <p className="label" style={{fontSize:14}}><i className="fa fa-user"></i> {`${label}`}</p>
                    <p className="intro my-0"><i className="fa fa-envelope-o" style={{width:18}}></i>
                        SMS&nbsp;<span style={{float:"right"}}>{`${payload[0].value}`}</span></p>
                    <p className="intro my-0"><i className="fa fa-envelope" style={{width:18,paddingTop:1,paddingBottom:1}}></i>
                        EMAIL&nbsp;<span style={{float:"right"}}>{`${payload[1].value}`}</span></p>
                    <p className="intro my-0"><i className="fa fa-image" style={{width:18,paddingTop:1,paddingBottom:1}}></i>
                        MMS&nbsp;<span style={{float:"right"}}>{`${payload[2].value}`}</span></p>
                    <p className="intro my-0"><i className="fa fa-phone" style={{width:18,paddingTop:1,paddingBottom:1}}></i>
                        CALL&nbsp;<span style={{float:"right"}}>{`${payload[3].value}`}</span></p>
                </div>
            );
        }
        return null;
    }

    render() {
        return(
            <div>
            <If condition={this.props.activeTab==='by-user'}>
                <div className="tab-pane fade in active show">
                    <div className="tabs__content-action">
                        <div className="action_lists">
                            <div className="action_list">
                                {/*<div className="action__input action__input__radio">
                                    <label className="widget-radio active"><input type="radio" name="viewType" value="count"
                                                                                  checked="&quot;checked&quot;/"/>Count</label>
                                    <label className="widget-radio"><input type="radio" name="viewType" value="value"/>Value</label>
                                </div>
                                <div className="action__input action__input__radio">
                                    <label className="widget-radio"><input type="radio" name="userType" value="creator"/>Deal
                                        creator</label>
                                    <label className="widget-radio active"><input type="radio" name="userType" value="owner"
                                                                                  checked="&quot;checked&quot;/"/>Current
                                        owner</label>
                                </div>*/}
                                {/*<div className="action__input action__input__checkbox">*/}
                                {/*    <label className="checkbox">*/}
                                {/*        <input type="checkbox" name="" value="1"/>*/}
                                {/*        <span className="checkbox__label">Divide by current status</span>*/}
                                {/*    </label>*/}
                                {/*</div>*/}
                            </div>
                            <div className="action_list">
                                <div style={{width:"100%",height:400}}>
                                    {(this.state.data.length===0||this.state.loading)?this.loadingPanel():
                                    <ResponsiveContainer>
                                        <BarChart
                                            data={this.state.data}
                                            layout={'vertical'}
                                            margin={{
                                                top: 20, right: 30, left: 20, bottom: 5,
                                            }}
                                        >
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis type="number" tick={{fontSize: 14}}/>
                                            <YAxis dataKey="name" type="category" tick={{fontSize: 14}}/>
                                            <Tooltip content={this.customTooltipChart}/>
                                            <Legend wrapperStyle={{fontSize:14}}/>
                                            <Bar name="SMS" dataKey="sms" stackId="a" fill={this.props.barColors.sms} />
                                            <Bar name="Email" dataKey="email" stackId="a" fill={this.props.barColors.email} />
                                            <Bar name="MMS" dataKey="mms" stackId="a" fill={this.props.barColors.mms} />
                                            <Bar name="Call" dataKey="call" stackId="a" fill={this.props.barColors.call} />
                                        </BarChart>
                                    </ResponsiveContainer>}
                                </div>
                            </div>
                            <If condition={this.state.data.length !== 0}>
                                <div className="action_list">
                                    <table className={'mt-5 table table-bordered text-center'}>
                                        <thead><th>User</th><th>All Type</th><th>Sms</th><th>Email</th><th>Mms</th><th>Call</th></thead>
                                        <tbody>{this.populateUserTable()}</tbody>
                                    </table>
                                </div>
                            </If>
                        </div>
                    </div>
                </div>
            </If>
            </div>
        );
    }
}
