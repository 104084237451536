import React, { useState, useRef } from "react";
import CustomSelect from "../../common/customDropdown/CustomSelect";
import { USER_ORDER_LISTS } from "./userOderListConstants";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import moment from "moment";
import { USER_ORDER_REPORT } from "../../../constants/CoreConstants";
import { icons } from "../icon";
import BootstrapTooltip from "../../globals/BootstrapTooltip";

const FilterOrderList = (props) => {
  const [menus, setMenus] = useState({ type: "", status: "" });
  const [date, setdate] = useState({ startDate: "", endDate: "" });
  const [openDateField, setOpenDateField] = useState({isStartDateOpen: false, isEndDateOpen: false});

  const startDateRef = useRef(null);
  const endDateRef = useRef(null);

  const handleChangeMenu = (e, menuType) => {
   
    if (menuType === "type") {
      setMenus({ ...menus, type: USER_ORDER_REPORT.types[e.target.value] })
    } else if (menuType === "status") {
      setMenus({ ...menus, status: USER_ORDER_REPORT.allStatus[e.target.value] })
    }
  };

  const handleDateChange = (item, value) => {
    if (item === "startDate") {
      if(value) {
        setdate({ ...date, startDate: moment(value).format('YYYY-MM-DD')});
      } else {
        setdate({ ...date, startDate: ""});
      }
    } else if (item === "endDate") {
      if(value) {
        setdate({ ...date, endDate: moment(value).format('YYYY-MM-DD') });
      } else {
        setdate({ ...date, endDate: "" });
      }
    }
  };

  return (
    <>
      <div className="filter__menu__area">
        <div className="each__dropdown each__dropdown--type">
          <CustomSelect
            type={USER_ORDER_LISTS.types}
            dropdownLabelId={"each__dropdown__label"}
            dropdownTypeId={"each__dropdown__type"}
            value={Object.keys(USER_ORDER_REPORT.types).find(key => USER_ORDER_REPORT.types[key] === menus.type)}
            handleChangeMenu={(e) => handleChangeMenu(e, "type")}
          />
        </div>

        <div className="each__dropdown each__dropdown--status">
          <CustomSelect
            type={USER_ORDER_LISTS.status}
            dropdownLabelId={"each__dropdown__label"}
            dropdownTypeId={"each__dropdown__status"}
            value={Object.keys(USER_ORDER_REPORT.allStatus).find(key => USER_ORDER_REPORT.allStatus[key] === menus.status)}
            handleChangeMenu={(e) => handleChangeMenu(e, "status")}
          />
        </div>
      </div>

      <div className="filter__date__area">
        
        <div className={`each__date each__date--startdate`}>

          <div className={`${date.startDate ? 'd-none' : ""}`}>
            <button onClick={e => startDateRef.current.click()}>Start Date</button>
          </div>

          <div className={`${!date.startDate ? 'd-none' : ""}`}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                    inputRef={startDateRef}
                    clearable
                    onClick={() => setOpenDateField({isStartDateOpen: true, isEndDateOpen: false})}
                    onClose={() => setOpenDateField({isStartDateOpen: false, isEndDateOpen: false})}
                    open={openDateField.isStartDateOpen}
                    inputProps={{readOnly : true}}
                    value={date.startDate ? date.startDate : null}
                    onChange={(value) => handleDateChange("startDate", value)}
                    margin="normal"
                    format="MM/dd/yyyy"
                    KeyboardButtonProps={{ disabled: true, style: { display: 'none' } }}
                />
            </MuiPickersUtilsProvider>
          </div>
          
        </div>

        <div className={`each__date each__date--endDate`}>

          <div className={`${date.endDate ? 'd-none' : ""}`}>
            <button onClick={e => endDateRef.current.click()}>End Date</button>
          </div>

          <div className={`${!date.endDate ? 'd-none' : ""}`}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                    inputRef={endDateRef}
                    clearable
                    onClick={() => setOpenDateField({isStartDateOpen: false, isEndDateOpen: true})}
                    onClose={() => setOpenDateField({isStartDateOpen: false, isEndDateOpen: false})}
                    open={openDateField.isEndDateOpen}
                    inputProps={{readOnly : true}}
                    value={date.endDate ? date.endDate : null}
                    onChange={(value) => handleDateChange("endDate", value)}
                    margin="normal"
                    format="MM/dd/yyyy"
                    KeyboardButtonProps={{ disabled: true, style: { display: 'none' } }}
                />
            </MuiPickersUtilsProvider>
          </div>
          
        </div>

      </div>

      <div className="order__history__filter__btn" onClick={()=>props.fetchUserReportData(menus.type, menus.status, date.startDate, date.endDate)}>
        <BootstrapTooltip title="Filter" arrow placement="top">
          {icons.sendIcon}
        </BootstrapTooltip>
      </div>
    </>
  );
};

export default FilterOrderList;
