import React from "react";
import Utils from "../../../helpers/Utils";
import { icons } from "../icon";

const Pagination = (props) => {
  const { total_records, total_pages, current_page } = props.reportReducer;

  const handlePaginationChange = (event, page) => {
    event.preventDefault();
    if (page != null) {
      props.userReportPagination({ currentPage: page });
      props.setPaginationChange(true);
    }
  };

  const handleChangeSearchPage = (pageNo) => {
    if (!Utils.checkLetterExistInText(pageNo) || pageNo === "") {
      props.userReportPagination({ currentPage: pageNo });
      props.setPaginationChange(true);
    }
  };

  let previous_page = null,
    next_page = null;
  
  let currentPage = parseInt(current_page);
    
  if (currentPage > 1) {
    previous_page = currentPage - 1;
  }

  if (currentPage !== total_pages) {
    next_page = currentPage + 1;
  }

  if (total_records === 0) {
    return null;
  }
  return (
    <div className="pagination_bar">
      <ul className="user__report__pagination__ul">
        <li>
          <a
            href="#!"
            onClick={(event) => handlePaginationChange(event, previous_page)}
            style={
              previous_page == null
                ? {
                    cursor: "not-allowed",
                    backgroundColor: "#9daac1",
                    borderColor: "#9daac1",
                  }
                : {}
            }
          >
            {icons.previous}
          </a>
        </li>
        <li className="active">
          <div className="user-report-pagination-text">
            <input
              className="user__report-pagination-no"
              type="text"
              placeholder="Search Report Page"
              value={current_page}
              onChange={(event) => handleChangeSearchPage(event.target.value)}
            />
          </div>
        </li>
        <li>
          <a className="d-flex align-items-center"> {/* eslint-disable-line jsx-a11y/anchor-is-valid */}
            <span>{icons.backwardSlash}</span>
            {total_pages}
          </a>
        </li>
        <li>
          <a
            href="#!"
            onClick={(event) => handlePaginationChange(event, next_page)}
            style={
              next_page == null
                ? {
                    cursor: "not-allowed",
                    backgroundColor: "#9daac1",
                    borderColor: "#9daac1",
                  }
                : {}
            }
          >
            {icons.next}
          </a>
        </li>
        <li style={{ color: "#9daac1", marginLeft: 20 }}>
          <small className="paginations_text">
            Total entries : {total_records}
          </small>
        </li>
      </ul>
    </div>
  );
};

export default Pagination;
