// import * as ACTION from "../constants/triggerActionTypes";
import axis from "axis.js";
import { AllColumns,DefaultColumns } from "../constants/CoreConstants";
import { CHANGE_COLUMNS, CHANGE_DATE, CHANGE_FIRST_COLUMN, CHANGE_SECOND_COLUMN, CONTENT_LOADED, CONTENT_LOADING ,REPORT_INFO_UPDATE,REPORT_PAGINATION} from "../constants/reportActionTypes";


let previousFirstColFilter = localStorage.getItem("firstColumn");
let previousSecondColFilter = localStorage.getItem("secondColumn");
let visibleColumns = localStorage.getItem("visibleColumn");

let filterFirstParams = {
    id : "new_leads",
    title : 'New Leads'
};

let filterSecondParams = {
    id : null,
    title : null
};

if(!axis.isNull(previousSecondColFilter)){
    filterSecondParams = JSON.parse(previousSecondColFilter);
}

if(!axis.isNull(previousFirstColFilter)){
    filterFirstParams = JSON.parse(previousFirstColFilter);
}

if(!axis.isNull(visibleColumns)){
    visibleColumns = JSON.parse(visibleColumns);
}

const initialState = {
    reportCurrentUser: [],
    reportOthersUser: [],
    reportUserPrevious: [],
    loading : false,
    currentDateFilterID : 'today',
    currentDateFilterTitle : 'Today',
    currentFirstColumnFilterTitle : filterFirstParams.title,
    currentFirstColumnFilterID : filterFirstParams.id,
    currentSecColumnFilterTitle : filterSecondParams.title,
    currentSecColumnFilterID : filterSecondParams.id,
    allColumns : AllColumns,
    defaultColumns :  axis.isNull(visibleColumns) ? DefaultColumns : visibleColumns,
    total_records: 0,
    total_pages : 0,
    current_page :1,
    per_page : 20,
    need_to_reload: false,
};

const reportReducer = (state = initialState, action) => {
    switch (action.type) {
        case CONTENT_LOADED:
            return {
                ...state,
                reportCurrentUser: action.payload.currentUser,
                reportOthersUser: action.payload.othersUser,
                reportUserPrevious: action.payload.currentUserPrevious,
                subUsers: action.payload.subUsers
            }
        case CHANGE_FIRST_COLUMN : 
            localStorage.setItem("firstColumn", JSON.stringify(action.payload));
            return {...state,currentFirstColumnFilterID: action.payload.id,currentFirstColumnFilterTitle: action.payload.title};
        case CHANGE_SECOND_COLUMN : 
            localStorage.setItem("secondColumn", JSON.stringify(action.payload));
            return {...state,currentSecColumnFilterID: action.payload.id,currentSecColumnFilterTitle: action.payload.title};
        case CHANGE_COLUMNS:
            localStorage.setItem("visibleColumn", JSON.stringify(action.payload.addColumns));
            return {...state,defaultColumns: action.payload.addColumns,visibleColumns: action.payload.addColumns};
        case CHANGE_DATE :
            localStorage.setItem("date_filter", JSON.stringify(action.payload));
            return {...state,currentDateFilterID: action.payload.id,currentDateFilterTitle: action.payload.title};
        case CONTENT_LOADING:
            return {...state,loading:action.payload.loading}
        case REPORT_PAGINATION:
            return {...state,current_page:action.payload.currentPage}

        case REPORT_INFO_UPDATE:
            return {...state,total_records:action.payload.total_order , total_pages: action.payload.total_page}

        default:
            return state;
    }
}


export default reportReducer;
