import { InputBase, withStyles } from "@material-ui/core";

export const BasicInput = withStyles((theme) => ({
    root: {
      "label + &": {
        marginTop: theme.spacing(3),
      },
      "&.MuiInputBase-multiline": {
        padding: "0 !important",
      },
    },
    input: {
      position: "relative",
      color: "var(--dark_blue)",
      backgroundColor: "white !important",
      fontSize: "14px !important",
      margin: "0 !important",
      height: "2.5rem !important",
      padding: "10px 12px 10px  !important",
      borderRadius: "4px !important",
      border: "1px solid #ced4da !important",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
  
      "&:focus": {
        borderRadius: 4,
        boxShadow: "unset !important",
      },
  
      "&.MuiSelect-select": {
        height: "1rem !important",
      },
    },
  }))(InputBase);