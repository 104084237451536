import React from 'react';
import ReactEcharts from 'echarts-for-react';
import axis from "axis.js";

function isFloat(n){
    return Number(n) === n && n % 1 !== 0;
}

class ReportColumns extends React.Component{


    render() {
        let prevData = axis.isUndefined(this.props.previousData) ? 0 : this.props.previousData.val;
        let xAxisData = [];
        let yAxisData = [];
        this.props.graphData.forEach(( data, index)=>{
            xAxisData.push(data.date);
            yAxisData.push(data.val);
        });

        let isIncrease = false;
        let currentVal = this.props.data;
        let percentage = null;
        let heightIncrease = this.props.graphData.length > 1;

        if(prevData < currentVal){
            isIncrease = true;
            percentage = ((currentVal - prevData) / currentVal ) * 100;
        } else {
            percentage = ((prevData - currentVal) / prevData ) * 100;
        }

        if(isNaN(percentage)) {
            percentage = 0;
        }

        if(isFloat(percentage)){
            percentage = percentage.toFixed(2);
        }

        let option = {

            xAxis: [
                {
                    data: xAxisData,
                    show:false,
                }, {
                    data: xAxisData,
                    show:false
                }
            ],
            yAxis:[
                {
                    show:false,
                    splitLine: {show: false}
                }, {
                    splitLine: {show: false},
                    show:false
                }
            ],
            grid: [{
                left:'0%',
                right:'0%',
                bottom: '5%'
            }],
            series: [{
                color: {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [{
                        offset: 0, color: '#114ea9' // color at 0% position
                    }, {
                        offset: 1, color: '#3bd1bf' // color at 100% position
                    }],
                    global: false // false by default
                },
                data: yAxisData,
                type: 'line',
                showSymbol: false,
            }]
        };
        let classData = (isIncrease) ? "green" : (prevData === currentVal) ? "blue" : "red";

        return (
            <div onClick={()=>{this.props.changeBoxWise(this.props.item)}} className="chart__count__single">
                <h4 className="count__title">{this.props.title.replace(new RegExp("_", 'g'), ' ')}</h4>
                <h2 className="count__number">{this.props.data}</h2>
                {
                    classData==="green" &&
                    <span className={"up__arrow "+ (classData==='green'?'up__arrow__green':classData==='red'?'up__down__red':'up__arrow__blue')}><svg width="9.87" height="8.32" viewBox="0 0 9 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.77184 0.304186L0.159704 3.86866C0.0565957 3.97041 -2.61809e-07 4.10602 -2.55404e-07 4.25062C-2.48992e-07 4.39538 0.056677 4.53091 0.159704 4.63266L0.487733 4.95629C0.590679 5.05796 0.728185 5.11397 0.874798 5.11397C1.02133 5.11397 1.16347 5.05796 1.26642 4.95629L3.37827 2.87683L3.37827 9.48338C3.37827 9.78124 3.61458 10.0166 3.91651 10.0166L4.38025 10.0166C4.68218 10.0166 4.94231 9.78124 4.94231 9.48338L4.94231 2.85324L7.06596 4.95621C7.16907 5.05788 7.30283 5.11389 7.44944 5.11389C7.59589 5.11389 7.73161 5.05788 7.83464 4.95621L8.16161 4.63258C8.26472 4.53083 8.32091 4.3953 8.32091 4.25054C8.32091 4.10594 8.26399 3.97033 8.16088 3.86857L4.54882 0.304106C4.44538 0.202115 4.30731 0.146024 4.16053 0.146425C4.01327 0.146105 3.87512 0.202115 3.77184 0.304186Z" fill="white"/>
                    </svg>
                    </span>
                }
                {

                classData==="red" &&
                <span className={"up__arrow "+ (classData==='green'?'up__arrow__green':classData==='red'?'up__down__red':'up__arrow__blue')}>
                <svg width="9.87" height="8.32" viewBox="0 0 9 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.22816 9.85701L8.8403 6.29252C8.9434 6.19076 9 6.05515 9 5.91055C9 5.76579 8.94332 5.63025 8.8403 5.5285L8.51227 5.20487C8.40932 5.1032 8.27182 5.04719 8.1252 5.04719C7.97867 5.04719 7.83653 5.1032 7.73359 5.20487L5.62173 7.28435L5.62173 0.67776C5.62173 0.37989 5.38543 0.144531 5.0835 0.144531L4.61976 0.144531C4.31783 0.144531 4.0577 0.37989 4.0577 0.67776L4.0577 7.30794L1.93406 5.20495C1.83095 5.10328 1.69718 5.04727 1.55057 5.04727C1.40412 5.04727 1.2684 5.10328 1.16538 5.20495L0.838405 5.52858C0.735297 5.63033 0.679107 5.76587 0.679107 5.91063C0.679107 6.05523 0.736028 6.19084 0.839137 6.2926L4.45119 9.85709C4.55462 9.95908 4.6927 10.0152 4.83947 10.0148C4.98673 10.0151 5.12489 9.95908 5.22816 9.85701Z" fill="white"/>
                </svg>
                </span>

                }
                {
                     classData==="blue" &&
                     <span className={"up__arrow "+ (classData==='green'?'up__arrow__green':classData==='red'?'up__down__red':'up__arrow__blue')}>
                     <svg width="9.87" height="8.32" viewBox="0 0 11 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M9.84208 3.85497L6.23003 0.290413C6.12692 0.188663 5.98949 0.132812 5.84296 0.132812C5.69627 0.132812 5.55893 0.188743 5.45582 0.290413L5.12787 0.614121C5.02484 0.715711 4.96808 0.851406 4.96808 0.996087C4.96808 1.14069 5.02484 1.28096 5.12787 1.38255L7.23509 3.46659L0.540345 3.46659C0.2385 3.46659 2.01102e-07 3.69978 2.179e-07 3.99773L2.43701e-07 4.45537C2.60499e-07 4.75332 0.2385 5.01002 0.540345 5.01002L7.259 5.01002L5.12795 7.10569C5.02492 7.20745 4.96816 7.33945 4.96816 7.48413C4.96816 7.62865 5.02492 7.76258 5.12795 7.86425L5.4559 8.18692C5.55901 8.28867 5.69635 8.34411 5.84304 8.34411C5.98958 8.34411 6.127 8.28794 6.23011 8.18619L9.84217 4.62171C9.94552 4.51964 10.0024 4.38339 10.002 4.23854C10.0023 4.09322 9.94552 3.95688 9.84208 3.85497Z" fill="white"/>
                     </svg>
                     </span>
                }
                <p className="count__precent">({percentage}%)<span className="count__precent__devide"> {prevData ? <small>vs {prevData}</small> : null }</span></p>
                <p className="count__line">
                    <ReactEcharts  style={{height: heightIncrease ? '100px' : '27px', width: '100%'}}
                        notMerge={true}
                        lazyUpdate={true}
                        theme={"light"}
                        option={option} />                
                </p>
            </div>
        )
    }
}

export default ReportColumns;
