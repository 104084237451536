import { Skeleton } from '@material-ui/lab'
import React from 'react'

const listStyle = {
    root : {
        width : "100%",
        minHeight : "47px",
        backgroundColor : "rgb(250, 250, 250)",
        color : "rgba(0, 0, 0, 0.87)",
        border: "1px solid rgba(60,126,243,.1)",
        borderRadius: "10px",
        display : "flex",
        alignItems : "center",
        marginBottom : "5px"
    }
}

const SingleSkeleton = () => {
    return (
        <div style={listStyle.root}>
            <div style={{width:"10%"}} className="ml-3">
                <Skeleton className="ml-2" width={80} height={15}/>
                <Skeleton className="ml-2" width={100} height={15}/>
            </div>
            <div style={{width:"5%"}} className="ml-2">
                <Skeleton className="ml-2" width={30} height={30}/>
            </div>
            <div style={{width:"12.5%"}} className="ml-4">
                <Skeleton className="ml-2" width={80} height={15}/>
                <Skeleton className="ml-2" width={100} height={15}/>
            </div>
            <div style={{width:"12.5%"}} className="ml-4">
                <Skeleton className="ml-3" width={80} height={30}/>
            </div>
            <div style={{width:"12%"}} className="ml-4">
                <Skeleton className="ml-3" width={80} height={15}/>
                <Skeleton className="ml-3" width={100} height={15}/>
            </div>
            <div style={{ width:"12.5%"  }} className="ml-4">
                <Skeleton className="ml-2" width={80} height={15}/>
                <Skeleton className="ml-2" width={100} height={15}/>
            </div>
            <div style={{width:"12.5%"}} className="ml-4">
                <Skeleton className="ml-2" width={80} height={15}/>
                <Skeleton className="ml-2" width={100} height={15}/>
            </div>
            <div style={{width:"12.5%"}} className="ml-4">
                <Skeleton className="ml-3" width={30} height={30} variant="circle"/>
            </div>
        </div>
    )
}

export const CallLogTableSkeleton = () => {
    return (
        <div className="d-flex flex-column w-100">
            <SingleSkeleton/>
            <SingleSkeleton/>
            <SingleSkeleton/>
            <SingleSkeleton/>
            <SingleSkeleton/>
            <SingleSkeleton/>
            <SingleSkeleton/>
        </div>
    )
}
