import React from 'react';
import UserReportTab from "../activityAddedChart/userReportTab";
import UserReportByTimeTab from "../activityAddedChart/userReportByTime";
import TypeReportTab from '../activityAddedChart/TypeReportTab';
import TypeReportByTime from '../activityAddedChart/TypeReportByTime';
import If from 'if-else-react'; 
import { Tab, Tabs } from '@material-ui/core';
import { Link } from 'react-router-dom';

class ActivitiesAdded extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab:'by-user'
        };
    };

    showTab=(e,value)=>{
        this.setState({activeTab:value});
    }

    render() {
        const {activeTab}=this.state;
        const {selectedDateType,selectedPipeline,selectedTeamUser,selectedDateFrom,selectedDateTo,barColors}=this.props;
        return (
            <React.Fragment>
                <div class="goalreport__right effort__report">
                    <div class="effort__top__wrapper">     
                        <div class="goalreport__right__top effort__right__top">
                            <div style={{display:"flex",
                                        justifyContent:"space-between",      
                                        alignItems:"center"}}>
                              <h2 className="goalreport__right__top_title">
                               How many activities were added?
                              </h2>
                                <Link to="/goal/progress">
                                    <button style={{backgroundColor: '#424242',
                                                    border: 'none',
                                                    padding: '15px 20px',
                                                    cursor: 'pointer',
                                                    borderRadius: '5px',
                                                    color: 'white',
                                                    fontSize: '16px',
                                                    marginRight: '10px'
                                                    }}>
                                        Back
                                    </button>
                                </Link>
                            </div>
                            
                            <div className="">
                                 <Tabs variant="scrollable" className="effor__tab__topper" value={activeTab} onChange={this.showTab}>
                                    <Tab className="effort__tab__button" value={'by-user'} label="by Users"/>
                                    <Tab className="effort__tab__button" value={'by-user-over-time'} label="by Users over time"  />
                                    <Tab className="effort__tab__button" value={'by-type'} label="by Types" />
                                    <Tab className="effort__tab__button" value={'by-type-over-time'} label="by Types over time" />
                                </Tabs>
                            </div>
                            
                        </div>
                        <div className="goalreport__main__content effort__main__content">
                            <If condition={activeTab=='by-user'}>
                                <UserReportTab activeTab={activeTab}
                                                selectedDateType={selectedDateType}
                                                selectedPipeline={selectedPipeline}
                                                selectedTeamUser={selectedTeamUser}
                                                selectedDateFrom={selectedDateFrom}
                                                selectedDateTo={selectedDateTo}
                                                barColors={barColors}
                                                goal={this.props.goal}
                                />
                            </If>
                            <If condition={activeTab=='by-user-over-time'}>
                                <UserReportByTimeTab activeTab={activeTab}
                                                        selectedDateType={selectedDateType}
                                                        selectedPipeline={selectedPipeline}
                                                        selectedTeamUser={selectedTeamUser}
                                                        selectedDateFrom={selectedDateFrom}
                                                        selectedDateTo={selectedDateTo}
                                                        barColors={barColors}
                                />
                            </If>
                            <If condition={activeTab == 'by-type'}>
                                <TypeReportTab activeTab={activeTab}
                                                selectedDateType={selectedDateType}
                                                selectedPipeline={selectedPipeline}
                                                selectedTeamUser={selectedTeamUser}
                                                selectedDateFrom={selectedDateFrom}
                                                selectedDateTo={selectedDateTo}
                                                barColors={barColors}
                                />
                            </If>
                            <If condition={activeTab == 'by-type-over-time'}>
                                <TypeReportByTime activeTab={activeTab}
                                                    selectedDateType={selectedDateType}
                                                    selectedPipeline={selectedPipeline}
                                                    selectedTeamUser={selectedTeamUser}
                                                    selectedDateFrom={selectedDateFrom}
                                                    selectedDateTo={selectedDateTo}
                                                    barColors={barColors}
                                />
                            </If>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default ActivitiesAdded
