import moment from 'moment-timezone';

const TimezoneConversation =(inputTime, currentTimezone='UTC', convertTimezone='UTC', format = "YYYY-MM-DD HH:mm:ss") =>{
    
    var currentTimeInGivenTimezone = moment.tz(inputTime, currentTimezone);
    return currentTimeInGivenTimezone
      .clone()
      .tz(convertTimezone)
      .format(format);
  }

  export const getTimeCompareWithCurrentTime = (date, dbTimezone='UTC') => {
    return moment.tz(date, dbTimezone)
      .clone()
      .fromNow();
  }

  export const formatDate= (date, dbTimezone = 'UTC',  format = "YYYY-MM-DD HH:mm:ss") => {
    return moment.tz(date, dbTimezone)
      .format(format)
  }

export const formatDateWithoutZone = (date, format = "YYYY-MM-DD HH:mm:ss") => {
    try {
        return moment(date).format(format)
    } catch (e) {
        return date
    }

}


export default TimezoneConversation;
