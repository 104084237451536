import { ArrowBack, ArrowForward, Block, Save } from "@material-ui/icons";
import { useState } from "react";
import GlobalModal from "../globals/Modal/GlobalModal";
import { createNewGoal } from "../../api/goalApi";
import StepFirst from "./AddDealSteps/StepFirst";
import StepSecond from "./AddDealSteps/StepSecond";
import StepThird from "./AddDealSteps/StepThird";
import StepFourth from "./AddDealSteps/StepFourth";
import StepFifth from "./AddDealSteps/StepFifth";
import StepSixth from "./AddDealSteps/StepSixth";


const GoalModalNew = props => {

    const [stepActive,setStepActive] = useState(1);
    const [stepComplete,setStepComplete] = useState(0);
    const [submitButtonText,setSubmitButtonText] = useState('Continue');
    const [submitForm,setSubmitForm] = useState(false);
    const [errorMessage,setErrorMessage] = useState('');
    const [validSteps,setValidSteps] = useState({});
    const [postData,setPostData] = useState({});

    const onClickBackBtn = () => {
        if(stepActive === 7) {
            setSubmitButtonText('Continue');
        }
        setStepActive(cStepActive => cStepActive-1);
    }

    const validateStep = (step,isValid,newPostData) => {
        setValidSteps({...validSteps,[step] : isValid});
        if(isValid){
            setPostData({...postData,...newPostData});
            toNext();
        }
    }

    const toNext = () => {
        if (stepActive === 6) {
            setSubmitButtonText('Save');
        }
        setStepActive(cStepActive => cStepActive+1);
        if(stepComplete < stepActive) {
            setStepComplete(cStepComplete => cStepComplete+1);
        }
    }

    const renderNextButtonIcon = () => {
        if(stepActive === 7) {
            return <Save/>
        } else if (validSteps[stepActive] !== true) {
            return <Block/>
        }
        return <ArrowForward/>
    }



    const submitGoalSetting = () => {
        if(!submitForm && stepActive === 7){
            setSubmitButtonText('Processing. . .');
            setSubmitForm(true);

            createNewGoal({data:postData}).then(res => {
                if(res.data.success){
                    setTimeout(() => {
                        setSubmitButtonText('Success');
                        setSubmitForm(false);
                        handleClose();
                    }, 2000);

                    props.reloadList(res.data.data.assignee_type , res.data.data.assignee_to);
                }else{
                    setSubmitButtonText('Fail!');
                    setErrorMessage('Something went wrong!');
                }
            });
        } else if(validSteps[stepActive] === true) {
            toNext();
        }
    };

   
    const handleClose = () => {
        setTimeout(() => {
            props.hideModal();   
        }, 500); 
    };

    return ( 
        <GlobalModal 
            open={props.isOpen} 
            onClose={handleClose}
            title={"Add goal"}
            bodyClass={'modal__body__goal'}
            wrapperClass={'globalMoalWrapper__goal'}
            showBackButton={stepActive !== 1}
            backButtonText={'Back'}
            backButtonIcon={<ArrowBack/>}
            onBackSubmit={onClickBackBtn}
            buttonText={submitButtonText}
            buttonIcon={renderNextButtonIcon()}
            onSubmit={submitGoalSetting}
            errorMessage={errorMessage}
            buttonTooltip={submitButtonText === 'Save' ? 'Hit Save' : ''}>
            
            <form action="/">
                <div className="add__goal_content__wr">
                    <div className="add__goal_steps__wr">
                        <StepFirst stepComplete={stepComplete} stepActive={stepActive} validateStep={validateStep}/>
                        <StepSecond stepComplete={stepComplete} stepActive={stepActive} validateStep={validateStep} postData={postData} />
                        <StepThird stepComplete={stepComplete} stepActive={stepActive} validateStep={validateStep} postData={postData}/>
                        <StepFourth stepComplete={stepComplete} stepActive={stepActive} validateStep={validateStep} postData={postData}/>
                        <StepFifth stepComplete={stepComplete} stepActive={stepActive} validateStep={validateStep} postData={postData}/>
                        <StepSixth stepComplete={stepComplete} stepActive={stepActive} validateStep={validateStep}/>
                    </div>
                </div>
            </form>

        </GlobalModal>
    );
}
 
export default GoalModalNew;