import React from 'react';
import Filter from './Filter'

const ReportHead = () => {
    return (
        <div className="business__report__top">
            <h3 className="section__title">Total Lead Count And Total Agent Activity</h3>
            <div className="section__select">
                <Filter/>
            </div>
        </div>
    )
}

export default ReportHead;
