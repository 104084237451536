import React from 'react';
import styled, { keyframes } from 'styled-components';

const shimmer = keyframes`
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: calc(200px + 100%) 0;
  }
`;

const SkeletonContainer = styled.div`
  padding: 16px;
`;

const SkeletonElement = styled.div`
  background: #f0f0f0;
  background-image: linear-gradient(90deg, #f0f0f0, #e0e0e0, #f0f0f0);
  background-size: 200px 100%;
  border-radius: 4px;
  margin-bottom: 12px;
  width: ${({ width }) => width || '100%'};
  height: ${({ height }) => height || '20px'};
`;


const SkeletonUserItem = styled(SkeletonElement)`
  height: 50px;
  width: 100%;
  margin-bottom: 25px;

`;

const GoalSkeleton = () => {
  return (
    <SkeletonContainer>
      {Array.from({ length: 3 }).map((_, index) => (
        <SkeletonUserItem key={index} />
      ))}
    </SkeletonContainer>
  );
};

export default GoalSkeleton;
