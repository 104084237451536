import React from 'react';
import ActivitiesAdded from './Options/ActivitiesAdded';
import ActivitiesCompleted from './Options/ActivitiesCompleted';
import ConversationsSent from './Options/ConversationsSent';
import ConversationsReceived from './Options/ConversationsReceived';
import DealStarted from './Options/DealStarted';
import DealProgress from './Options/DealProgress';
import DealVelocity from './Options/DealVelocity';
import DealConversion from './Options/DealConversion';
import DealsWon from './Options/DealsWon';
import DealsLost from './Options/DealsLost';
import RevenueForecast from './Options/RevenueForecast';
import If from 'if-else-react';


class MiddleSide extends React.Component {
    barColors = {
        sms:"#84af28",
        mms:"#007150",
        call:"#99124c",
        meeting:"#84af28",
        task:"#75268b",
        email:"#b35322",
        deadline:"#0c7105",
        followup:"#007150",
    };

    constructor(props) {
        super(props);
        this.state = {};
    };

    render() {
        const {selectedOption,selectedDateType,selectedPipeline,selectedTeamUser,selectedDateFrom,selectedDateTo,goal}=this.props;
        return (
            <React.Fragment>
                <If condition={selectedOption == 'activity-added'}>
                    <ActivitiesAdded selectedDateType={selectedDateType}
                                     selectedPipeline={selectedPipeline}
                                     selectedTeamUser={selectedTeamUser}
                                     selectedDateFrom={selectedDateFrom}
                                     selectedDateTo={selectedDateTo}
                                     barColors={this.barColors}
                                     goal={goal}
                    />
                </If>
                <If condition={selectedOption == 'activity-completed'}>
                    <ActivitiesCompleted
                        selectedDateType={selectedDateType}
                        selectedPipeline={selectedPipeline}
                        selectedTeamUser={selectedTeamUser}
                        selectedDateFrom={selectedDateFrom}
                        selectedDateTo={selectedDateTo}
                        barColors={this.barColors}
                        goal={goal}
                    />
                </If>
                <If condition={selectedOption == 'conversions-sent'}>
                    <ConversationsSent
                        selectedDateType={selectedDateType}
                        selectedPipeline={selectedPipeline}
                        selectedTeamUser={selectedTeamUser}
                        selectedDateFrom={selectedDateFrom}
                        selectedDateTo={selectedDateTo}
                        barColors={this.barColors}
                    />
                </If>
                <If condition={selectedOption == 'conversions-received'}>
                    <ConversationsReceived
                        selectedDateType={selectedDateType}
                        selectedPipeline={selectedPipeline}
                        selectedTeamUser={selectedTeamUser}
                        selectedDateFrom={selectedDateFrom}
                        selectedDateTo={selectedDateTo}
                        barColors={this.barColors}
                    />
                </If>
                <If condition={selectedOption == 'deals-started'}>
                    <DealStarted selectedDateType={selectedDateType}
                                 selectedPipeline={selectedPipeline}
                                 selectedTeamUser={selectedTeamUser}
                                 selectedDateFrom={selectedDateFrom}
                                 selectedDateTo={selectedDateTo}
                                 goal={goal}
                    />
                </If>
                <If condition={selectedOption == 'deal-progress'}>
                    <DealProgress selectedDateType={selectedDateType}
                                  selectedPipeline={selectedPipeline}
                                  selectedTeamUser={selectedTeamUser}
                                  selectedDateFrom={selectedDateFrom}
                                  selectedDateTo={selectedDateTo}
                                  goal={goal}
                    />
                </If>
                <If condition={selectedOption == 'deal-velocity'}>
                    <DealVelocity selectedDateType={selectedDateType}
                                  selectedPipeline={selectedPipeline}
                                  selectedTeamUser={selectedTeamUser}
                                  selectedDateFrom={selectedDateFrom}
                                  selectedDateTo={selectedDateTo}
                    />
                </If>
                <If condition={selectedOption == 'deal-conversion'}>
                    <DealConversion/>
                </If>
                <If condition={selectedOption == 'deals-won'}>
                    <DealsWon selectedDateType={selectedDateType}
                              selectedPipeline={selectedPipeline}
                              selectedTeamUser={selectedTeamUser}
                              selectedDateFrom={selectedDateFrom}
                              selectedDateTo={selectedDateTo}
                              goal={goal}
                    />
                </If>
                <If condition={selectedOption == 'deals-lost'}>
                    <DealsLost selectedDateType={selectedDateType}
                               selectedPipeline={selectedPipeline}
                               selectedTeamUser={selectedTeamUser}
                               selectedDateFrom={selectedDateFrom}
                               selectedDateTo={selectedDateTo}
                               goal={goal}

                    />
                </If>
                <If condition={selectedOption == 'revenue-forecast'}>
                    <RevenueForecast/>
                </If>
            </React.Fragment>
        );
    }
}

export default MiddleSide
