import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';

const useStyles = makeStyles((theme) => ({
  paper : {
      borderRadius : "8px"
  }
}));

export default function GlobalPopover(props) {
  const classes = useStyles();

  const handleClose = () => {
    
  };

  return (
    <>
      <Popover
        open={props.open}
        anchorEl={props.target}
        onClose={handleClose}
        classes={{paper:classes.paper}}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
      >
        {props.children}
      </Popover>
    </>
  );
}