import React from 'react';
import {BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend,ResponsiveContainer,ReferenceLine} from 'recharts';
import { getGoalStatistics } from '../../../../api/goalApi';
import If from 'if-else-react';
import CoreConstants from '../../../../constants/CoreConstants';
import GoalSkeleton from '../../../common/Skeletons/GoalSkeleton';

export default class DealStartedByuser extends React.Component{
    constructor(props) {
        super(props);
        this.state={
            data:[],
            userData:[],
            responseLength:0,
            duration:'today',
            teamUser:0,
            loading:true,
            goalValue: 0,
            viewType:'count',
            pipeline:this.props.selectedPipeline,
            totalCount:0,
            goal: null,
            perDayGoalValue: null
        }
    }
    loadChart=()=>{
        getGoalStatistics({
            type:5,
            chart:'byUser',
            duration:this.props.selectedDateType,
            pipeline:this.props.selectedPipeline,
            teamUser:this.props.selectedTeamUser,
            from:this.props.selectedDateFrom,
            to:this.props.selectedDateTo
        }).then(
                response  => {
                    let res = response.data;
                    let chartData=[];
                    let totalCount=0;
                    if(res.length>0){
                        res.map(function(data,index){
                            totalCount +=data.total_count;
                            chartData.push({name: data.full_name, count: data.total_count, value:data.total_value});
                        });
                    }
                    this.setState({data:chartData,userData:res,loading:false,totalCount:totalCount});
                    this.setGoalFromGoalSetting();
                }
            );
    }

    setGoalFromGoalSetting = () => {
        let exceptedOutComeKey = 1;
        let perDayGoalValue = 0;
        let goalValue = 0;

        if (this.state.viewType == 'count') {
            exceptedOutComeKey = 1;
        } else if (this.state.viewType == 'value') {
            exceptedOutComeKey = 2;
        }

        if (this.props.goal.goal_duration_type == 1) {
            perDayGoalValue = parseFloat(this.props.goal.expected_outcome_value) / 30;
        } else if (this.props.goal.goal_duration_type == 2) {
            perDayGoalValue = parseFloat(this.props.goal.expected_outcome_value) / 30;
        } else if (this.props.goal.goal_duration_type == 5) {
            perDayGoalValue = parseFloat(this.props.goal.expected_outcome_value) / 365;
        }  else if (this.props.goal.goal_duration_type == 6) {
            perDayGoalValue = parseFloat(this.props.goal.expected_outcome_value) / 365;
        }

        if (this.state.duration == 'today') {
            goalValue = perDayGoalValue;
        } else if (this.state.duration == 'yesterday') {
            goalValue = perDayGoalValue;
        } else if (this.state.duration == 'thisweek') {
            goalValue = perDayGoalValue * 7;
        } else if (this.state.duration == 'lastweek') {
            goalValue = perDayGoalValue * 7;
        } else if (this.state.duration == 'thismonth') {
            goalValue = perDayGoalValue * 30;
        } else if (this.state.duration == 'lastmonth') {
            goalValue = perDayGoalValue * 30;
        } else if (this.state.duration == 'thisyear') {
            goalValue = perDayGoalValue * 365;
        } else if (this.state.duration == 'lastyear') {
            goalValue = perDayGoalValue * 365;
        }

        if (this.props.goal.goal_type_key == 3 && this.props.goal.expected_outcome_key == exceptedOutComeKey && (this.props.selectedPipeline == 0 || this.props.goal.pipeline_id == this.props.selectedPipeline)) {
            this.setState({
                goal: this.props.goal,
                perDayGoalValue: perDayGoalValue,
                goalValue: goalValue
            });
        } else {
            this.setState({
                goal: null,
                perDayGoalValue: null,
                goalValue: 0
            });
        }
    }

    populateUserTable = () => {
        return this.state.data.map((value, index) => {
            return <tr key={index}>
                <td>{value.name}</td>
                <td>{value.count}</td>
                <td>{this.state.viewType=='value'?CoreConstants.DEAL_CURRENCY:''}{this.state.goalValue}</td>
                <td>{CoreConstants.DEAL_CURRENCY+parseFloat(value.value).toLocaleString()}</td>
                <td>{CoreConstants.DEAL_CURRENCY+parseFloat((value.value/value.count).toFixed(2)).toLocaleString()}</td>
            </tr>;
        });
    }

    loadingPanel(){
        if (this.state.loading){
            return (
                <GoalSkeleton/>);
        }else{
            return (
                <div className="goal_no_data_text_wrapper">
                    <div>
                        <h2 className="no_data__loading_title text-center">No activities have been added during the chosen period</h2>
                        <br/>
                        <p className="no_data__loading_subtitle">Try selecting another pipeline or a longer period</p>
                    </div>
                </div>
            );
        }
    }

    componentDidMount(){
        this.loadChart();
    }

    radioOnClick=(type)=>{
        this.setState({viewType:type}, () => {
            this.setGoalFromGoalSetting();
        });

    }
    componentDidUpdate(){
        if(this.props.activeTab=='by-user' && this.state.duration!=this.props.selectedDateType){
            this.setState({duration:this.props.selectedDateType},() => {
                this.loadChart();
            })
        }else if(this.props.activeTab=='by-user' && this.state.teamUser!=this.props.selectedTeamUser){
            this.setState({teamUser:this.props.selectedTeamUser},() => {
                this.loadChart();
            })
        }else if(this.props.activeTab=='by-user' && this.state.pipeline!=this.props.selectedPipeline){
            this.setState({pipeline:this.props.selectedPipeline},() => {
                this.loadChart();
            })
        }
    }

    customTooltipChartCount = ({ payload, label, active }) => {
        if (active) {
            return (
                <div className="custom-tooltip" style={{backgroundColor:"#ffffff",padding:10,borderRadius:5,fontSize:13,lineHeight:1}}>
                    <p className="label" style={{fontSize:14}}><i className="fa fa-user"></i> {`${label}`}</p>
                    <p className="intro my-0"><i className="fa fa-filter" style={{width:18}}></i>
                        {payload[0].name}&nbsp;<span style={{float:"right",marginLeft:10,paddingTop:2}}>{`${payload[0].unit}${parseFloat(payload[0].value).toLocaleString()}`} [Goal: {this.state.goal ? parseFloat(this.state.goalValue).toLocaleString() : '--'}]</span></p>
                </div>
            );
        }
        return null;
    }

    renderCustomAxisTick = ({ x, y, payload }) => {
        return (
            <text x={x} y={y} fill={"#0a0101"} textAnchor="middle" dy={8} >
                {CoreConstants.DEAL_CURRENCY+parseFloat(payload.value).toLocaleString()}
            </text>
        );
    }

    render() {
        return(
            <div>
            <If condition={this.props.activeTab=='by-user'}>
                <div className="tab-pane fade in active show">
                    <div className="tabs__content-action">
                        <div className="action_lists">
                            <div className="action_list">
                                <div className="action__input action__input__radio">
                                <label className={(this.state.viewType=='count'?" active ":"")+"widget-radio"} onClick={()=>this.radioOnClick('count')}>
                                    <input  type="radio" name="viewType" value="count"
                                           checked={false}/>Count</label>
                                   <label className={(this.state.viewType=='value'?" active ":"")+"widget-radio"} onClick={()=>this.radioOnClick('value')}>
                                       <input  type="radio" name="viewType" value="value"/>Value</label>
                                </div>
                            </div>
                            <div className="action_list">
                                <div style={{width:"100%",height:400}}>
                                    {(this.state.data.length===0||this.state.loading || this.state.totalCount==0)?this.loadingPanel():
                                    <ResponsiveContainer>
                                        <BarChart
                                            data={this.state.data}
                                            layout={'vertical'}
                                            margin={{
                                                top: 20, right: 30, left: 20, bottom: 5,
                                            }}
                                        >
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis type="number"  tick={this.state.viewType==='count'?{fontSize: 14}:this.renderCustomAxisTick}/>
                                            <YAxis dataKey="name" type="category" tick={{fontSize: 14}}/>
                                            <Tooltip content={this.customTooltipChartCount}/>
                                            <Legend wrapperStyle={{fontSize:14}}/>
                                            <Bar name={this.state.viewType==='count'?'Total Deal':'Total Deal Value'} unit={this.state.viewType==='count'?'':CoreConstants.DEAL_CURRENCY} dataKey={this.state.viewType} stackId="a" fill={"#99124c"} />
                                        </BarChart>
                                    </ResponsiveContainer>}
                                </div>
                            </div>
                            <If condition={this.state.data.length !== 0 && this.state.totalCount!=0}>
                                <div className="action_list">
                                    <table className={'mt-5 table table-bordered text-center'}>
                                        <thead><th>User</th><th>Deal Started</th><th>Goal</th><th>Result</th><th>Average Deal Value</th></thead>
                                        <tbody>{this.populateUserTable()}</tbody>
                                    </table>
                                </div>
                            </If>
                        </div>
                    </div>
                </div>
            </If>
            </div>
        );
    }
}
