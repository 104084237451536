import React, { useEffect, useState } from 'react';
import LeftSide from './LeftSide';
import MiddleSide from './MiddleSide';
import { getGoalReport } from '../../../api/goalApi';
import { useParams } from 'react-router-dom';
import './goalReportStatus.css';
import Utils from '../../../helpers/Utils';
import GoalIndexSkeleton from '../../common/Skeletons/GoalIndexSkeleton';


const GoalReport = () => {

    const [selectedPipeline,setSelectedPipeline] = useState(0);
    const [selectedTeamUser,setSelectedTeamUser] = useState(0);
    const [selectedDateType,setSelectedDateType] = useState('today');
    const selectedDateFrom ='2020-07-05';
    const selectedDateTo = '2020-07-05';
    const [selectedOption,setSelectedOption] = useState('activity-added');
    const [goal,setGoal] = useState(null);
    const [hidePipeline,setHidePipeline] = useState(false);

    const { goalId } = useParams();

    useEffect(() => {
        document.title = `Goal Report | ${Utils.getAccountData('AuthUserAgencyName')}`;
        if(window.setActiveSidebar) {
            window.setActiveSidebar('report');
        }
        getGoalSetting(goalId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])


    const getGoalSetting = () => {
        getGoalReport({goalId : goalId}).then(
            response  => {
                if (response.data.status === 'success') {
                    let mySelectedDateType = selectedDateType;

                    if (typeof response.data.result.goal_duration_type) {
                        if (response.data.result.goal_duration_type == 1) {
                            mySelectedDateType = 'thismonth';
                        } else if (response.data.result.goal_duration_type == 5) {
                            mySelectedDateType = 'thisyear';
                        } 
                    } 
                    if (typeof response.data.result.pipeline_id != 'undefined' && response.data.result.pipeline_id) {
                        setSelectedPipeline(response.data.result.pipeline_id)
                    } else {
                        setSelectedPipeline(1);
                    }
                    let selectedOption = 'activity-added';
                    let hidePipeline = false;

                    if (response.data.result.goal_type_key == 1) {
                        selectedOption = 'activity-added';
                        hidePipeline = true;
                    } else if (response.data.result.goal_type_key == 2) {
                        selectedOption = 'activity-completed';
                        hidePipeline = true;
                    } else if (response.data.result.goal_type_key == 3) {
                        selectedOption = 'deals-started';
                    } else if (response.data.result.goal_type_key == 4) {
                        selectedOption = 'deal-progress';
                    } else if (response.data.result.goal_type_key == 5) {
                        selectedOption = 'deals-won';
                    }  else if (response.data.result.goal_type_key == 6) {
                        selectedOption = 'deals-lost';
                    }
                    setGoal(response.data.result);
                    setSelectedDateType(mySelectedDateType);
                    setSelectedOption(selectedOption);
                    setHidePipeline(hidePipeline);
                } else {
                    alert(response.data.result);
                }

            }
        )
        .catch((error) => {
            alert('Server Error. Please Reload.');
        });
    }

    
    return (
        goal ?
        <div class="goalreport__wr effort__wr">
            <LeftSide
                selectedPipeline={selectedPipeline}
                selectedTeamUser={selectedTeamUser}
                selectedDateFrom={selectedDateFrom}
                selectedOption={selectedOption}
                selectedDateTo={selectedDateTo}
                pipelineOptionOnChangeHandler={(e) => setSelectedPipeline(e.target.value)}
                teamUserOptionOnChangeHandler={(e) => setSelectedTeamUser(e.target.value)}
                handleDateRangeChange={(e) => setSelectedDateType(e.target.value)}
                changeSelectedOption={(option) => setSelectedOption(option)}
                selectedDateType={selectedDateType}
                goal={goal}
                hidePipeline={hidePipeline}
                toggleHidePipelineHandler={value => setHidePipeline(value)}
            />
            <MiddleSide selectedOption={selectedOption}
                        selectedDateType={selectedDateType}
                        selectedPipeline={selectedPipeline}
                        selectedTeamUser={selectedTeamUser}
                        selectedDateFrom={selectedDateFrom}
                        selectedDateTo={selectedDateTo}
                        goal={goal}
            />
        </div> : 
        
        <div style={{paddingTop:"100px",paddingLeft:"200px"}}><GoalIndexSkeleton/></div>
    );
}

export default GoalReport
