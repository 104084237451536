import React from "react";
import EachRow from "./EachRow";
import { TableCell, Table, TableBody, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    root: {
      width: '100%',
    },
    table: {
      minWidth: 750,
    },
});

const UserOrderTable = (props) => {
  const classes = useStyles();

  const renderTableHeader = () => {
    return (
      <>
        <TableCell>Preview</TableCell>
        <TableCell>Recipients</TableCell>
        <TableCell>Type</TableCell>
        <TableCell>Status</TableCell>
        <TableCell>Delivery Status</TableCell>
        <TableCell>Created at</TableCell>
        <TableCell>Total</TableCell>
      </>
    )
  }

  const renderTableBody = () => {
    return (
      <>
        {!props.loading && props.list.length === 0 && (
          <TableRow>
            <TableCell>No data available in table</TableCell>
          </TableRow>
        )}

        {props.list.map((eachItem) => {
          return <EachRow eachItem={eachItem} key={eachItem.id} />;
        })}
      </>
    )
  }

  return (
    <div className="chart__table__wr user__order__table__wr">
      <TableContainer className={classes.container +" awesome__scroll_bar2"}>
        <Table stickyHeader aria-label="sticky table" className={"chart__table "+classes.table}>
          <TableHead>
              <TableRow>
                  {renderTableHeader()}
              </TableRow>
          </TableHead>
          <TableBody className="chart__table__body">
              {renderTableBody()}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default UserOrderTable;
