const ActionType = {
    HANDLE_LOADING_CHART: 'HANDLE_LOADING_CHART',
    HANLDE_USER_TYPE_CHANGE: 'HANLDE_USER_TYPE_CHANGE',
    HANLDE_USER_CHANGE: 'HANLDE_USER_CHANGE',
    HANDLE_DATE_CHANGE: 'HANDLE_DATE_CHANGE',
    HANDLE_COMPARE_FOR_CHANGE: 'HANDLE_COMPARE_FOR_CHANGE',
    HANDLE_COMPARE_WITH_CHANGE: 'HANDLE_COMPARE_WITH_CHANGE',
    STORE_COMPARE_DATA: 'STORE_COMPARE_DATA',
    STORE_SUB_USER_DATA: 'STORE_SUB_USER_DATA',
    STORE_ADD_COLUMN_DATA: 'STORE_ADD_COLUMN_DATA',

    HANDLE_LOADING_CARD: 'HANDLE_LOADING_CARD',
    STORE_CARD_DATA: 'STORE_CARD_DATA',
}
export default ActionType;