import React from 'react';
import ReactEcharts from 'echarts-for-react';
import Moment from 'moment';

const DEFAULT_VALUE = {
    GIFT : 0,
    LETTER : 0,
    GREETINGCARD : 0,
    POSTCARD : 0
}

const ReportChart = (props) => {

    const getOption = () => {

        let calculate = {
            date : [],
            GIFT : [],
            LETTER : [],
            GREETINGCARD: [],
            POSTCARD: []
        };

        
        for(let i = 5; i >= 0; i--) {
            let monthName = Moment().subtract(i, 'months').format('MMMM, YYYY');
            let element = props.orderCharts.find(el => el.date === monthName);
            if(!element) {
                element = {...DEFAULT_VALUE};
            }
            calculate.date.push(monthName);
            calculate.GIFT.push(element.GIFT);
            calculate.LETTER.push(element.LETTER);
            calculate.GREETINGCARD.push(element.GREETINGCARD);
            calculate.POSTCARD.push(element.POSTCARD);
        }

        return {
            tooltip : {
                trigger: 'axis',
                axisPointer: {
                    type: 'cross',
                    label: {
                        backgroundColor: '#252d47'
                    }
                }
            },
            toolbox: {
                feature: {
                    saveAsImage: {
                        title : "",
                    }
                }
            },
            legend: {
                data:['GIFT', 'LETTER', 'GREETING CARD', 'POSTCARD']
            },
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true
            },
            xAxis: {
                type: 'category',
                boundaryGap: false,
                data: calculate.date,
                nameTextStyle : {
                  color:'#ffffff'
                }
            },
            yAxis: {
                type: 'value',
                axisTick: {
                    show: false
                },
                splitLine: {
                    lineStyle: {
                        color: '#f7f7f7'
                    }
                }
            },
            series: [{
                name: "GIFT",
                data: calculate.GIFT,
                color: {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [{
                        offset: 0, color: '#114ea9' // color at 0% position
                    }, {
                        offset: 1, color: '#3bd1bf' // color at 100% position
                    }],
                    global: false // false by default
                },
                type: 'line',
                smooth: true,
                showSymbol: true,
                showAllSymbol: true,
                symbol: 'circle',
                symbolSize: 10,
                lineStyle: {
                    normal: {
                        width: 2
                    }
                },
            },
            {
                name: "LETTER",
                color: {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [{
                        offset: 0, color: '#ff3221' // color at 0% position
                    }, {
                        offset: 1, color: '#B53471' // color at 100% position
                    }],
                    global: false // false by default
                },
                data: calculate.LETTER,
                type: 'line',
                smooth: true,
                showSymbol: true,
                showAllSymbol: true,
                symbol: 'circle',
                symbolSize: 10,
                lineStyle: {
                    normal: {
                        width: 2
                    }
                }
            },
            {
                name: "GREETING CARD",
                color: {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [{
                        offset: 0, color: '#9b59b6' // color at 0% position
                    }, {
                        offset: 1, color: '#FDA7DF' // color at 100% position
                    }],
                    global: false // false by default
                },
                data: calculate.GREETINGCARD,
                type: 'line',
                smooth: true,
                showSymbol: true,
                showAllSymbol: true,
                symbol: 'circle',
                symbolSize: 10,
                lineStyle: {
                    normal: {
                        width: 2
                    }
                }
            },
            {
                name: "POSTCARD",
                color: {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [{
                        offset: 0, color: '#f1c40f' // color at 0% position
                    }, {
                        offset: 1, color: '#6F1E51' // color at 100% position
                    }],
                    global: false // false by default
                },
                data: calculate.POSTCARD,
                type: 'line',
                smooth: true,
                showSymbol: true,
                showAllSymbol: true,
                symbol: 'circle',
                symbolSize: 10,
                lineStyle: {
                    normal: {
                        width: 2
                    }
                }
            }
        
        ]}
    };
  return (
    <>
        <div className="order__chart__content">
            <h4 className="order__chart__title ">Historical Sends</h4>
            <ReactEcharts 
                style={{height: '250px', width: '100%'}}
                className={"Echart__custom"}
                notMerge={true}
                lazyUpdate={true}
                theme={"light"}
                option={getOption()}
            />
        </div>
    </>
  );
};

export default ReportChart;