import axios from "axios";
import { getCookie } from "../helpers/Cookie";

export const sendRequest = (method, url, payload = [], config = []) => {
    let data = [];
    const apiKey = getCookie(process.env.REACT_APP_ACCESS_TOKEN);

    try{
        payload.append('api-key', apiKey)
        data = payload;
    } catch (error) {
        data = {...payload, ...{'api-key': apiKey}};
    }
    // try{
    //     payload.append('api-key', "W6GSG6PQ6K5ZCQ1UQT5E")
    //     data = payload;
    // } catch (error) {
    //     data = {...payload, ...{'api-key':  "W6GSG6PQ6K5ZCQ1UQT5E"}};
    // }
    return axios.request({
        method: method,
        url: url,
        data: data,
        params: (method === 'get' || method === 'GET') ? data : null
    });
}

// export const sendRequest = (method, url, data = [], config = []) => {
//     switch (method) {
//         case 'get':
//             return axios.get(url, {data})
//                 .then(res => res.data)
//                 .catch(err => {
//                     console.log(err);
//                 });
//
//         case 'post':
//             return axios.post(url, data, config)
//                 .then((response) => response.data)
//                 .catch(err => {
//                     console.log(err);
//                 });
//         default:
//             return {
//                 status: false,
//                 message: "Unfamiliar request type"
//             }
//     }
// }

// "W6GSG6PQ6K5ZCQ1UQT5E"

export const sendRequestBackEnd = (method, url, postData) => {
    const apiKey = getCookie(process.env.REACT_APP_ACCESS_TOKEN);

    return fetch(url, {
      method: method,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + apiKey
      },
      referrerPolicy: 'origin',
      body: JSON.stringify(postData)
    })
    .then((response) => response.json())
    .then((responseData) => {
      return responseData
    })
    .finally(() => { })
    .catch(error =>{
      console.log("url", error)
      return false;
    })
  }
  
  export const sendRequestBackEndGetMethod = (url,param={}) => {

      // Object.keys(param).forEach((key)=>{
      //   apiEndPoint.searchParams.append(key,param[key])
      // })
    const apiKey = getCookie(process.env.REACT_APP_ACCESS_TOKEN);

    return fetch(url, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + apiKey,
        // Authorization: 'Bearer DGM2RNXDL8KS85JD066E', //live
      },
      referrerPolicy: 'origin'
    })
    .then((response) => response.json())
    .then((responseData) => {
      return responseData
    })
    .finally(() => { })
    .catch(error =>{
      console.log("url", error)
      return false;
    })
  }