import React, {createContext, useReducer} from 'react'
import Utils from '../../../helpers/Utils';
import ActionType from './ActionType';

const ReportViewContext = createContext({});

export const reportViewStates = {
    isLoadingChart: false,
    isLoadingCard: false,
    isLoadingTable: false,
    userType: 'owner',
    userId: Utils.getAccountData('userId'),
    date: 'yesterday',
    compareFor: 'new_leads',
    compareWith: '',
    selectedUserType: '',
    selectedUser: '',
    selectedDate: '',
    selectedCompareFor: 'New Leads',
    selectedCompareWith: '',
    chartDataFor: null,
    chartDataWith: null,
    subUsers: [],
    addColumn: null,
    currentCardData: [],
    currentCardDataCount: [],
    // previousCardData: [],
    previousCardDataCount: []
}

export const reportViewReducer = (state, action) => {
    let payload = action.payload
    switch(action.type){
        case ActionType.HANDLE_LOADING_CHART:
            return {...state, isLoadingChart: payload}
            
        case ActionType.HANLDE_USER_TYPE_CHANGE:
            return {...state, userType: payload.userType, selectedUserType: payload.selectedUserType}

        case ActionType.HANLDE_USER_CHANGE:
            return {...state, userId: payload.userId, selectedUser: payload.selectedUser}

        case ActionType.HANDLE_DATE_CHANGE:
            return {...state, date: payload.date, selectedDate: payload.selectedDate}

        case ActionType.HANDLE_COMPARE_FOR_CHANGE:
            return {...state, compareFor: payload.compareFor, selectedCompareFor: payload.selectedCompareFor}

        case ActionType.HANDLE_COMPARE_WITH_CHANGE:
            return {...state, compareWith: payload.compareWith, selectedCompareWith: payload.selectedCompareWith}

        case ActionType.STORE_COMPARE_DATA:
            return {...state, chartDataFor: payload.compareFor, chartDataWith: payload.compareWith}

        case ActionType.STORE_SUB_USER_DATA:
            return {...state, subUsers: payload}

        case ActionType.STORE_ADD_COLUMN_DATA:
            return {...state, addColumn: payload}

        case ActionType.HANDLE_LOADING_CARD:
            return {...state, isLoadingCard: payload}
            
        case ActionType.STORE_CARD_DATA:
            return {
                ...state, 
                currentCardData: payload.currentCardData,
                currentCardDataCount: payload.currentCardDataCount,
                // previousCardData: payload.previousCardData,
                previousCardDataCount: payload.previousCardDataCount
            }

        default:
            return state
    }
}

const ReportViewProvider = ({children}) => {
    const [reportStates, detailsDispatch] = useReducer(reportViewReducer, reportViewStates)
    return (
        <ReportViewContext.Provider
            value={{
                // states
                reportStates,
                // dispatch
                detailsDispatch
            }}
        >
            {children}
        </ReportViewContext.Provider>
    )
}
export {ReportViewContext, ReportViewProvider}