import React from 'react';
import UserReportTab from "../activityAddedChart/userReportTab";
import UserReportByTimeTab from "../activityAddedChart/userReportByTime";
import If from 'if-else-react';

class DealConversion extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab:'by-stages'
        };
    };

    showTab=(tab)=>{
        this.setState({activeTab:tab});
    }

    render() {
        const {activeTab}=this.state;
        return (
            <React.Fragment>
                <div class="goalreport__right effort__report">
                    <div class="gola__page_topbar_tabs">
                        <div class="topbar__wrapper_tabs">
                            <div class="tabbar__title">
                                <h3>What are our deal conversion rates for closed deals?</h3>
                            </div>
                            <ul class="nav nav-tabs">
                                <li><a class={(activeTab=='by-stages')?"active":""} onClick={()=>this.showTab('by-stages')}>by Stages</a></li>
                                <li><a class={(activeTab=='by-stages-over-time')?"active":""} onClick={()=>this.showTab('by-stages-over-time')}>by Stages over time</a></li>
                                <li><a class={(activeTab=='by-user')?"active":""} onClick={()=>this.showTab('by-user')}>by Users</a></li>
                                <li><a class={(activeTab=='by-user-over-time')?"active":""} onClick={()=>this.showTab('by-user-over-time')}>by Users over time</a></li>
                                {/*<li><a  class={(activeTab=='by-product')?"active":""} onClick={()=>this.showTab('by-product')}>by Products</a></li>*/}
                                {/*<li><a  class={(activeTab=='by-product-over-time')?"active":""} onClick={()=>this.showTab('by-product-over-time')}>by Products over time</a></li>*/}
                            </ul>
                        </div>
                    </div>
                    <div class="tab-content gola__tabs__content">
                        <If condition={activeTab=='by-user'}>
                            <UserReportTab activeTab={activeTab}/>
                        </If>
                        <If condition={activeTab=='by-user-over-time'}>
                            <UserReportByTimeTab activeTab={activeTab}/>
                        </If>

                        <div id="menu1" class="tab-pane fade">
                            <div class="tabs__content-action">
                                <div class="action_lists">
                                    <div class="action_list">
                                        <div class="action__input action__input__radio">
                                            <label class="widget-radio active"><input type="radio" name="viewType" value="count" checked="&quot;checked&quot;/" />Count</label>
                                            <label class="widget-radio"><input type="radio" name="viewType" value="value" />Value</label>
                                        </div>
                                        <div class="action__input action__input__radio">
                                            <label class="widget-radio"><input type="radio" name="userType" value="creator" />Deal creator</label>

                                            <label className="widget-radio active"><input type="radio" name="userType" value="owner" checked="&quot;checked&quot;/" />Current owner</label>
                                        </div>
                                        <div className="action__input action__input__checkbox">
                                            <label className="checkbox">
                                                <input type="checkbox" name="" value="1" />
                                                <span className="checkbox__label">Divide by current status</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="menu2" className="tab-pane fade">
                            <div className="tabs__content-action">
                                <div className="action_lists">
                                    <div className="action_list">
                                        <div className="action__input action__input__radio">
                                            <label className="widget-radio active"><input type="radio" name="viewType" value="count" checked="&quot;checked&quot;/" />Count</label>
                                            <label className="widget-radio"><input type="radio" name="viewType" value="value" />Value</label>
                                        </div>
                                        <div className="action__input action__input__radio">
                                            <label className="widget-radio"><input type="radio" name="userType" value="creator" />Deal creator</label>
                                            <label className="widget-radio active"><input type="radio" name="userType" value="owner" checked="&quot;checked&quot;/" />Current owner</label>
                                        </div>
                                        <div className="action__input action__input__checkbox">
                                            <label className="checkbox">
                                                <input type="checkbox" name="" value="1" />
                                                <span className="checkbox__label">Divide by current status</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="menu3" className="tab-pane fade">
                            <div className="tabs__content-action">
                                <div className="action_lists">
                                    <div className="action_list">
                                        <div className="action__input action__input__radio">
                                            <label className="widget-radio active"><input type="radio" name="viewType" value="count" checked="&quot;checked&quot;/" />Count</label>
                                            <label className="widget-radio"><input type="radio" name="viewType" value="value" />Value</label>
                                        </div>
                                        <div className="action__input action__input__radio">
                                            <label className="widget-radio"><input type="radio" name="userType" value="creator" />Deal creator</label>
                                            <label className="widget-radio active"><input type="radio" name="userType" value="owner" checked="&quot;checked&quot;/" />Current owner</label>
                                        </div>
                                        <div className="action__input action__input__checkbox">
                                            <label className="checkbox">
                                                <input type="checkbox" name="" value="1" />
                                                <span className="checkbox__label">Divide by current status</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="menu4" className="tab-pane fade">
                            <h3>Menu 4</h3>
                            <p>Some content in menu 2.</p>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default DealConversion
