import ReportView from "./Index";
import { ReportViewProvider } from "./reducer/ReportV2Reduers";

const ReportViewV2ModuleApp = () => {
    return(
        <ReportViewProvider>
            <ReportView />
        </ReportViewProvider>
    )
}
export default ReportViewV2ModuleApp;