import React from 'react';
import { useDispatch, useSelector} from "react-redux";
import { changeSecColumnFilter } from '../../actions/reportAction.js';
import { FormControl, MenuItem, Select } from '@material-ui/core';
import { KeyboardArrowDown } from '@material-ui/icons';

const SecondColumnWise = () => {

    const dispatch = useDispatch();
    const { currentSecColumnFilterID, currentFirstColumnFilterID, defaultColumns } = useSelector(state => state.reportReducer);

    const selectType = ({id,title})=>{
        dispatch(changeSecColumnFilter({
            id,
            title
        }));
    };

    const renderAllTypes = () => {
        const allTypes = [];
        const allColumn = Object.assign({},{null:"Nothing"},defaultColumns);
        for(let i in allColumn){
            if(i !== currentFirstColumnFilterID)
                allTypes.push(<MenuItem className={'repot_dropdown_item'} value={i}>{allColumn[i]}</MenuItem>);
        }
        return allTypes;
    }

    return (
        <FormControl variant="outlined" margin="none" focused="false" className="business_select__wr">
            <Select
                className="business_select select__dark__back"
                labelId="demo-simple-select-outlined-label"
                value={currentSecColumnFilterID}
                onChange={(e,value) => selectType({id:value.props.value,title:value.props.children})}
                IconComponent = {KeyboardArrowDown}
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                MenuProps={{
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                    },
                    transformOrigin: {
                        vertical: "top",
                        horizontal: "left"
                    },
                    getContentAnchorEl: null
                }}
            >
                {renderAllTypes()}
            </Select>
        </FormControl>
    );
}

export default SecondColumnWise;