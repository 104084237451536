import { FormControl, MenuItem } from "@material-ui/core";
import { useEffect, useState } from "react";
import { goalTeam, goalUser } from "../../../api/goalApi";
import { AssigneeTypes } from "../../../constants/CoreConstants";
import { useIsMounted } from "../../../hooks/IsMounted";
import { iconList } from "../../globals/IconList";
import SelectComponent from "../SelectComponent";

const StepFirst = props => {

    const isMounted = useIsMounted();
    const [assigneeType,setAssigneeType] = useState("");
    const [selectedUser,setSelectedUser] = useState(0);
    const [selectedTeam,setSelectedTeam] = useState(0);
    const [userList,setUserList] = useState([]);
    const [teamList,setTeamList] = useState([]);
    const [assigneeText,setAssigneeText] = useState('');
    const [validStep,setValidStep] = useState(false);


    useEffect(() => {
        goalUser({}).then(res => {
            setUserList(res.data.data.users);
        });
        goalTeam({}).then(res => {
            setTeamList(res.data.data.teams);
        });
    },[]);

    useEffect(() => {
        if(isMounted) {
            let isValid = assigneeType === AssigneeTypes.TYPE_COMPANY || 
                (assigneeType === AssigneeTypes.TYPE_TEAM && selectedTeam !== 0) || 
                (assigneeType === AssigneeTypes.TYPE_USER && selectedUser !== 0);
            setValidStep(isValid);
            props.validateStep(1,isValid,{
                assigneeType : assigneeType,
                selectedTeam : selectedTeam,
                selectedUser : selectedUser
            });
        }
    },[assigneeType,selectedTeam,selectedUser]);

    const renderUserList = () => (
        userList.map((item, index) => (
            <MenuItem key={index} value={item['id']} >
                {item['full_name']}
            </MenuItem>
        ))
    )

    const renderTeamList = () => (
        teamList.map((item, index) => (
            <MenuItem key={index} value={item['id']} >
                {item['name']}
            </MenuItem>
        ))
    )

    return ( 
        <>
        <div className="add__goal_item">
            <div className="step__label__icon">
                { validStep ? iconList.checkMark : iconList.circle }
            </div>
            <div className="step__level__select__content__wr">
                <div className={`step__label__name ${props.stepActive == 1 ? 'assigne__label__name' : ''}`}>Assignee</div>
            </div>
        </div>
        {
            props.stepActive == 1 && 
            <div className="setp__label__left__border">
                <h4 className="step__label__name__title">Who is this goal assigned to?</h4>
                <div className="step__select__content">
                    <div className="add__goal__select1">
                        <FormControl variant="outlined" margin="none" focused="false" className="goal_select__wr">
                            <SelectComponent
                                onChange={e => {setAssigneeType(e.target.value);setAssigneeText('Everyone')}}
                                value={assigneeType}>
                                <MenuItem value={""} disabled>---Select---</MenuItem>
                                <MenuItem value={AssigneeTypes.TYPE_COMPANY}>Company (everyone)</MenuItem>
                                <MenuItem value={AssigneeTypes.TYPE_TEAM}>Team</MenuItem>
                                <MenuItem value={AssigneeTypes.TYPE_USER}>User</MenuItem>
                            </SelectComponent>
                        </FormControl>
                    </div>
                        
                    {
                        assigneeType === AssigneeTypes.TYPE_USER &&
                        <div className="add__goal__select2">
                            <FormControl variant="outlined" margin="none" focused="false" className="goal_select__wr">
                                <SelectComponent
                                    value={selectedUser}
                                    onChange={(e,elem) => {setSelectedUser(e.target.value);setAssigneeText(elem.props.children)}}>
                                    <MenuItem value="0" disabled>Choose User</MenuItem>
                                    {renderUserList()}
                                </SelectComponent>
                            </FormControl>
                        </div>
                    }

                    {
                        assigneeType === AssigneeTypes.TYPE_TEAM &&
                        <div className="add__goal__select2">
                            <FormControl variant="outlined" margin="none" focused="false" className="goal_select__wr">
                                <SelectComponent
                                    value={selectedTeam}
                                    onChange={(e,elem) => {setSelectedTeam(e.target.value);setAssigneeText(elem.props.children)}}>
                                    <MenuItem value="0" disabled>Choose Teams</MenuItem>
                                    {renderTeamList()}
                                </SelectComponent>
                            </FormControl>
                        </div>
                    }
                </div>
            </div>
        }
        {
            props.stepComplete >= 1 && props.stepActive != 1 &&
            <div className="">
                <h4 className="active__border__green pro__lead__group">{assigneeText}</h4>
            </div>
        }
        </>
     );
}
 
export default StepFirst;