import { sendRequest } from './rootApi';
import Api from "../constants/Api";

export const loadData = async (payload) => {
    return sendRequest("post", Api.loadData, payload);
}

export const loadGoalData = async (payload) => {
    return sendRequest("post", Api.loadGoalData, payload);
}

export const userSearch = async (payload) => {
    return sendRequest("post", Api.userSearch, payload);
}

export const groupSearch = async (payload) => {
    return sendRequest("post", Api.groupSearch, payload);
}

export const updateSetting = async (payload) => {
    return sendRequest("post", Api.updateSetting, payload);
}

export const reloadGoalData = async (payload) => {
    return sendRequest("post", Api.reloadGoalData, payload);
}

export const deleteGoal = async (payload) => {
    return sendRequest("post", Api.deleteGoal, payload);
}

export const goalPipeline = async (payload) => {
    return sendRequest("post", Api.goalPipeline, payload);
}

export const goalStage = async (payload) => {
    return sendRequest("post", Api.goalStage, payload);
}

export const goalTeam = async (payload) => {
    return sendRequest("post", Api.goalTeam, payload);
}

export const goalUser = async (payload) => {
    return sendRequest("post", Api.goalUser, payload);
}

export const createNewGoal = async (payload) => {
    return sendRequest("post", Api.createNewGoal, payload);
}

export const getGoalStatistics = async (payload) => {
    return sendRequest("post", Api.goalStatistics, payload);
}

export const getGoalReport = async (payload) => {
    return sendRequest("post", Api.goalReport, payload);
}

export const getGoalReportPopulateData = async (payload) => {
    return sendRequest("post", Api.goalReportPopulateData, payload);
}