import { FormControl,Select, MenuItem } from '@material-ui/core';
import { KeyboardArrowDown } from '@material-ui/icons';
import { useContext, useEffect, useState } from 'react';
import { DefaultColumns } from '../../../constants/CoreConstants';
import { handleAddColumn, handleCompareForChange, handleCompareWithChange } from '../reducer/Action';
import { ReportViewContext } from '../reducer/ReportV2Reduers';

const ChartViewTopHeader = () => {
    const {reportStates, detailsDispatch} = useContext(ReportViewContext)

    const [columns, setColumns] = useState(DefaultColumns)

    useEffect(() => {
        if(reportStates.addColumn != null){
            /* add a process for column */
            setColumns(reportStates.addColumn)
            detailsDispatch(handleAddColumn(null))
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reportStates.addColumn])

    const _handleCompareForChange = (e) => {
        detailsDispatch(handleCompareForChange({compareFor: e.target.value, selectedCompareFor: e.target.value}))
    }

    const _handleCompareWithChange = (e) => {
        detailsDispatch(handleCompareWithChange({compareWith: e.target.value, selectedCompareWith: e.target.value}))
    }

    const renderCompareForMenu = () => {
        let view = [], index = 0;
        for(var item in columns){
            if(item !== reportStates.compareWith){
                view.push(<MenuItem key={index} className={'repot_dropdown_item dropdownhelper-menuitem-class'} value={item}>{columns[item]}</MenuItem>);
                index++;
            }
        }
        return view;
    }

    const renderCompareWithMenu = () => {
        let view = [], index = 0;
        for(var item in columns){
            if(item !== reportStates.compareFor){
                view.push(<MenuItem key={index} className={'repot_dropdown_item dropdownhelper-menuitem-class'} value={item}>{columns[item]}</MenuItem>);
                index++;
            }
        }
        return view;
    }

    return(
        <div className="business__report__chart__view__top__header" key={`${reportStates.compareFor}-${reportStates.compareWith}`}>
            <div>
                <FormControl variant="outlined" margin="none" focused="false" className="business___report__select__wr">
                    <Select
                        className="business_select select__blue__back accent--bg--color"
                        labelId="business__report__compare_for__filter"
                        value={reportStates.compareFor}
                        onChange={_handleCompareForChange}
                        IconComponent = {KeyboardArrowDown}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        MenuProps={{
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left"
                            },
                            transformOrigin: {
                                vertical: "top",
                                horizontal: "left"
                            },
                            getContentAnchorEl: null
                        }}
                    >
                        {renderCompareForMenu()}
                    </Select>
                </FormControl>
            </div>

            <div>
                <FormControl variant="outlined" margin="none" focused="false" className="business___report__select__wr">
                    <Select
                        className="business_select select__blue__back accent--bg--color"
                        labelId="business__report__compare_with__filter"
                        value={reportStates.compareWith}
                        onChange={_handleCompareWithChange}
                        IconComponent = {KeyboardArrowDown}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        MenuProps={{
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left"
                            },
                            transformOrigin: {
                                vertical: "top",
                                horizontal: "left"
                            },
                            getContentAnchorEl: null
                        }}
                    >
                        <MenuItem className={'repot_dropdown_item dropdownhelper-menuitem-class'} value={''}>Nothing</MenuItem>
                        {renderCompareWithMenu()}
                    </Select>
                </FormControl>
            </div>
        </div>
    )
}
export default ChartViewTopHeader;