import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { userReportPagination, userReportReload, userReportInfoUpdate} from "../../actions/reportAction";
import "./userOrderReport.css";
import UserOrderTable from "./userOrderReport/UserOrderTable";
import Pagination from "./userOrderReport/Pagination";
import GlobalSearchBox from "../common/searchBox/GlobalSearchBox";
import FilterOrderList from "./userOrderReport/FilterOrderList";
import useDelayCallback from "../../hooks/useDelayCallback";
import { getUserReportData, getUserOrderReports, getUserOrderReportCharts } from "../../api/reportApi";
import ReportChart from "./userOrderReport/ReportChart";
import PipelineSkeleton from "./PipelineSkeleton";

const initialReports = [
    {
      status: "PENDING",
      total: 0
    },  
    {
      status: "CANCELLED",
      total: 0
    },
    {
      status: "FAILED",
      total: 0
    },
    {
      status: "PRINTING",
      total: 0
    },
    {
      status: "PRINTED",
      total: 0
    },
    {
      status: "ERROR",
      total: 0
    },
    {
      status: "SHIPPED",
      total: 0
    }
  ]

const UserOrderReportApp = (props) => {
  const [paginationChange, setPaginationChange] = useState(false);
  // const [isOpen, setIsOpen] = useState(false);
  // const [message, setMessage] = useState("");
  const [searchText, setSearchText] = useState('');

  const { per_page, total_pages, current_page } = props.reportReducer;
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [firstLanding, setFirstLanding] = useState(true);
  const [orderReportCounts, setOrderReportCounts] = useState([]);

  /* Chart */
  const [orderCharts, setOrderCharts] = useState([]);

  useDelayCallback(() => {
    setPaginationChange(false);
    let currentPage = parseInt(current_page);
    let totalPage = parseInt(total_pages);
    if ((current_page !== "" && currentPage !== 0 && paginationChange)) {
      if (totalPage >= currentPage) {
        fetchUserReportData();
      }
    }
  }, [current_page]);

  useDelayCallback(() => {
    if (searchText) {
      fetchUserReportData();
    }
  }, [searchText]);

  useEffect(() => {
    if (!loading) {
      if (firstLanding) {
        setLoading(true);
        setFirstLanding(false);
      }

      fetchUserReportData();

      getUserOrderReportCharts({}).then((res) => {
        if(res.status === "SUCCESS" && res.data) {
          setOrderCharts(res.data);
        }
      }).catch(err => console.log(err));

      getUserOrderReports({}).then((res) => {
        if(res.data) {
          setOrderReportCounts(res.data);
        }
      }).catch(err => console.log(err));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(()=>{
    if(searchText === "" && paginationChange) {
      fetchUserReportData();
    }
  },[searchText]) // eslint-disable-line react-hooks/exhaustive-deps

  const fetchUserReportData = (type="", status="", startDate="", endDate="") => {
    getUserReportData({ page: current_page, size: per_page, searchKey: searchText, type, status, startDate, endDate}).then((res) => {
      props.userReportInfoUpdate({
        total_page: res.totalPages,
        total_order: res.totalOrders,
      });
      setPaginationChange(true);
      setList(res.orderInfos);
      setLoading(false);
    });
  };

  //! Don't delete commented code. Will add this feature later
  // const showJsonObject = (message) => {
	// 	return <pre dangerouslySetInnerHTML={{ __html: syntaxHighlight(JSON.stringify(message, undefined, 4)) }} />
	// }

  // function syntaxHighlight(json) {
	// 	json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
	// 	// eslint-disable-next-line no-useless-escape
	// 	return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g, function (match) {
	// 		var cls = 'number';
	// 		if (/^"/.test(match)) {
	// 			if (/:$/.test(match)) {
	// 				cls = 'key';
	// 			} else {
	// 				cls = 'string';
	// 			}
	// 		} else if (/true|false/.test(match)) {
	// 			cls = 'boolean';
	// 		} else if (/null/.test(match)) {
	// 			cls = 'null';
	// 		}
	// 		return '<span class="' + cls + '">' + match + '</span>';
	// 	});
	// }

	// const getwayResponseData = (text) =>{
	// 	try{
	// 		let message = text.replace(/\\/g, '')
	// 		return JSON.parse(message)
	// 	}catch(error){
	// 		console.log(error)
	// 		return {}
	// 	}
  // }

  const renderColumns = () => {

    let columns = [];
    let total = 0;

    orderReportCounts.forEach((eachReport) => {
      total +=  eachReport.total;
    })

    for(let i = 0; i <= 6; i++) {
        let element = orderReportCounts.find(el => el.status === initialReports[i].status);
        if(!element) {
            columns.push(
              <div className="order__history__count__single">
                <h4 className={`order__history__title ${initialReports[i].status}`}>{initialReports[i].status}</h4>
                <h2 className="order__history__number">{initialReports[i].total}</h2>
                <h6 className="order__history__h6">of {total} sent</h6>
              </div>
          );
        } else {
            columns.push(
              <div className="order__history__count__single">
                <h4 className={`order__history__title ${element.status}`}>{element.status}</h4>
                <h2 className="order__history__number">{element.total}</h2>
                <h6 className="order__history__h6">of {total} sent</h6>
              </div>
            );
        }
    }
    
    return columns;
     
  }

  const renderData = () => {
    return (
      <>
        <div className="userBox">
          <div className="user_order_list_title">
            <h5>Order history</h5>
          </div>
        </div>

        {/** Graph and show total count */}

        <div className="business__chart__wr business__chart__wr--section">

          <h4 className="business__chart__wr__h4__title">{`Summary - ${window.globalTimezoneConversionToDifferentTimezone(new Date(), 'UTC','','MMMM, YYYY')}`}</h4>

          <div className="order__history__count__wr">
              {renderColumns()}
          </div>

          <ReportChart orderCharts={orderCharts} />
          
        </div>

        {/** Search Area */}

       

        {/** Filter Area */}

        <div className="user__order__list__filter__serch__area">

            <div className="user__order__list__filter__area">
              <FilterOrderList fetchUserReportData={fetchUserReportData} />
            </div>
            <div className="user__order__list__search__area">

              <GlobalSearchBox
                dataTarget="top-search-result"
                className="user__order__list__search" 
                type="search" 
                autocomplete="off"
                name="Search"
                placeholder="Search" id="searchInput"
                onChange={(e) => setSearchText(e.target.value)}
              />

              </div>
            </div>

        <div className="details">
          {/* table */}
          <UserOrderTable
            // showDetails={(message) => {
            //   setMessage(message);
            //   setIsOpen(true);
            // }}
            loading={loading}
            list={list}
          />
        </div>

        {/* pagination */}
        <Pagination
          reportReducer={props.reportReducer}
          setPaginationChange={setPaginationChange}
          userReportPagination={props.userReportPagination}
        />

        {/* <GlobalModal
          open={isOpen}
          onClose={() => setIsOpen(false)}
          title={"Response"}
          className={"modal__body__goal"}
          hideFooter={true}
        >
          {showJsonObject(getwayResponseData(message))}
        </GlobalModal> */}
      </>
    )
  }

  return (
    <div className="user__report_page_wrapper min-height-500">
      {loading ? <PipelineSkeleton /> : renderData()}
    </div>
  );
};

const mapStateToProps = (state) => ({
  reportReducer: state.reportReducer,
});

const mapDispatchToProps = (dispatch) => ({
  userReportReload: (params) => dispatch(userReportReload(params)),
  userReportPagination: (params) => dispatch(userReportPagination(params)),
  userReportInfoUpdate: (params) => dispatch(userReportInfoUpdate(params)),
});

const UserOrderReport = connect(mapStateToProps, mapDispatchToProps)(UserOrderReportApp);
export default UserOrderReport;
