import { useContext, useEffect, useState } from "react";
import { getBusinessReportCardV2Api } from "../../../api/reportApi";
import { DefaultColumns } from "../../../constants/CoreConstants";
import { handleAddColumn, handleCardLoading, storeCardData } from "../reducer/Action";
import { ReportViewContext } from "../reducer/ReportV2Reduers";
import CustomCircleLoader from "./CustomCircleLoader";
import EachCardItem from "./EachCardItem";

const BusinessCardView = () => {
    const {reportStates, detailsDispatch} = useContext(ReportViewContext)

    const [columns, setColumns] = useState(DefaultColumns)

    useEffect(()=> {
        if(!reportStates.isLoadingCard){
            detailsDispatch(handleCardLoading(true))
            getCardData({
                date: reportStates.date,
                user_type: reportStates.userType,
                user_id: reportStates.userId,
                fields: Object.keys(columns)
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        reportStates.userType, 
        reportStates.userId, 
        reportStates.date
    ])

    useEffect(() => {
        if(reportStates.addColumn != null){
            /* add a process for column */
            setColumns(reportStates.addColumn)
            detailsDispatch(handleAddColumn(null))
            detailsDispatch(handleCardLoading(true))
            getCardData({
                date: reportStates.date,
                user_type: reportStates.userType,
                user_id: reportStates.userId,
                fields: Object.keys(reportStates.addColumn)
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reportStates.addColumn])

    const getCardData = (payload) => {
        getBusinessReportCardV2Api(payload).then(res => {
            detailsDispatch(storeCardData({
                currentCardData: res.data.data.current_data,
                currentCardDataCount: res.data.data.current_data_count,
                previousCardDataCount: res.data.data.previous_data_count
            }))
            detailsDispatch(handleCardLoading(false))
        }).catch(error => {
            detailsDispatch(handleCardLoading(false))
        })
    }

    const renderCardList = () => {
        let view = [], index = 0;
        for(var item in columns){
            view.push(
                <EachCardItem 
                    key={index}
                    item={columns[item]}
                    key_data={item}
                    currentData={reportStates.currentCardData}
                    countData={reportStates.currentCardDataCount}
                    previousCountData={reportStates.previousCardDataCount}
                />
            )
            index++;
        };
        return view;

    }

    return (
        <div className="business__report__card__view__container">
            <div className="business__chart__view">
                {reportStates.isLoadingCard ?
                    <CustomCircleLoader className="business__custom__loader" block={false} />
                :
                    <div className="business__chart__view__row">
                    {renderCardList()}
                    </div>
                }
            </div>
        </div>
    );
}
export default BusinessCardView;