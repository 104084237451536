import React, {createContext} from "react";
import './App.css';
import Routes from "./routes/Routes";
import { createBrowserHistory } from "history";
import {BrowserRouter as Router} from 'react-router-dom';
import { StylesProvider, createGenerateClassName } from '@material-ui/core/styles';
import Utils from "./helpers/Utils";
import ErrorBoundary from "./components/common/ErrorBoundary";

const defaultHistory = createBrowserHistory();
export const HistoryProvider = createContext({});

function App({ history = defaultHistory }) {

    const generateClassName = createGenerateClassName({
        productionPrefix: 'rprt_' + Utils.generateRandomString(5),
    });

    return (
        <ErrorBoundary>
            <HistoryProvider.Provider value={history}>
                <StylesProvider generateClassName={generateClassName}>
                    <Router history={history}>
                        <Routes history={history} />
                    </Router>
                </StylesProvider>
            </HistoryProvider.Provider>
        </ErrorBoundary>
    );
}

export default App;
