import React, {Fragment, useEffect, useState} from "react";
import Styles from "./DncInventory.module.css"
import {
    InputBase,
    withStyles,
} from "@material-ui/core";
import {getDncInventoryApi, updateNumberDncStatusApi} from "../../../api/reportApi";
import useDebounce from "../../../hooks/useDebounce";
import NewConfirmAlert from "../../common/new-alert/NewConfirmAlert";
import TableSkeletonLoader from "./TableSkeletonLoader";

const BootstrapInput = withStyles((theme) => ({
    root: {
        "label + &": {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        fontSize: "14px !important",
        borderRadius: "4px !important",
        position: "relative",
        color: "#3a414a !important",
        border: "1px solid #ced4da !important",
        padding: "10px 26px 10px 12px !important",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        "&:focus": {
            borderRadius: 4,
            boxShadow: "none !important"
        },
        backgroundColor: "#ffffff !important"
    },
}))(InputBase);

function formatPhoneNumber(phoneNumberString) {
    if (!phoneNumberString){
        return 'N/A';
    }

    if (phoneNumberString.length < 11){
        phoneNumberString = '1' + phoneNumberString;
    }
    return phoneNumberString.replace(/\D+/g, '').replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, '+$1 ($2) $3-$4');
}

const DncInventory = () => {
    const [loading,setLoading] = useState(true);
    const [initialLoading,setInitialLoading] = useState(true);
    const [loadingMore,setLoadingMore] = useState(false);
    const [page,setPage] = useState(1);
    const [pages,setPages] = useState(0);
    const [dncInventories, setDncInventories] = useState([]);
    const [search, setSearch] = useState("");
    const debouncedSearch = useDebounce(search, 500);

    useEffect(()=>{
        setLoading(true);
        getDncInventoryApi({
            search: debouncedSearch,
            totalPage: pages,
            page: page,
            limit: 20,
        })
            .then((res)=>{
            if (res){
                res = res.data;
                if (res && res.status === "success" && Array.isArray(res.data)){
                    if (loadingMore){
                        setDncInventories([ ...dncInventories, ...res.data ]);
                    }else {
                        setDncInventories(res.data);
                    }
                    setPages(res.totalPage);
                }else {
                    setDncInventories([]);
                }
            }
        }).catch((err)=>{
            setDncInventories([]);
        }).finally(()=>{
            setLoading(false);
            setInitialLoading(false);
            setLoadingMore(false);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[debouncedSearch, page]);

    const updateNumberDncStatus = async (item) => {
        NewConfirmAlert({
            onSubmit : async () => {
                try{
                    const res = await updateNumberDncStatusApi({
                        "contact-id": item.contact_id,
                        "phone": item.phone,
                        "dnc-status": "VERIFIED_OK"
                    });

                    if (res && res.success && res.data && res.data.dnc_status){
                        window.showNotification("success", res.message);
                    }else {
                        window.showNotification("error", "Unable to update dnc status! Try again later");
                    }
                }catch (err){
                    window.showNotification("error", "Something went wrong! Contact with administrator");
                }
            },
            title: "Are you sure?",
            description: `You are about to remove this contact number from DNC list`,
            cancelText: "Cancel",
            submitText: "Ok, Confirm",
            width: "480px"
        });
    };

    const getContactName = (dncInventory) => {
        let name = 'N/A';
        if (dncInventory && dncInventory.contact){
            if (dncInventory.contact.first_name){
                name = dncInventory.contact.first_name;
            }

            if (dncInventory.contact.last_name){
                if (name){
                    name += " ";
                }

                name += dncInventory.contact.last_name;
            }
        }

        return name;
    }

    return(
        <div className={Styles.pageWrapper}>
            <div className={Styles.pageHeader}>
                <div className={Styles.pageHeaderTitle}>
                    <div>
                        <h1 className={Styles.pageMainTitle}>Internal DNC Report</h1>
                        <p className={Styles.pageMainSubtitle}>Review the numbers currently on your internal DNC list</p>
                    </div>
                </div>
                <div className={Styles.pageHeaderAction}>
                    <BootstrapInput onChange={(event)=>{
                        setSearch(event.target.value);
                        if (page !== 1){
                            setPage(1);
                        }
                    }} placeholder="Search by number" />
                </div>
            </div>
            <div className={Styles.pageBody}>
                <div className={Styles.detailsPane}>
                    <div className={Styles.detailsPaneHeader}>
                        <div className={Styles.detailsPaneHeaderLeft}>
                        </div>
                        <div className={Styles.detailsPaneHeaderRight}>
                        </div>
                    </div>
                    <div className={Styles.detailsPaneBody}>
                        <table>
                            <thead>
                            <tr>
                                <th>Number</th>
                                <th>Contact</th>
                                <th style={{textAlign: "center"}}>Last Checked</th>
                                <th style={{textAlign: "center"}}>Added to DNC List</th>
                                <th>DNC Source</th>
                                <th style={{textAlign: "center"}}>Action</th>
                            </tr>
                            </thead>
                            <tbody onScroll={(e)=>{
                                const bottom = e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 50;
                                if(bottom && !loading && pages > page){
                                    setLoadingMore(true);
                                    if (!loadingMore){
                                        setTimeout(()=>{
                                            let uPage = page + 1;
                                            setPage(uPage);
                                        },500);
                                    }
                                }
                            }} >
                            {
                                (loading && initialLoading) ? (
                                   <TableSkeletonLoader rows={11} columns={6} />
                                ) : (
                                    dncInventories[0] ? (
                                        <Fragment>
                                            {
                                                dncInventories.map((dncInventory,index)=>(
                                                    <tr>
                                                        <td>
                                                            {formatPhoneNumber(dncInventory.phone)}
                                                        </td>
                                                        <td>
                                                            <a href={`/contacts/${dncInventory.contact_id}`}
                                                               target="_blank" rel="noopener noreferrer">
                                                                {getContactName(dncInventory)}
                                                            </a>
                                                        </td>
                                                        <td style={{textAlign: "center"}}>
                                                            {dncInventory.last_checked && dncInventory.last_checked.created_at ? window.globalTimezoneConversionToDifferentTimezone(dncInventory.updated_at, 'UTC', '', 'MM/DD/YYYY') : ""}
                                                        </td>
                                                        <td style={{textAlign: "center"}}>
                                                            {dncInventory.updated_at ? window.globalTimezoneConversionToDifferentTimezone(dncInventory.updated_at, 'UTC', '', 'MM/DD/YYYY') : ""}
                                                        </td>
                                                        <td>
                                                            {dncInventory.note}
                                                        </td>
                                                        <td style={{textAlign: "center"}}>
                                                            <span className={Styles.removeDNCListButton}
                                                                  onClick={async () => {
                                                                      await updateNumberDncStatus(dncInventory);
                                                                  }}>
                                                                Remove from DNC List
                                                            </span>
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                            {
                                                loadingMore &&
                                                // <tr>
                                                // <td colSpan={2}>
                                                //         Loading more...
                                                //     </td>
                                                // </tr>
                                                <TableSkeletonLoader rows={11} columns={6} />

                                            }
                                        </Fragment>
                                    ) : (
                                        <tr>
                                            <td colSpan={7}>
                                                <div className={Styles.loaderWrapper}>
                                                    <h5 className={Styles.marginTop}>No contact found!</h5>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                )
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DncInventory;